import Footer from "components/footerUser";
import Header from "components/header";
import React from "react";
// import Header from "../../components/Header";
import BookListItem from "../../components/BookListItem";
import useStyles from './style';
import 'assets/css/About.css';
// import Footer from "../../components/Footer";

const AboutUs = () => {
    const styles = useStyles();
    const data = [
        {
          menu: 'Tentang Persona',
          content: (
            <div className="book">
              <h4>Tentang Persona</h4>
              <p>Berdasarkan analisa dan penelitian terkait Delapan Aksara, Astrologi dengan analitik metodologi dari Persona, kami bertujuan untuk dapat memberikan Anda informasi yang bersifat hiburan namun mendalam dan komprehensif tentang diri Anda, hubungan Anda, kecocokan dengan orang lain, gaya bekerja Anda, kehidupan sosial dan pribadi Anda, kehidupan cinta Anda dan pentingya berbagai waktu dalam keseharian Anda.</p>
              <p>Kami tidak membuat peramalan atau penilaian, melainkan memberi Anda informasi data yang bersifat terbuka dalam potret Anda.</p>
              <p>Dengan lebih memahami kekuatan dan tantangan Anda sendiri, serta teman dan pasangan Anda, Anda akan semakin berdaya untuk membuat pilihan terbaik bagi diri Anda sendiri.</p>
              <p>Idealnya, apa yang Anda temukan tentang diri Anda akan mencerminkan hal-hal yang sudah Anda ketahui, tetapi mungkin belum Anda terima atau pahami sebelumnya</p>
              <p>Kami percaya bahwa kita semua adalah bagian dari bagan-bagan dan pilhan yang lebih besar, dan keberadaan Anda sangatlah penting.</p>
              <p>Kami harap Anda menikmati.</p>
              <h5>Persona Talenta</h5>
              <p>What's Your Persona?</p>
            </div>
          )
        },
        {
          menu: 'Cara Menggunakan Layanan',
          content: (
            <div className="book">
              <h4>A. Cara menggunakan Persona Talenta:</h4>
              <ol type="1">
                <li>Buka website <a href="/home">https://personatalenta.id/home</a></li>
                <li>Klik <strong>Buat Akun</strong></li>
                <li>⁠⁠Isi form profile, klik <strong>register</strong></li>
                <li>Cek <strong>email verifikasi</strong> ke alamat email yang diisikan didalam form. Klik button <strong>verifikasi email saya</strong></li>
                <li>Kembali ke <a href="/home">https://personatalenta.id/home</a> dan Klik <strong>Login</strong></li>
              </ol>
              <h4>B. Cara membeli layanan Persona Talenta:</h4>
              <ol type="1">
                <li>Masuk ke website <a href="/home">https://personatalenta.id/home</a></li>
                <li>⁠⁠Klik Login, masukan username dan password.</li>
                <li>⁠⁠Setelah Login akan masuk ke <strong>Dashboard Profile</strong>.</li>
                <li>⁠⁠Untuk membeli layanan, selanjutnya Pilih Service yang akan dibeli, misal: <strong>Service</strong> : <strong>Profiling Book - Feature : Complete Profiling Book</strong> , geser ke kanan pada kolom menu Action klik button <strong>Beli</strong>.</li>
              </ol>
              <h4>C. Cara Membayar Layanan yang akan dibeli:</h4>
              <ol type="1">
                <li>⁠Setelah klik tombol <strong>Beli</strong> akan diarahkan ke halaman Layanan yang dipilih dengan kelengkapan informasinya.</li>
                <li>⁠⁠Dihalaman informasi Layanan dan Harga, mulai proses Select Service : klik <strong>Buy Now</strong> sesuai dengan layanan yang dipilih.</li>
                <li>⁠⁠Selanjutnya ke proses Confirm Order : pada kolom <strong>Pilih Pembayaran</strong>, klik <strong>QR Code</strong>, lanjut ke kolom <strong>Pilih Metoda Pembayaran</strong>, klik <strong>kotak logo QRIS</strong> akan muncul button <strong>lanjut bayar</strong>, klik button lanjut bayar maka akan muncul <strong>QR Bayar</strong>, selanjutnya scan QR Bayar untuk melakukan pembayaran.</li>
                <li>⁠⁠Setelah selesai pembayaran akan di redirect ke <strong>Dashboard Purchase History</strong> dengan list Service Feature yang sudah dibayar.</li>
                <li>⁠⁠Klik button <strong>icon download</strong> atau <strong>web view</strong> sesuai dengan layanan yang dibeli dan yang anda inginkan, untuk icon <strong>download</strong> maka file pdf yang dibeli akan didownload.</li>
                <li>⁠⁠Enjoy Reading Your Profilling.</li>
              </ol>
            </div>
          )
        },
        {
          menu: 'Guideline',
          content: (
            <div className="book">
              <h4>Community Guideline</h4>
              <p>Panduan berikut berlaku untuk semua konten di aplikasi Persona Talenta. Dengan menggunakan aplikasi Persona Talenta, Anda menyetujui pedoman ini, Kebijakan Privasi dan Ketentuan Layanan kami. Melanggar pedoman ini dapat mengakibatkan konten dihapus, akun dinonaktifkan, dan kemungkinan pembatasan lainnya. Selanjutnya, kami berhak memblokir siapa saja yang berulang kali melanggar Pedoman Komunitas ini. Persona Talenta berhak untuk menyelidiki dan mengambil tindakan terhadap siapa saja pengguna aplikasi dan website, atas kebijakan Persona Talenta yang melanggar Kebijakan Privasi, Ketentuan Layanan dan Pedoman Komunitas ini. Persona Talenta memiliki kebijakan tanpa toleransi terkait penerapan Pedoman Komunitas dan Persyaratan Layanannya, dan akan mengambil langkah yang diperlukan untuk menegakkannya secara agresif.</p>
              <h5>Keamanan (Security)</h5>
              <h6>Ujaran Kebencian / Perilaku Menyinggung</h6>
              <ul>
                <li>Anda tidak boleh memposting sesuatu yang penuh kebencian yang berkaitan dengan jenis kelamin, identitas gender, ras, etnis, orientasi seksual, cacat fisik atau mental, asal negara, usia, atau penyakit serius seseorang.</li>
                <li>Anda tidak boleh memposting konten cabul, vulgar, eksplisit secara seksual, atau pornografi.</li>
                <li>Anda tidak boleh memposting komentar yang tidak pantas atau menghina tentang ukuran atau bentuk tubuh orang lain, termasuk mempermalukan gemuk/kurus, atau frasa lain apa pun yang dapat memengaruhi kepositifan tubuh seseorang.</li>
              </ul>
              <h6>Ancaman / Kekerasan</h6>
              <ul>
                <li>Anda tidak boleh memposting konten yang mengancam, kekerasan, atau berbahaya.</li>
                <li>Anda tidak boleh mendorong kekerasan terhadap orang atau properti mana pun.</li>
              </ul>
              <h6>Penindasan / Pelecehan</h6>
              <ul>
                <li>Anda tidak boleh terlibat dalam perilaku kasar terhadap sesama pengguna;</li>
                <li>Anda tidak boleh mendorong orang lain untuk terlibat dalam perilaku kasar terhadap sesama pengguna;</li>
                <li>Hormati keinginan sesama pengguna. Jika seseorang memblokir Anda, jangan coba menghubungi mereka dari akun lain;</li>
                <li>Anda tidak boleh terlibat dalam pencemaran nama baik, fitnah, penyiksaan, atau apa pun yang dapat mengganggu privasi orang lain.</li>
              </ul>
              <h6>Menyakiti Diri Sendiri</h6>
              <ul>
                <li>Anda tidak boleh mendukung atau mendorong bunuh diri atau melukai diri sendiri;</li>
                <li>Anda tidak boleh memposting materi apa pun yang mendukung atau mendorong bunuh diri atau melukai diri sendiri.</li>
              </ul>
              <h6>Aktivitas Ilegal</h6>
              <ul>
                <li>Anda tidak boleh memposting apa pun yang melanggar hukum Adat, lokal, nasional atau internasional yang berlaku, atau peraturan apa pun yang memiliki kekuatan hukum;</li>
                <li>Anda tidak boleh memposting informasi instruksional apa pun tentang aktivitas ilegal.</li>
              </ul>
              <h6>Terorisme</h6>
              <ul>
                <li>Anda tidak boleh mendukung atau mendorong aktivitas teroris atau ekstremisme kekerasan dalam bentuk apa pun.</li>
              </ul>
              <h5>Transparansi</h5>
              <h6>Permohonan / Spam</h6>
              <ul>
                <li>Anda tidak boleh memposting apa pun yang merupakan iklan, materi promosi, aktivitas komersial dan/atau penjualan yang tidak diminta atau tidak sah, "surat sampah", "spam", "surat berantai", "skema piramida", "kontes", "undian", atau segala bentuk ajakan lainnya;</li>
                <li>Anda tidak boleh mengiklankan atau menawarkan untuk menjual atau membeli barang atau jasa apa pun untuk tujuan bisnis apa pun yang tidak diizinkan secara khusus;</li>
                <li>Anda tidak boleh mengumpulkan alamat email atau informasi kontak lainnya dari pengguna lain dari Layanan melalui elektronik atau cara lain untuk tujuan mengirim email yang tidak diminta atau komunikasi yang tidak diminta lainnya.</li>
              </ul>
              <h6>Peniruan</h6>
              <ul>
                <li>Anda tidak boleh menyamar sebagai orang atau entitas mana pun, atau secara salah menyatakan atau menggambarkan secara keliru afiliasi Anda dengan seseorang atau entitas.</li>
              </ul>
              <h6>Hak Kekayaan Intelektual</h6>
              <ul>
                <li>Anda tidak boleh melanggar hak kekayaan intelektual siapa pun, termasuk hak cipta, paten, merek dagang, rahasia dagang, atau hak dan undang-undang kepemilikan lainnya.</li>
              </ul>
              <h5>Pribadi</h5>
              <h6>Informasi Pribadi</h6>
              <ul>
                <li>Anda tidak boleh memposting informasi pribadi, termasuk namun tidak terbatas pada kartu tanda penduduk, kartu jaminan sosial, atau informasi sensitif lainnya;</li>
              </ul>
              <h5>Kebijakan Foto / Gambar</h5>
              <p>Kebijakan Foto/Gambar ini, bersama dengan Pedoman Komunitas kami, dan Ketentuan Layanan dibuat untuk memastikan Persona Talenta tetap menjadi tempat yang aman bagi anggota komunitas kami. Panduan berikut berlaku untuk semua foto/gambar yang diposting di Layanan kami:</p>
              <ul>
                <li>Tidak ada foto/gambar telanjang.</li>
                <li>Tidak ada foto/gambar yang mengandung kekerasan grafis.</li>
                <li>Tidak ada foto/gambar porno.</li>
                <li>Wajah harus terlihat jelas.</li>
                <li>Tidak ada foto/gambar yang mengandung kekerasan senjata atau berburu.</li>
                <li>Tidak ada foto/gambar yang menggambarkan citra kebencian dalam bentuk apa pun.</li>
                <li>Tidak ada foto/gambar yang menunjukkan simbol, tindakan, atau tanda/afiliasi kelompok yang cabul atau provokatif.</li>
                <li>Tidak ada foto/gambar yang berisi informasi identitas pribadi.</li>
                <li>Tidak ada f foto/gambar yang menggambarkan aktivitas ilegal.</li>
              </ul>
              <p>Kami meminta agar Pengguna memperlakukan semua orang dengan rasa hormat, kejujuran, dan kebaikan untuk mempromosikan ruang yang aman untuk berbagi cerita dan pengalaman pribadi.Kami juga mendesak Pengguna untuk berhati-hati dan membuat penilaian yang baik sebelum berkomunikasi dengan orang lain di Persona Talenta.</p>
            </div>
          )
        },
      ]
    
    return (
        <div className={styles.app}>
            <Header />
            <div className={styles.containerListItem}>
                <BookListItem data={data}/>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs;