import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import qs from 'qs';
import useStyles from './style';
// import Footer from '../../components/Footer';
import Button from 'components/button';
// import ModalLogin from '../../components/ModalLogin';
import InputBase from '@mui/material/InputBase';
import Checked from 'assets/svg/checkedSmall';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import Header from '../../components/Header';
import 'assets/css/About.css';
import Header from 'components/header';
import Footer from 'components/footerUser';

const Input = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
  },
  '& .MuiInputBase-input': {
    minWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      minWidth: 150,
    }
  },
}));

const Forgot = () => {
  const [step, setStep] = useState(1);
  // const [openLogin, setOpenLogin] = useState(false);
  const [tokenReset, setTokenReset] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [params, setParams] = useState({});

  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const styles = useStyles();
  // const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  // const onChangeInput = e => {
  //   const tmpValue = {};
  //   tmpValue[e.target.id] = e.target.value;
  //   setParams({...params, ...tmpValue});
  // };

  const onClickSendCode = () => {
    axios.post('https://api.personatalenta.id/user/reset_password', qs.stringify({email: email}), {headers: {"content-type": "application/x-www-form-urlencoded"}})
    .then(res => {
      if (res.status === 200) {
        setError('');
        setSuccess(res.data.message);
        setTokenReset(res.data.data.token)
      }
    }).catch(err => {
      setError(err.response?.data?.message || err.response?.data || err.response?.message || err.message)
    })
  }

  const onClickVerify = () => {
    axios.post('https://api.personatalenta.id/user/verify_otp', qs.stringify({otp: otp, token: tokenReset}), {headers: {"content-type": "application/x-www-form-urlencoded"}})
    .then(res => {
      if (res.status === 200) {
        setError('');
        setTokenReset(res.data.data.token)
        setStep(2)
      }
    }).catch(err => {
      setError(err.response?.data?.message || err.response?.data || err.response?.message || err.message)
    })
  }

  const onChangePassword = () => {
    axios.post('https://api.personatalenta.id/user/new_password', qs.stringify({password1: password1, password2: password2, token: tokenReset}), {headers: {"content-type": "application/x-www-form-urlencoded"}})
    .then(res => {
      if (res.data.message === 'Password has been reset successfully') {
        window.location.assign('/');
      }
    }).catch(err => {
      setError(err.response?.data?.message || err.response?.data || err.response?.message || err.message)
    })
  }

  return (
    <div className={styles.app}>
      <Header />
      <div className={styles.services}>
        {step === 1 ? (
          <div className={styles.servicesContainer}>
            <h4>Forgot Password</h4>
            <div className={styles.containerPassword}>
              {error && (
                <span className={styles.error}>{error}</span>
              )}
              <InputLabel shrink htmlFor="email" className={styles.label}>
                Input your account email address or username.
              </InputLabel>              
              <div className={styles.containerInput}>
                <Input id="email" className={styles.input} placeholder="Email / Username" onChange={e => setEmail(e.target.value)} />
                <Button variant="secondary" label="Submit" onClick={onClickSendCode} />
              </div>
              {success && (
                <div className={styles.containerSuccess}>
                  <Checked />
                  <span>{success}</span>
                </div>
              )}
            </div>
            {success && (
              <div className={styles.containerPassword}>
                <InputLabel shrink htmlFor="otp" className={styles.label}>
                  Enter the 6 digit OTP
                </InputLabel>
                <div>
                  <div className={styles.containerInput}>
                    <Input id="otp" className={styles.input} type="text" placeholder="OTP" onChange={e => setOtp(e.target.value)} />
                    <Button variant="primary" label="Verify" onClick={onClickVerify} />
                  </div>
                </div>
              </div>
            )}
          </div> 
        ) : (
          <div className={styles.servicesContainer}>
            <h4>Create New Password</h4>
            {error && (
              <span className={styles.error}>{error}</span>
            )}
            <div>
              <FormControl variant="standard" className={styles.formControl}>
                <InputLabel shrink htmlFor="password" className={styles.label}>
                  Password
                </InputLabel>
                <Input 
                  fullWidth
                  id="password"
                  className={styles.input}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  onChange={e => setPassword1(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl variant="standard" className={styles.formControl}>
                <InputLabel shrink htmlFor="confirmPassword" className={styles.label}>
                  Confirm Password
                </InputLabel>
                <Input 
                  fullWidth
                  id="confirmPassword"
                  className={styles.input}
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  onChange={e => setPassword2(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <Button variant="primary" label="Submit" onClick={onChangePassword} />
          </div>
        )}
      </div>
      <Footer />
      {/* <ModalLogin open={openLogin} setOpen={setOpenLogin} /> */}
    </div>
  );
}

export default Forgot;
