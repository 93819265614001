export const pricingProfilingStatic = [
  {
    code: "profiling_career",
    name: "Career Profiling Book",
    price: 200000,
    discountPrice: 1,
    desc: "Dapat di download dalam file pdf",
    features: [
      "Pilihan Karier Sesuai Potensi : Ringkasan Pilihan Karier Dan Peran Kerja, Ringkasan Pengembangan Potensi, Cara Analisa Potensi Diri Persona Talenta, Bidang Karier Pilihan Pertama, Bidang Karier Pilihan Kedua",
      "Pilihan Peran Kerja Sesuai Potensi: Peran Kerja Alternatif Pertama, Peran Kerja Alternatif Kedua, Peran Kerja Dalam Artikulasi Lain, Peran Kerja Lain Yang Kemungkinan Bisa Pas.",
      "Tips Meningkatkan Peran Dalam Berkarier: Berperan Sesuai Pilihan Pekerjaan Karier, Pemikiran Dalam Peran Kerja, Tips Memperluas Wawasan Berpikir",
      "Meningkatkan Ketajaman Bisnis Dan Keuangan: Kemampuan Pemahaman Ketajaman Bisnis, Cara Meningkatkan Ketajaman Bisnis,  Manajemen Keuangan, Profesionalitas Kerja",
      "Cara Pengembangan Potensi Diri: Cara Mengembangkan Potensi, Langkah Terbaik Untuk Sukses",
    ],      
  },
  {
    code: "profiling_workstyle",
    name: "Career Profiling Book",
    price: 200000,
    discountPrice: 1,
    desc: "Dapat di download dalam file pdf",
    features: [
      "Ringkasan Karakter Dan Gaya Kerja",
      "Potret Personalitas Ciri Kepribadian Anda",
      "Karakter Kerja Dan Gaya Bekerja Anda, Gaya Bekerja Yang Baik, Gaya Bekerja Yang Kurang Baik, Gaya Kerja Yang Berlebihan",
      "Gaya Kepemimpinan Anda, Tips Untuk Memimpin Lebih Baik",
      "Cara Berinteraksi /Dealing",
      "Karakter Tersembunyi Dan Sifat Dominan Saat Dalam Tekanan",
      "Sifat Positif, Sifat Negatif",
      "Kekuatan Dan Kelemahan Cara Pengembangan Potensi : Cara Mengembangkan Potensi, Langkah Terbaik Anda Untuk Sukses",
    ],      
  },
  {
    code: "profiling_relationship",
    name: "Career Profiling Book",
    price: 200000,
    discountPrice: 1,
    desc: "Dapat di download dalam file pdf",
    features: [
      "Ringkasan Kepribadian Dalam Membangun Hubungan: Ringkasan Kepribadian Dalam Interaski Sosial Dan Intim, Ringkasan Untuk Memahami, Mengatasi Dan Delaing",
      "Kepribadian Dalam Relasi Sosial Dan Intim : Dalam Interaksi Sosial, Kepribadian Dalam Hubungan Dekat",
      "Karakter Dalam Membangun Relasi: Karakter Dalam Menjalin Relasi, Cara Terbaik Untuk Dalam Menjalani Relasi",
      "Jalinan Persahabatan Dan Kemitraan: Sifat Dalam Persahabatan Dan Kemitraan, Tips Persahabatan Dan Kemitraan",
      "Hubungan Romantis: Jalinan Cinta, Kehidupan Cinta Dan Seksualitas",
      "Perilaku Dalam Relasi Keluarga: Potret Hubungan Keluarga, Perilaku Yang Baik Sebagai Orang Tua, Perilaku Yang Tidak Baik Sebagai Orang Tua",
      "Cara Interaksi Dan Dealing: Memahami Perilaku Dan Cara Mengatasinya, Cara Interaksi, Cara Melakukan Dealing.",
    ],      
  },
  {
    code: "profiling_standard",
    name: "Standard Profiling",
    price: 500000,
    discountPrice: 250000,
    desc: "Dapat di download dalam file pdf",
    features: [
      "Brief, Visible, Socio Persona",
      "Hidden, Potential Persona, Under Pressure",
      "Self Persona, Self Confidence",
      "The Bright and The Dark Side, Positive and Negative Side, Strength And Weakness",
      "Thinking Judgment and Tips",
      "Character at Work",
      "Work Style, Over-Acting Probability",
      "Good and Bad Work Style",
      "Leadership Style, Leadership Tips",
      "Career, Suitable Career",
      "Suitable Job Role, Suitable Environment",
      "Identifying, Dealing, Interacting",
      "Career and Wealth",
      "Key Talent, Ambition, Business Acumen, Improving Profile, The Path To Growth",
    ],      
  },
  {
    code: "profiling_complete",
    name: "Complete Profiling",
    price: 750000,
    discountPrice: 350000,
    desc: "Dapat di download dalam file pdf",
    features: [
      "Brief, Visible, Socio Persona",
      "Hidden, Potential Persona, Under Pressure",
      "Self Persona, Self Confidence",
      "The Bright and The Dark Side, Positive and Negative Side, Strength And Weakness",
      "Thinking Judgment and Tips",
      "Character at Work",
      "Work Style, Over-Acting Probability",
      "Good and Bad Work Style",
      "Leadership Style, Leadership Tips",
      "Career, Suitable Career",
      "Suitable Job Role, Suitable Environment",
      "Identifying, Dealing, Interacting",
      "Career and Wealth",
      "Key Talent, Ambition, Business Acumen, Improving Profile, The Path To Growth",
      "Social And Intimate Profile",
      "Relationship, Guide to Relationship",
      "Friendship and Colleagues",
      "Love Relation, Love and Sex Life",
      "Family Relation, Good and Bad as a Parent",
      "General Health, Guide For Health",
      "Food and Color",
      "Money Matters",
      "Wealth Description, Path To Wealth",
      "Year Prediction",
      "Tips Of The Year",
      "Career and Financial Growth",
      "Social Welfare & Romance",
      "Home and Family Life",
      "Mind and body",
      "Travel and Life Changes",
      "Future Outlook",
    ],
  },
  // {
  //   code: "profiling_basic",
  //   name: "Basic Profiling",
  //   price: 300000,
  //   discountPrice: 150000,
  //   desc: "Hanya dapat dilihat di website",
  //   features: [
  //     "Character in Brief",
  //     "Good Characteristic",
  //     "Bad Characteristic",
  //     "Character Bright and Dark",
  //     "Self Confidence",
  //     "Character Identification & To Deal With",
  //     "Dealing and Interacting With",
  //     "Career Choices",
  //     "Suitable Career",
  //     "Career Environment",
  //     "Career Life",
  //     "Career Roles",
  //     "Career And Wealth",
  //     "Talent in Brief",
  //     "Business Acumen",
  //     "Thinking Judgment",
  //     "Money Matters",
  //     "Hidden Nature & When Under Pressure",
  //     "Work Style and Leadership"
  //   ],      
  // },
]

export const pricingPersonaStatic = [
  {
    code: "persona_general",
    name: "General Persona",
    price: 100000,
    discountPrice: 50000,
    duration: 1,
    duration_type: "day",
    desc: "Hanya dapat dilihat di website",
    features: [
      "Personality",
      "Hidden Nature",
      "Positive And Negative Character",
      "The Bright And The Dark",
      "Strength And Weakness",
      "Work Style",
      "Leadership Style",
      "Business And Wealth",
      "Social Life",
      "Intimate Life",
      "Love and Sex Life",
      "Health Life",
      "Annual Analytic",
      "Annual Interpretation",
      "Annual Condition",
    ],
  },
  {
    code: "persona_daily_vibe_1",
    name: "Daily Vibe Persona",
    price: 5000,
    discountPrice: 3000,
    duration: 1,
    duration_type: "day",
    desc: "Hanya dapat dilihat di website",
    features: [
      "Daily Analytic",
      "Situation of the Day",
      "Love and Relationship",
      "Character Health",
      "Travel and Recreation",
      "Business And Wealth",
    ],
  },
  {
    code: "persona_daily_vibe_10",
    name: "Daily Vibe Persona",
    price: 50000,
    discountPrice: 15000,
    duration: 10,
    duration_type: "day",
    desc: "Hanya dapat dilihat di website",
    features: [
      "Daily Analytic",
      "Situation of the Day",
      "Love and Relationship",
      "Character Health",
      "Travel and Recreation",
      "Business And Wealth",
    ],      
  },
  {
    code: "persona_daily_vibe_20",
    name: "Daily Vibe Persona",
    price: 100000,
    discountPrice: 30000,
    duration: 20,
    duration_type: "day",
    desc: "Hanya dapat dilihat di website",
    features: [
      "Daily Analytic",
      "Situation of the Day",
      "Love and Relationship",
      "Character Health",
      "Travel and Recreation",
      "Business And Wealth",
    ],      
  },
]
export const pricingMatchingStatic = [
  {
    code: "matching_partnership",
    name: "Matching Partnership",
    product: [
      {
        price: 20000,
        discountPrice: 15000,
        duration: 5,
        duration_type: "day",    
      },
      {
        price: 40000,
        discountPrice: 25000,
        duration: 10,
        duration_type: "day",
      },
    ],
    desc: "Hanya dapat dilihat di website",
    features: [
      "Bar chart Result for General Matched (%)",
      "Partnership Matched",
      "Partnership Guidance",
      "Relation Style",
      "Interaction",
      "Deal With",
      "Bar chart Result for Today Matched (%)",
      "Daily Partnership",
      "Business Perspective",
      "Good Dealing",
      "Improving Performance",
      "Situation of The Day",
    ],
  },
  {
    code: "matching_romance",
    name: "Matching Romance",
    product: [
      {
        price: 20000,
        discountPrice: 15000,
        duration: 5,
        duration_type: "day",
      },
      {
        price: 40000,
        discountPrice: 25000,
        duration: 10,
        duration_type: "day",
      }
    ],
    desc: "Hanya dapat dilihat di website",
    features: [
      "Bar chart Result for General Matched (%)",
      "Romance Matched",
      "Romance Guidance",
      "Romance Compatibility",
      "Guide Interaction",
      "Love Life",
      "Sex Life",
      "Bar chart Result for Today Matched (%)",
      "Daily Romance",
      "Romance Guidance",
      "Situation of The Day",
    ],
  },
  {
    code: "matching_friendship",
    name: "Matching Friendship",
    product: [
      {
        price: 20000,
        discountPrice: 15000,
        duration: 5,
        duration_type: "day",
      },
      {
        price: 40000,
        discountPrice: 25000,
        duration: 10,
        duration_type: "day",
      }
    ],
    desc: "Hanya dapat dilihat di website",
    features: [
      "Bar chart Result for General Matched (%)",
      "Friendship Matched",
      "Relationship",
      "Guide on Friendship",
      "Tips and Ideas",
      "Interaction",
      "Bar chart Result for Today Matched (%)",
      "Daily Friendship",
      "Friends Activity and Traveling",
      "Situation of The Day",
    ],
  },
]
export const pricingPersonaNewStatic = [
  {
    code: "persona",
    name: "Persona",
    product: [
      {
        id: -1,
        price: 20000,
        discountPrice: 15000,
        duration: 5,
        duration_type: "day",    
      },
      {
        id: -2,
        price: 40000,
        discountPrice: 25000,
        duration: 10,
        duration_type: "day",
      },
    ],
    desc: "Hanya dapat dilihat di website",
    features: [
      "General Persona:",
      "Personality",
      "Hidden Nature",
      "Positive And Negative Character",
      "The Bright And The Dark",
      "Strength And Weakness",
      "Work Style",
      "Leadership Style",
      "Business And Wealth",
      "Social Life",
      "Intimate Life",
      "Love and Sex Life",
      "Health Life",
      "Annual Analytic",
      "Annual Interpretation",
      "Annual Condition",
      "Daily Vibe Persona:",
      "Daily Analytic",
      "Situation of the Day",
      "Love and Relationship",
      "Character Health",
      "Travel and Recreation",
      "Business And Wealth"
    ],
  },
]
