import React from 'react';
import FilledInput from '@mui/material/FilledInput';
import useStyles from './style';
import Button from '../button';
import FacebookIcon from 'assets/svg/facebook.js';
import InstagramIcon from 'assets/svg/instagram.js';
import TwitterIcon from 'assets/svg/twitter.js';
import MailIcon from 'assets/svg/mail.js'
// import MailIcon from 'assets/svg/mail.js';

const Footer = () => {
  const styles = useStyles();

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className="item">
          <div>
            <h6 className="title">N A V I G A T I O N</h6>
            <div className={styles.containerContact}>
              <a href="/about-us"><p className="content">About Us</p></a>
              <span>|</span>
              <a href="/community-guideline"><p className="content">Community Guideline</p></a>
            </div>
          </div>
        </div>
        <div className="item">
          <h6 className="title">L E G A L</h6>
          <div className={styles.containerContact}>
            <a href="/disclaimer"><p className="content">Disclaimer</p></a>
            <span>|</span>
            <a href="/terms-of-service"><p className="content">Term of Services</p></a>
            <span>|</span>
            <a href="/privacy-policy"><p className="content">Privacy Policy</p></a>
          </div>
        </div>
        <div className="item">
          <h6 className="title">C O N T A C T</h6>
          <div className={styles.containerContact}>
            <MailIcon />
            <p className="content">contact@personatalenta.id</p>
          </div>
        </div>
        {/* <div>
          <p className="content">Be the first to get the latest update from Persona Talenta</p>
          <div className={styles.newsletter}>
            <FilledInput disableUnderline className={styles.containerInput} placeholder="Alamat Email" fullWidth id="filled-email" label="Alamat Email" inputProps={{className: styles.inputEmail}} />
            <Button variant="outlined" label="Subscribe" />
          </div>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
