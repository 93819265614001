import { Flex, Text } from '@chakra-ui/react';
import Header from 'components/header';
import React, { useEffect, useState } from 'react';
import PricingMatching from 'views/pricingMatching';
import PricingPersona from 'views/pricingPersona';
import PricingProfiling from 'views/pricingProfiling';

const Pricing = (props) => {
  const [user, setUser] = useState();

  useEffect(() => {
    let localUser = localStorage.getItem('user');
    if (localUser) {
      setUser(JSON.parse(localUser))
    }
  }, [])

  useEffect(() => {
    // Define a cleanup variable
    let ignore = false;

    if (props.location.hash && !ignore) {
      const element = document.getElementById(props.location.hash.slice(1));
      console.log(element)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }

    // Cleanup function to avoid updating after unmount
    return () => {
      ignore = true;
    };
  }, [props.location.hash]);


  return (
    <Flex direction="column">
      <Header />
      <Flex alignItems="center" direction="column" mt={20} gap={10} pl="20px">
        <Flex id="profiling" direction="column" alignItems="start" maxWidth={1080} width="100%">
          <Text fontWeight="bold" fontSize="lg">PRICING PROFILING</Text>
          <Flex direction="row" mt={5} overflowX="hidden" width="100%">
            <PricingProfiling user={user} />
          </Flex>
        </Flex>
        <Flex id="matching" direction="column" alignItems="start" maxWidth={1080} width="100%">
          <Text fontWeight="bold" fontSize="lg">PRICING MATCHING</Text>
          <Flex direction="row" mt={5} overflowX="hidden" width="100%">
            <PricingMatching user={user} />
          </Flex>
        </Flex>
        <Flex id="persona" direction="column" alignItems="start" maxWidth={1080} width="100%">
          <Text fontWeight="bold" fontSize="lg">PRICING PERSONA</Text>
          <Flex direction="row" mt={5} overflowX="hidden" width="100%">
            <PricingPersona user={user} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Pricing;
