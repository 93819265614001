import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
    backgroundColor: "#fff"
  },
  containerServiceItem: {
    backgroundColor: '#FCEDE6',
    padding: 20,
    margin: 10,
    flex: 1,
    flexWrap: 'wrap',
    width: "100%",
    minWidth: '350px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '300px',
    },  
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: '100px',
    },  
},
  title: {
    color: '#E24E06',
  },
  containerItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10
  },
  more: {
    textAlign: 'end',
    color: 'blue'
  }
}));
