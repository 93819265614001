import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
  },
  appHeader: {
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    gap: 40,
    fontFamily: 'Manrope',
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.05)',
    filter: 'drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.05))',
    fontSize: 16,
    backgroundColor: '#fff',
    position: 'fixed',
    top: 0,
    zIndex: 9,
    width: '100%',
    '& .active': {
      fontWeight: 700,
      borderBottom: '2px solid #E24E06',
      padding: '5px 0',
    }
  },
  heroContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 100
    // backgroundImage: 'url(../asset/img/hero.png)',
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'right 15% top 50%',
    // backgroundSize: 500,
  },
  hero: {
    // marginTop: 50,
    // marginBottom: 50,
    display: 'flex',
    flexDirection: 'row',  
    maxWidth: '1040px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 100,
    backgroundColor: '#fff',
    padding: '50px 50px 20px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '720px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 50,
    },
    '& .title': {
      fontWeight: 700,
      fontSize: 36,
    },
    '& .content': {
      fontSize: 22,
    },
    '& .yellow': {
      color: '#E24E06',
      fontWeight: 700,
    },
    '& .swiper': {
      paddingBottom: 50
    }

  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFF',
    maxWidth: 370,
    borderRadius: 8,
    padding: 40,
    gap: 10,
    boxShadow: '4px 4px 12px 0px rgba(0, 0, 26, 0.5)',
    '& .title': {
      fontFamily: 'Audiowide',
      color: '#E24E06',
      textAlign: 'center',
      fontSize: 28,
    }
  },
  label: {
    fontFamily: 'Manrope!important',
  },
  forgotPassword: {
    textAlign: 'center',
    fontSize: 14,
    marginTop: 15,
  },
  buttonLogin: {
    padding: '12px 28px!important',
    backgroundColor: '#E24E06!important',
    borderRadius: '8px!important',
    marginTop: '20px!important',
    fontFamily: 'Manrope!important',
    fontWeight: '700!important',
  },
  error: {
    textAlign: 'center',
    backgroundColor: '#ffbb33',
    color: '#fff',
    width: '100%',
    borderRadius: 5,
    padding: '10px 0',
    fontWeight: 700
  },
  pleaseLogin: {
    textAlign: 'center',
    backgroundColor: '#E24E06',
    boxShadow: '4px 4px 12px 0px rgba(0, 0, 26, 0.5)',
    color: '#fff',
    width: '100%',
    borderRadius: 5,
    padding: '10px 0',
    animation: 'blink 4s linear infinite',
    fontWeight: 700
  }
}));
