import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
  },
  appHeader: {
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    gap: 40,
    fontFamily: 'Manrope',
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.05)',
    filter: 'drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.05))',
    fontSize: 16,
    '& .containerHeader': {
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '1040px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '0 20px',
      }
    },
    '& .active': {
      fontWeight: 700,
      borderBottom: '2px solid #E24E06',
      padding: '5px 0',
    },
    '& .menu': {
      display: 'flex',
      gap: 60,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        gap: 20,
        fontSize: 14
      }
    },
    '& .logo': {
      color: '#E24E06',
      fontFamily: 'Audiowide',
      fontWeight: 400,
      fontSize: 28,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      }
    }
  },
  services: {
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '64vh',
    padding: '50px 10px 0'
  },
  servicesContainer: {
    marginTop: 30,
    marginBottom: 60,
    maxWidth: '1040px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 100,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 360,
    }
  },
  containerInput: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 360,
    }
  },
  inputLabel: {
    marginBottom: 10
  },
  containerSuccess: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    marginTop: 20,
  },
  formControl: {
    width: '100%',
    marginBottom: '24px!important',
  },
  input: {
    borderRadius: 8,
    position: 'relative',
    border: '1px solid #C2C2C2',
    fontSize: 16,
    padding: '10px 12px',
    fontWeight: 700,
  },
  label: {
    fontWeight: '700!important',
    fontFamily: 'Manrope!important',
    fontSize: '16px!important',
    color: '#262B31!important',
    display: 'contents!important',
    marginBottom: '0 0 10px!important',
  },
  containerPassword: {
    gap: 10,
    display: 'flex',
    marginTop: 40,
    flexDirection: 'column'
  },
  error: {
    textAlign: 'center',
    backgroundColor: '#ffbb33',
    color: '#fff',
    width: '70%',
    borderRadius: 5,
    padding: '10px 0',
    margin: '10px 0',
    display: 'block',
    // width: '100%',
    fontWeight: 700
  }
}));
