import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
    backgroundColor: "#fff"
  },
  containerListItem: {
    marginTop: 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 16px' 
  },
  containerMenu: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    maxWidth: "100%"
  }
}));
