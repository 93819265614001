import React, { useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import Header from "components/header";
import MatchingFriendship from '../../assets/img/MatchingFriendship.webp';
import MatchingPartnership from '../../assets/img/MatchingPartnership.webp';
import MatchingRomance from '../../assets/img/MatchingRomance.webp';
import useStyles from './style';
import Footer from "components/footerUser";

const MatchingService = props => {
  const styles = useStyles();
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    if (props?.location?.hash?.split('#')[1]) {
      setActivePage(props?.location?.hash?.split('#')[1])
    }
  }, [])

  return (
    <Flex direction="column" gap={5}>
      <Header active="home" />
      <Flex justifyContent="center" mt={16} width="100%">
        <Flex maxWidth={1080} overflowX="hidden" width="100%" direction="column">
          <Flex gap={{ sm: 3, md: 5 }} flexWrap="nowrap" overflowX="auto" style={{ scrollbarWidth: 'none' }} width="100%">
            <Flex direction="column" flex={1}>
              <img src={MatchingPartnership} alt="profile" className={styles.img} />
              <Flex width={{ sm: 150, md: '100%' }} backgroundColor={activePage == 0 ? "#B02418" : "#016A70"} cursor="pointer" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center" onClick={() => setActivePage(0)}>
                <Text fontSize={{sm: 'xs', md: 'sm'}} color="#fff" fontWeight="bold" textAlign="center">PARTNERSHIP MATCHING</Text>
              </Flex>
            </Flex>
            <Flex direction="column" flex={1}>
              <img src={MatchingRomance} alt="profile" className={styles.img} />
              <Flex width={{ sm: 150, md: '100%' }} backgroundColor={activePage == 1 ? "#B02418" : "#016A70"} cursor="pointer" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center" onClick={() => setActivePage(1)}>
                <Text fontSize={{sm: 'xs', md: 'sm'}} color="#fff" fontWeight="bold" textAlign="center">ROMANCE MATCHING</Text>
              </Flex>
            </Flex>
            <Flex direction="column" flex={1}>
              <img src={MatchingFriendship} alt="profile" className={styles.img} />
              <Flex width={{ sm: 150, md: '100%' }} backgroundColor={activePage == 2 ? "#B02418" : "#016A70"} cursor="pointer" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center" onClick={() => setActivePage(2)}>
                <Text fontSize={{sm: 'xs', md: 'sm'}} color="#fff" fontWeight="bold" textAlign="center">FRIENDSHIP MATCHING</Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex padding={5} borderWidth={1} borderColor="#016A70" borderRadius={20}>
            {activePage == 0 ? (
              <Flex direction="column" gap={3}>
                <Text fontSize={{sm: 'xs', sm: 'sm'}}>Layanan Matching Partnership memberikan analisis untuk menilai dan meningkatkan kecocokan dalam kemitraan bisnis. Hasil yang ditampilkan dalam bentuk grafik batang (General Matched %) menunjukkan tingkat kecocokan umum antara mitra, yang mencakup berbagai aspek penting seperti gaya hubungan (Relation Style), interaksi (Interaction), dan cara menangani masalah (Deal With). Selain itu, layanan ini juga menyediakan panduan kemitraan (Partnership Guidance) untuk membantu mitra bekerja sama lebih efektif dan harmonis.</Text>
                <Text fontSize={{sm: 'xs', sm: 'sm'}}>Layanan ini juga mencakup analisis harian yang ditampilkan dalam bentuk grafik batang (Matched %), yang menunjukkan kecocokan harian antara mitra (Daily Partnership). Perspektif bisnis (Business Perspective), rekomendasi untuk transaksi yang baik (Good Dealing), dan tips untuk meningkatkan kinerja (Improving Performance), juga disertakan untuk membantu mitra memaksimalkan potensi kemitraan setiap hari. Dengan memahami situasi hari ini (Situation of The Day), mitra dapat merencanakan strategi yang tepat dan meningkatkan produktivitas serta keberhasilan bisnis.</Text>
              </Flex>
            ) : activePage == 1 ? (
              <Flex direction="column" gap={3}>
                <Text fontSize={{sm: 'xs', sm: 'sm'}}>Layanan Matching Romance menyediakan analisis komprehensif untuk menilai dan meningkatkan kecocokan dalam hubungan romantis. Hasil analisis ditampilkan dalam bentuk grafik batang (General Matched %), yang menunjukkan tingkat kecocokan umum antara pasangan. Layanan ini mencakup penilaian kecocokan romansa (Romance Matched) serta panduan untuk hubungan romantis (Romance Guidance), membantu pasangan memahami tingkat kompatibilitas (Romance Compatibility). Selain itu, layanan ini juga menawarkan panduan interaksi (Guide Interaction) yang membantu pasangan berkomunikasi dan berinteraksi dengan lebih baik dalam kehidupan cinta (Love Life) dan kehidupan seks (Sex Life).</Text>
                <Text fontSize={{sm: 'xs', sm: 'sm'}}>Setiap hari, layanan ini juga menyediakan analisis harian yang ditampilkan dalam bentuk grafik batang (Today Matched %), yang menunjukkan kecocokan harian antara pasangan (Daily Romance). Dengan panduan harian untuk romansa (Romance Guidance), pasangan dapat memahami situasi hari ini (Situation of The Day) dan menyesuaikan interaksi untuk memaksimalkan keharmonisan dan kebahagiaan dalam hubungan. Dengan layanan ini, pasangan dapat membangun dan memelihara hubungan yang lebih kuat dan memuaskan, menghadapi tantangan bersama, dan menikmati kehidupan romantis yang lebih harmonis setiap hari.</Text>
              </Flex>
            ) : activePage == 2 ? (
              <Flex direction="column" gap={3}>
                <Text fontSize={{sm: 'xs', sm: 'sm'}}>Layanan Matching Friendship menyediakan analisis untuk menilai dan memperkuat kecocokan dalam persahabatan. Hasil analisis ini ditampilkan dalam bentuk grafik batang (General Matched %), yang menggambarkan tingkat kesesuaian umum antara teman-teman. Melalui penilaian kecocokan persahabatan (Friendship Matched), layanan ini menawarkan panduan hubungan (Guide on Friendship) dan berbagai tips serta ide (Tips and Ideas) untuk membangun interaksi yang lebih baik. Dengan memahami aspek-aspek penting dari interaksi (Interaction) dalam persahabatan, individu dapat menciptakan hubungan yang lebih harmonis dan saling mendukung.</Text>
                <Text fontSize={{sm: 'xs', sm: 'sm'}}>Selain itu, layanan ini juga menyediakan analisis harian yang ditampilkan dalam bentuk grafik batang (Today Matched %), yang menunjukkan tingkat kecocokan harian antara teman-teman (Daily Friendship). Panduan harian ini mencakup aktivitas dan perjalanan yang dapat dilakukan bersama teman (Friends Activity and Traveling), serta memberikan gambaran tentang situasi hari ini (Situation of The Day). Dengan informasi ini, teman-teman dapat merencanakan aktivitas yang menyenangkan dan bermanfaat, memperkuat ikatan persahabatan mereka, dan menikmati waktu bersama dengan lebih baik setiap harinya.</Text>
              </Flex>
            ) : null}
          </Flex>
          <table id="tableMatching">
            <tr>
              <th><Text fontSize={{sm: 'xs', sm: 'sm'}}>No</Text></th>
              <th><Text fontSize={{sm: 'xs', sm: 'sm'}}>Range Value</Text></th>
              <th><Text fontSize={{sm: 'xs', sm: 'sm'}}>Category</Text></th>
              <th><Text fontSize={{sm: 'xs', sm: 'sm'}}>Deskripsi</Text></th>
            </tr>
            <tr>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>1</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>{`- < 50%`}</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>Average</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>Perlu usaha besar untuk saling mengerti dan memahami</Text></td>
            </tr>
            <tr>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>2</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>51% - 60</Text>%</td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>Moderate</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>Mengisi dan mengurangi kelemahan</Text></td>
            </tr>
            <tr>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>3</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>61% - 70%</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>Intermediate</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>Memberi dorongan dan memperbaiki kekurangan</Text></td>
            </tr>
            <tr>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>4</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>71% - 80%</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>Leading</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>Meningkatkan kekuatan bersama</Text></td>
            </tr>
            <tr>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>5</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>81% - 90%</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>Advance</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>Kombinasi yang erat</Text></td>
            </tr>
            <tr>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>6</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>91% - 100%</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>The BEST</Text></td>
              <td><Text fontSize={{sm: 'xs', sm: 'sm'}}>Seiring sejalan</Text></td>
            </tr>
          </table>
        </Flex>
      </Flex>
      <Footer />
    </Flex>
  )
}

export default MatchingService;
