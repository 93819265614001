import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
    maxWidth: '1040px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff'
  },
  menu: {
    position: 'fixed',
    top: 50,
    zIndex: 1,
    width: "100%",
    padding: '10px 20px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  bottomContainer: {
    display: 'flex',
    marginTop: 10,
    padding: '40px 0',
    maxWidth: '1080px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px',
    },  
    '& .title': {
        margin: 0,
    },
    '& .titleContent': {
        margin: 0
    },
    '& .css-12i7wg6-MuiPaper-root-MuiDrawer-paper': {
        zIndex: 0
    }
  },
  containerDesc: {
    marginTop: 20,
  },
  containerContentMenu: {
    padding: '0 30px',
    textAlign: 'justify',
    '& .book': {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,  
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },  
  }
}));
