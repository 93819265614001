import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
  },
  appHeader: {
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    gap: 40,
    fontFamily: 'Manrope',
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.05)',
    filter: 'drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.05))',
    fontSize: 16,
    '& .containerHeader': {
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '1040px',
      width: '100%'
    },
    '& .active': {
      fontWeight: 700,
      borderBottom: '2px solid #E24E06',
      padding: '5px 0',
    },
    '& .menu': {
      display: 'flex',
      gap: 60,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        gap: 20,
      }
    },
    '& .logo': {
      color: '#E24E06',
      fontFamily: 'Audiowide',
      fontWeight: 400,
      fontSize: 28,
    },
    '& .yellow': {
        color: '#E24E06'
    }
  },
  menu: {
    cursor: 'pointer',
  },
  services: {
    display: 'flex',
    minHeight: '66vh'
  },
  servicesContainer: {
    marginTop: 100,
    marginBottom: 60,
    maxWidth: '1040px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    }
  },
  breadcrumbsContainer: { 
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between'
  },
  containerContent: {
    flexDirection: 'row',
    display: 'flex',
    gap: 20,
    marginTop: 30,
  },
  menuContainer: {
    filter: 'drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.1))',
    minWidth: 245,
    '& .account': {
        backgroundColor: '#E24E06',
        color: '#fff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px 20px',
        gap: 12
    },
    '& .active': {
        backgroundColor: '#FCEDE6',
        padding: '10px 20px',
    },
    '& .item': {
        backgroundColor: '#fff',
        padding: '10px 20px',
        cursor: 'pointer',
    }
  },
  containerForm: {
    gap: 20,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  containerSectionServices: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #F5F6F8',
    boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.1)',
    filter: 'drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.1))',
    padding: '52px 60px',
    minWidth: 655,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      padding: '12px 30px',
    },
    '& .row': {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        },    
    },
    '& .spaceBetween': {
        justifyContent: 'space-between',
    },
    '& .alignItemsCenter': {
        alignItems: 'center',
    },
    '& .add-friend': {
        gap: 20,
        [theme.breakpoints.down('sm')]: {
          gap: 5,
        },    
    },
    '& .label': {
        margin: '15px 0',
        minWidth: 140,
    }
  },
  rightContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '& .btnEdit': {
        backgroundColor: '#E24E06',
        color: '#fff',
        padding: '10px 25px',
        borderRadius: 8,
        fontWeight: 700,
    },
    '& .item': {
        gap: 20,
    }
  },
  btnAddFriend: {
    backgroundColor: '#E24E06',
    color: '#fff',
    padding: '10px 25px',
    borderRadius: 8,
    fontWeight: 700,
    cursor: 'pointer'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #C2C2C2',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
  },
  formControl: {
    width: '100%'
  },
  containerButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 20,
    marginTop: 20
  },
  containerEditProfile: {
    flexDirection: 'column',
    display: 'flex',
    gap: 20,
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },    
  },
  containerQuestion: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 15
    },
  },
  formControlPhoto: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inputFile: {
    display: 'none',
  },
  placeholderPhoto:{
    marginTop: 25,
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
  },
  labelBrowse: {
    color: '#E24E06',
    backgroundColor: 'transparent',
    border: '1px solid #E24E06',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 20px',
    gap: 10,
    borderRadius: 8,
    cursor: 'pointer',
    fontFamily: 'Manrope',
    width: 'max-content',
    '&:hover': {
        backgroundColor: '#E24E06',
        color: '#fff',
        borderColor: '#F5F6F8',
        borderWidth: 1
    }
  },
  infoFile: {
    color: '#8C9197',
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontStyle: 'italic',
    marginTop: 0,
    fontSize: 12,
  },
  infoRequired: {
    color: '#8C9197',
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontSize: 14,
    fontStyle: 'italic',
    margin: 0
  },
  buttonUpload: {
    marginTop: 20,
    color: '#F5F6F8',
    backgroundColor: '#404852',
    fontSize: 14,
    display: 'flex',
    border: '1px solid #404852',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 20px',
    gap: 10,
    borderRadius: 8,
    fontFamily: 'Manrope',
    cursor: 'pointer',
    width: 'max-content',
    '&:hover': {
        backgroundColor: '#262B31',
    }
  },
  error: {
    textAlign: 'center',
    backgroundColor: '#ffbb33',
    color: '#fff',
    width: '100%',
    borderRadius: 5,
    padding: '10px 0',
    fontWeight: 700,
    display: 'block',
    marginBlock: 20
  }
}));
