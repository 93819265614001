// Chakra imports
import { Portal, Box, useDisclosure, Text, Button, Flex } from '@chakra-ui/react';
import axios from 'axios';
import Footer from 'components/footer/FooterAdmin.js';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin.js';
import Sidebar from 'components/sidebar/Sidebar.js';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes.js';
import AddColleague from 'views/admin/addColleague';
import AddFriend from 'views/admin/addFriend';
import FriendDetail from 'views/admin/friendDetail';
import Matching from 'views/admin/matchingWeb';
import PersonaWeb from 'views/admin/personaWeb';
import CompleteProfiling from 'views/admin/products/completeProfiling';
import DailyVibe from 'views/admin/products/dailyVibe';
import MatchingPrice from 'views/admin/products/matching';
import GeneralPersona from 'views/admin/products/generalPersona';
import StandardProfiling from 'views/admin/products/standardProfiling';
import ProfilingWeb from 'views/admin/profilingWeb';
import ProfilingCompleteWeb from 'views/admin/profilingCompleteWeb';
import ProfilingStandardWeb from 'views/admin/profilingStandardWeb';
import EditProfile from 'views/admin/editProfile';
import Header from 'components/header';
import Payment from 'views/admin/payment';
// import ProfilingWebComplete from 'views/admin/profilingWebComplete';

// Custom Chakra theme
export default function Dashboard(props) {
	const { ...rest } = props;
	// states and functions
	const [ fixed ] = useState(false);
	const [ toggleSidebar, setToggleSidebar ] = useState(false);
	const [user, setUser] = useState();
	const [localUser, setLocalUser] = useState();

	useEffect(() => {
		// console.log(theme.breakpoints.values.md)
		const token = localStorage.getItem('token');
		let currentUser = localStorage.getItem('user');
		setLocalUser(JSON.parse(currentUser))
		if (props?.match?.params?.id || props.location.pathname.split('/')[4] || window.location.pathname.split('/')[3] || window.location.pathname.split('/')[4]) {
			axios.get(`https://api.personatalenta.id/user/${window.location.pathname.split('/')[3] ?? props.match.params.type}/${props.match.params.id ?? props.location.pathname.split('/')[4]}`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode",  'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' }})
			.then(res => {
			  setUser(res.data.user ?? res.data.colleagues)
			}).catch(err => {
			  if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
				// navigate('/');
			  }
			})
		} else {
			if(currentUser) {
				setUser(JSON.parse(currentUser))
				setLocalUser(JSON.parse(currentUser))
			} else {
				window.location.assign('/home')
			}
		}
	}, [props.location]);
	
	// functions for changing the states from components
	const getRoute = () => {
		return window.location.pathname !== '/admin/full-screen-maps';
	};
	const getActiveRoute = (routes) => {
		let activeRoute = 'Profile';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].items);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].items);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbar(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].secondary;
				}
			}
		}
		return activeNavbar;
	};
	const getActiveNavbarText = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].messageNavbar;
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/dashboard') {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			}
			if (prop.collapse) {
				return getRoutes(prop.items);
			}
			if (prop.category) {
				return getRoutes(prop.items);
			} else {
				return null;
			}
		});
	};
	document.documentElement.dir = 'ltr';
	const { onOpen } = useDisclosure();
	document.documentElement.dir = 'ltr';
	return (
		<Box>
			<Box>
				<SidebarContext.Provider
					value={{
						toggleSidebar,
						setToggleSidebar
					}}>
					<Sidebar routes={routes} display='none' {...rest} />
					<Box
						float='right'
						minHeight='100vh'
						height='100%'
						overflow='auto'
						position='relative'
						maxHeight='100%'
						w={{ base: '100%', xl: 'calc( 100% - 250px )' }}
						maxWidth={{ base: '100%', xl: 'calc( 100% - 250px )' }}
						transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
						transitionDuration='.2s, .2s, .35s'
						transitionProperty='top, bottom, width'
						transitionTimingFunction='linear, linear, ease'>
						<Portal>
							<Box>
								<Header active="dashboard" />
								<Navbar
									onOpen={onOpen}
									logoText={'Persona Talenta'}
									username={user?.name}
									brandText={getActiveRoute(routes)}
									secondary={getActiveNavbar(routes)}
									message={getActiveNavbarText(routes)}
									fixed={fixed}
									user={user}
									localUser={localUser}
									{...rest}
								/>
							</Box>
						</Portal>

						{getRoute() ? (
							<Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='100vh' pt='50px'>
								<Switch>
									<Route path={`/dashboard/profile/:username/profiling-complete-web`} component={ProfilingCompleteWeb} />
									<Route path={`/dashboard/profile/:username/profiling-standard-web`} component={ProfilingStandardWeb} />
									<Route path={`/dashboard/profile/:username/profiling-web`} component={ProfilingWeb} />
									<Route path={`/dashboard/profile/:username/persona-web`} component={PersonaWeb} />
									<Route path={`/dashboard/profile/:username/matching-web`} component={Matching} />
									{/* <Route path={`/dashboard/profile/:username/product/profiling-book`} component={CompleteProfiling} />
									<Route path={`/dashboard/profile/:username/product/profiling-web`} component={StandardProfiling} />
									<Route path={`/dashboard/profile/:username/product/persona`} component={GeneralPersona} />
									<Route path={`/dashboard/profile/:username/product/daily-vibe`} component={DailyVibe} />
									<Route path={`/dashboard/profile/:username/product/matching`} component={MatchingPrice} /> */}
									<Route path={`/dashboard/profile/:username/checkout/:productId`} component={Payment} />
									<Route path={`/dashboard/profile/:username/edit`} component={EditProfile} />
									<Route path={`/dashboard/friend-list/add-friend`} component={AddFriend} />
									<Route path={`/dashboard/friend-list/add-colleague`} component={AddColleague} />
									<Route path={`/dashboard/friend-list/:type/:id/profiling-complete-web`} component={ProfilingCompleteWeb} />
									<Route path={`/dashboard/friend-list/:type/:id/profiling-standard-web`} component={ProfilingStandardWeb} />
									<Route path={`/dashboard/friend-list/:type/:id/matching-web`} component={Matching} />
									<Route path={`/dashboard/friend-list/:type/:id/profiling-web`} component={ProfilingWeb} />
									<Route path={`/dashboard/friend-list/:type/:id/persona-web`} component={PersonaWeb} />
									{/* <Route path={`/dashboard/friend-list/:type/:id/product/persona`} component={GeneralPersona} />
									<Route path={`/dashboard/friend-list/:type/:id/product/daily-vibe`} component={DailyVibe} />
									<Route path={`/dashboard/friend-list/:type/:id/product/matching`} component={MatchingPrice} />
									<Route path={`/dashboard/friend-list/:type/:id/product/profiling-web`} component={StandardProfiling} />
									<Route path={`/dashboard/friend-list/:type/:id/product/profiling-book`} component={CompleteProfiling} /> */}
									<Route path={`/dashboard/friend-list/:type/:userId/checkout/:productId`} component={Payment} />
									<Route path={`/dashboard/friend-list/:type/:id`} component={FriendDetail} />
									{getRoutes(routes)}
									<Redirect from='/dashboard' to='/dashboard/profile' />
								</Switch>
							</Box>
						) : null}
						{/* <Box>
							<Footer />
						</Box> */}
					</Box>
					{/* <Sidebar routes={routes} display='none' {...rest} hideContent={true} /> */}
				</SidebarContext.Provider>
			</Box>
		</Box>
	);
}
