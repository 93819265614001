/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Circle from '@mui/icons-material/Circle';
import axios from "axios";
import useStyles from './style';
import Back from 'assets/svg/back';
import PaymentMethod from 'components/PaymentMethod';
import QontoStepIcon from 'components/StepIcon';
import { Button } from '@chakra-ui/react';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#8C9197',
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1A7431',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1A7431',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const steps = ['Select Service', 'Confirm Order', 'Payment'];


const CompleteProfiling = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [service, setService] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [sampleComplete, setSampleComplete] = useState('');
  const [sampleStandard, setSampleStandard] = useState('');
  const [user, setUser] = useState({});
  const styles = useStyles();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (props?.match?.params?.id && props?.match?.params?.type) {
      axios.get(`https://api.personatalenta.id/user/${props?.match?.params?.type}/${props.match.params.id}`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode",  'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' }})
      .then(res => {
        setUser(res.data.user ?? res.data.colleagues)
      }).catch(err => {
        if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
          // navigate('/');
        }
      })
    } else {
      let localUser = localStorage.getItem('user');
      if(localUser) {
        setUser(JSON.parse(localUser))
      } else {
        window.location.assign('/home')
      }
    }
    if (token) {
      axios.get(`https://api.personatalenta.id/product/list`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode"}})
      .then(res => {
        const data = res.data.products.filter((product) => product.code === "profiling_complete" || product.code === "profiling_standard")
        setService(data)
      }).catch(err => {
        if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
          // navigate('/');
        }
      })  
    } else {
      // navigate('/')
    }
  }, []);

  useEffect(() => {
    //https://api.personatalenta.id/profiling/pdf/link?id=62&type=1&version=complete
    const token = localStorage.getItem('token');
    axios.get(`https://api.personatalenta.id/profiling/pdf/link?id=62&type=1&version=complete`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode"}})
    .then(res => {
      setSampleComplete(res.data.url)
    }).catch(err => {
      if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
        // navigate('/');
      }
    })  

  }, [])

  useEffect(() => {
    //https://api.personatalenta.id/profiling/pdf/link?id=62&type=1&version=complete
    const token = localStorage.getItem('token');
    axios.get(`https://api.personatalenta.id/profiling/pdf/link?id=62&type=1&version=standard`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode"}})
    .then(res => {
      setSampleStandard(res.data.url)
    }).catch(err => {
      if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
        // navigate('/');
      }
    })  

  }, [])

  const handleBuy = params => {
    setSelectedService(params)
    setActiveStep(1)
  }

  return (
    <div className={styles.app}>
      <div className={styles.services}>
        <div className={styles.containerContent}>
          <div className={styles.stepperContainer}>
            <div onClick={activeStep === 0 ? () => window.history.go(-1) : () => setActiveStep(activeStep - 1)} className={styles.backButton}>
              <Back />
            </div>
            <div className="step">
              <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>
          </div>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 && (
                <div>
                  <div className={styles.bottomContainer}>
                    <div className={styles.containerPricing}>
                      <div className="row spaceBetween">
                        <h6 className="title-type">PROFILING BOOK TYPE</h6>
                        <div className={styles.containerButtonBuy}>
                          <h6 className="content">STANDARD</h6>
                          <h6 className="content">COMPLETE</h6>
                        </div>
                      </div>
                      <div className="row spaceBetween">
                        {/* <h6 className="title-type">PRICING</h6> */}
                        <div className={styles.containerButtonBuy}>
                          {/* // TODO: FIX THIS */}
                          <h6 className="content">Rp {new Intl.NumberFormat('id-ID').format(service[1]?.price)}</h6>
                          <h6 className="content">Rp {new Intl.NumberFormat('id-ID').format(service[0]?.price)}</h6>
                        </div>
                      </div>
                      <div className="row spaceBetween">
                        <p className="note">This is annual one-time charge service. After you own general persona, you don’t have to buy this service again until next year.</p>
                        <div className={styles.containerButtonBuy}>
                          <div className={styles.btnBuy} onClick={() => handleBuy(service[1])}>
                            <span>Buy Now</span>
                          </div>
                          <div className={styles.btnBuy} onClick={() => handleBuy(service[0])}>
                            <span>Buy Now</span>
                          </div>
                        </div>
                      </div>
                      <div className="row spaceBetween">
                        {/* <h6 className="sample">Sample</h6> */}
                        <div className={styles.containerButtonBuy}>
                          <a href={sampleStandard ?? "#"}>
                            <h6 className="viewSample">View Sample</h6>
                          </a>
                          <a href={sampleComplete ?? "#"}>
                            <h6 className="viewSample">View Sample</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.containerFeature}>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Persona Character Analytic</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Brief Persona</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Visible Persona</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Socio Persona</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Hidden Nature</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Hidden Nature</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Potential Persona</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Under Pressure</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Self Persona and Confidence</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Self Persona</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Self-confidence</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Good and Bad Character</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Good Character</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Bad Character</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Two Sided Character Perspective</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">The Bright and The Dark Side</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Positive and Negative Side</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Strength And Weakness</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Thinking and Judgment</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Thinking Judgment</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Thinking Judgment Tips</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Character at Work</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Character at Work</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Work Style</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Work Style</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Over-Acting Probability</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Good and Bad Work Style</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Good Work Style</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Bad Work Style</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Leadership Style</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Leadership Style</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Leadership Tips</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Potential Career</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Career</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Suitable Career</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Suitable Job Role</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Suitable Job Roles</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Suitable Environment</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Dealing and Interacting</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Identifying Profile</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Dealing With Profile</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Interacting With Profile</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Career and Wealth</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Career and Wealth</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Career and Wealth Guide</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Path to Growth</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="green" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Key Talent</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Ambition</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Business Acumen</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Improving Profile</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">The Path To Growth</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="greenLight" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Social and Intimate Profile</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="transparent" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Social Profile</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Intimate Profile</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">General Relationship</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="transparent" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Relationship</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Guide to Relationship</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Friendship and Colleagues</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="transparent" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Friends and Colleagues</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Friends and Colleagues Ideas</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Romance</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="transparent" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Love Relation</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Love and Sex Life</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Family Relationship</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="transparent" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Family Relation</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Good and Bad as a Parent</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Health</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="transparent" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">General Health</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Guide For Health</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Food and Color</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="transparent" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Health and Food</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Food and Color</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Money Matters</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="transparent" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Money Matters</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Money Matters Ideas</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Wealth</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="transparent" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Wealth Description</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Path To Wealth</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth">
                      <div className="row spaceBetween">
                        <p className="feat">Current Year Interpretation</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="transparent" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.childFeature}>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Current Year Prediction</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Tips Of The Year</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Career and Financial Growth</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Social Welfare and Romance</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Home and Family Life</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Mind and body</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Travel and Life Changes</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                      <div className="fullWidth">
                        <div className="row spaceBetween">
                          <p className="feat">Future Outlook</p>
                          <div className={styles.containerButtonBuy}>
                            <Circle className="transparent" fontSize="small" />
                            <Circle className="greenLight" fontSize="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div>
                      <p className="feat">Interpretation of This Year</p>
                      <div  className="row spaceBetween">
                        <p className="note">This interpretation is made on an annual basis, so that each year's interpretation will be renew every January 1st</p>
                        <div className={styles.containerButtonBuy}>
                          <Circle className="transparent" />
                          <Circle className="green" />
                        </div>
                      </div>
                    </div> */}
                    
                  </div>
                </div>
              )}
              {activeStep === 1 && (
                <div className={styles.containerStepTwo}>
                  <div className={styles.containerTopTwo}>
                    <h4 className="title">Service Checkout</h4>
                    <p className="content">Please check again the data you provided below and service you want to order. </p>
                  </div>
                  <div className={styles.containerCenter}>
                    <div className={styles.containerLeft}>
                      <div className={styles.containerOrder}>
                        <h6 className="title">Name</h6>
                        <p className="content">{user.name}</p>
                      </div>
                      <div className={styles.containerOrder}>
                        <h6 className="title">Birth Date</h6>
                        <p className="content">{user.birth_date}</p>
                      </div>
                      <div className={styles.containerOrder}>
                        <h6 className="title">Gender</h6>
                        <p className="content">{user.gender === 0 ? "Male" : "Female"}</p>
                      </div>
                    </div> 
                    <div className={styles.containerLeft}>
                      <div className={styles.containerOrder}>
                        <h6 className="title">Service Type</h6>
                        <div>
                          <p className="content">{selectedService.name}</p>
                        </div>
                      </div>
                      <div className={styles.containerOrder}>
                        <h6 className="title">Total Price</h6>
                        <div>
                          <p className="content">Rp {new Intl.NumberFormat('id-ID').format(selectedService?.price)}</p>
                          <p className="note">Price exclude tax and fee</p>
                          {/* <div className={styles.containerButton}> */}
                            <Button
                              borderWidth={1}
                              borderColor="#1A7431"
                              bgColor="#1A7431"
                              color='#fff'
                              fontSize='sm'
                              w="100%"
                              fontWeight='bold'
                              borderRadius='5px'
                              px='10px'
                              py='10px'
                              onClick={() => setActiveStep(activeStep + 1)}
                            >
                              {"Lanjutkan pembayaran>"}
                            </Button>
                          {/* </div> */}
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              )}
              {activeStep === 2 && (
                <>
                  <PaymentMethod productId={selectedService.id} user={user} isUser={props?.match?.params?.type !== 'colleague'} />
                </>
              )}
            </React.Fragment>
          )}
        </div> 
      </div>
    </div>
  );
}

export default CompleteProfiling;
