import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  footer: {
    backgroundColor: '#E24E06',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerContainer: {
    maxWidth: '1040px',
    padding: '10px 0',
    display: 'flex',
    width: '100%',
    padding: "10px 0",
    gap: 20,
    '& .item': {
      width: '40%',
      padding: '10px 20px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '0px 20px',
      },    
    },
    '& .title': {
        fontWeight: 700,
        fontSize: 16,
        margin: '0px 0',
    },
    '& .content': {
        fontSize: 14,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 0,
      maxWidth: 480
    }
  },
  newsletter: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
  },
  containerInput: {
    color: '#8C9197!important',
    backgroundColor: '#fff!important',
  },
  inputEmail: {
    paddingTop: '8px!important'
  },
  containerSocial: {
    display: 'flex',
    flexDirection: 'row',
    gap: 30,
    marginTop: 20
  },
  containerContact: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    '& .content': {
        margin: '0 0 1em 0',
    }
  }
}));
