import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 20
    }
  },
    containerListItem: {
    marginTop: 80,
    maxWidth: "1080px",
    width: "100%",
    flexGrow: 1,
    padding: '0 16px',
    // [theme.breakpoints.down('sm')]: {
    // }
},
  containerInput: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    '& .list': {
      display: "flex",
      flexDirection: "column",
      width: '100%',
      gap: 5,
      '& p': {
        marginTop: 10,
        marginBottom: 10
      }
    },
    '& .listInput': {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: 5,
      width: "80%"
    }
  },
  containerButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'end',
      marginTop: 10
    }
  }
}));
