import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";

// Admin Imports
import ProfileDashboard from "views/admin/profile";
import FriendList from "views/admin/friendList";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import PurchaseHistory from "views/admin/purchaseHistory";
import MatchingHistory from "views/admin/matchingHistory";

const routes = [
  {
    name: "Profile",
    layout: "/dashboard",
    path: "/profile",
    // icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: ProfileDashboard,
  },
  {
    name: "Friend List",
    layout: "/dashboard",
    path: "/friend-list",
    component: FriendList,
    secondary: true,
  },
  {
    name: "Purchase History",
    layout: "/dashboard",
    // icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/purchase-history",
    component: PurchaseHistory,
  },
  {
    name: "Matching History",
    layout: "/dashboard",
    // icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/matching-history",
    component: MatchingHistory,
  },
];

export default routes;
