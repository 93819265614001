import { makeStyles } from "@mui/styles";
import { height } from "@mui/system";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
  },
  heroContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '100px 20px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '50px 20px 0px',
    },  
  },
  img: {
    width: '100%',
    aspectRatio: 1
  }
}));
