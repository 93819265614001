import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AdminLayout from 'layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import Home from 'views/home';
import Login from 'views/login';
import Dashboard from 'views/dashboard';
import AboutUs from 'views/aboutUs';
import CommunityGuideline from 'views/communityGuideline';
import Forgot from 'views/forgot';
import Services from 'views/services';
import ServiceMenu from 'views/serviceMenu';
import Disclaimer from 'views/disclaimer';
import TermsService from 'views/termsService';
import PrivacyPolicy from 'views/privacyPolicy';
import Signup from 'views/signup';
import Matching from 'views/matching';
import Profiling from 'views/profiling';
import Pricing from 'views/pricing';
import Service from 'views/service';
import MatchingService from 'views/matchingService';
import PersonaService from 'views/persona';

const themeMui = createTheme();

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<ThemeProvider theme={themeMui}>
				<Router>
					<Switch>
						<Route path={`/home`} component={Home} />
						<Route path={`/login`} component={Login} />
						<Route path={`/forgot-password`} component={Forgot} />
						<Route path={`/about-us`} component={AboutUs} />
						<Route path={`/profiling`} component={Profiling} />
						<Route path={`/matching`} component={Matching} />
						<Route path={`/matching-service`} component={MatchingService} />
						<Route path={`/persona-service`} component={PersonaService} />
						<Route path={`/pricing`} component={Pricing} />
						<Route path={`/service`} component={Service} />
						<Route path={`/service-menu`} component={ServiceMenu} />
						<Route path={`/disclaimer`} component={Disclaimer} />
						<Route path={`/terms-of-service`} component={TermsService} />
						<Route path={`/privacy-policy`} component={PrivacyPolicy} />
						<Route path={`/signup`} component={Signup} />
						<Route path={`/community-guideline`} component={CommunityGuideline} />
						<Route path={`/user/dashboard`} component={Dashboard} />
						<Route path={`/dashboard/`} component={AdminLayout} />
						<Redirect from={`/`} to='/home' />
					</Switch>
				</Router>
			</ThemeProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
