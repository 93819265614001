export const columnsDataFriendList = [
  {
    Header: "Display name",
    accessor: "name",
  },
  {
    Header: "Birth date",
    accessor: "birth_date",
  },
  {
    Header: "Action",
    accessor: 'id',
  },
];

export const columnsDataFriends = [
  {
    Header: "Display name",
    accessor: "friend.name",
  },
  {
    Header: "Bio/Occupation",
    accessor: "friend.occupation",
  },
  {
    Header: "Action",
    accessor: 'friend.id',
  },
]

export const columnsDataFriendsRequest = [
  {
    Header: "Display name",
    accessor: "name",
  },
  {
    Header: "Bio/Occupation",
    accessor: "occupation",
  },
  {
    Header: "Action",
    accessor: 'id',
  },
]