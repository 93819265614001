import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={17}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.005 2.5h12a.667.667 0 0 1 .667.667v10.666a.667.667 0 0 1-.667.667h-12a.666.666 0 0 1-.667-.667V3.167a.667.667 0 0 1 .667-.667Zm6.04 5.789L3.77 4.659l-.863 1.016 5.147 4.37 5.054-4.374-.872-1.008-4.19 3.626Z"
      fill="#fff"
    />
  </svg>
)

export default SvgComponent
