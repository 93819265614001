import { makeStyles } from "@mui/styles";
import { height } from "@mui/system";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
  },
  heroContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '100px 20px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '50px 20px 0px',
    },  
  },
  bottomContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 50
  },
  hero: {
    marginTop: 30,
    marginBottom: 60,
    display: 'flex',
    flexDirection: 'row',  
    maxWidth: '1000px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 100,
    backgroundColor: '#3269D2',
    [theme.breakpoints.down('md')]: {
      maxWidth: '720px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      // maxWidth: '360px',
      gap: 50,
    },
    '& .title': {
      fontWeight: 700,
      fontSize: 28,
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },  
    },
    '& .content': {
      fontSize: 18,
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },  
    },
    '& .yellow': {
      color: '#000',
      fontWeight: 700,
    },
    '& .swiper': {
      margin: '50px 50px 20px',
      paddingBottom: 50,
      width: '100%',
    },
    '& .swiper-slide': {
      [theme.breakpoints.down('sm')]: {
        padding: '0 20px',
      },  
    }
  },
  label: {
    fontFamily: 'Manrope!important',
  },
  bottomInfo: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '1040px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
    '& .containerInfo': {
      // backgroundColor: '#1A7431',
      color: "#fff!important",
      // padding: '10px 20px',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: '65%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },  
      '&:hover': {
        transition: 'transform 0.2s',
        transformOrigin: '50% 100%',
        transform: 'scale(1.05)'
      },
    },
    '& .containerSignup': {
      // backgroundColor: '#050C9C',
      color: "#fff!important",
      // padding: '10px 20px',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: '65%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '&:hover': {
        transition: 'transform 0.2s',
        transformOrigin: '50% 100%',
        transform: 'scale(1.05)'
      },
    },
    '& .titleInfo': {
      backgroundColor: '#2F6EBA',
      textTransform: 'uppercase',
      textAlign: 'center',
      margin: '0 0 10px 0',
      padding: 15,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    },
    '& .titleInfo-signup': {
      backgroundColor: '#B02418',
      textTransform: 'uppercase',
      textAlign: 'center',
      margin: '0 0 10px 0',
      padding: 15,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    },
    '& .contentInfo': {
      backgroundColor: '#4EADEA',
      padding: 15,
      paddingInlineStart: 0,
      height: 230,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        height: 130,
      },  
    },
    '& .aboutInfo': {
      backgroundColor: '#4EADEA',
      padding: 15,
      textAlign: 'center',
      height: 230,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        height: 130,
      },  
    },
    '& .aboutSignup': {
      backgroundColor: '#EDAB45',
      padding: 15,
      textAlign: 'center',
      height: 230,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        height: 130,
      },  
    }
  },
  img: {
    width: 250,
    height: 250,
    aspectRatio: 1,
    [theme.breakpoints.down('md')]: {
      width: 300,
      height: 300,  
    },  
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      height: "100%",  
    },  
  }
}));
