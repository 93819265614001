import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Avatar,
} from "@chakra-ui/react";
import axios from "axios";

// Custom components
import Banner from "views/admin/profile/components/Banner";

// Assets
import tableDataTopCreators from "views/admin/marketplace/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/marketplace/variables/tableColumnsTopCreators";
// import TableFriends from "./components/TableFriends";
import { columnsDataPurchaseHistory } from "./variables/columnsData";
import TablePurchased from "./components/TablePurchased";
import { Divider, LinearProgress } from "@mui/material";

export default function PurchaseHistory() {
  const [user, setUser] = useState();
  const [purchased, setPurchased] = useState([]);
  const [activeType, setActiveType] = useState('profiling');
  const [history, setHistory] = useState([])
  const [pagination, setPagination] = useState({ limit: 5, page: 1, max: 1, total: 1 })

  // const navigate = useNavigate()
  // const styles = useStyles();

  useEffect(() => {
    let localUser = localStorage.getItem('user');
    if (localUser) {
      setUser(JSON.parse(localUser))
    } else {
      // navigate('/')
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (activeType === 'matching') {
      axios.get(`https://api.personatalenta.id/friends/matching/list`, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode" } })
        .then(res => {
          if (res?.data?.data) {
            setPurchased(res.data.data)
          }
        }).catch(err => {
          if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
            // navigate('/');
          }
        })
    } else {
      axios.get(`https://api.personatalenta.id/user/transaction?type=${activeType}&limit=${pagination.limit}&page=${pagination.page}`, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode" } })
        .then(res => {
          // if (res?.data?.transactions?.length > 0) {
          setPurchased(res?.data?.transactions);
          setPagination({ ...pagination, max: res.data.totalPages, total: res.data.totalCount })
          // }
        }).catch(err => {
          if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
            // navigate('/');
          }
        })
    }
  }, [pagination.page, pagination.limit, activeType])

  useEffect(() => {
    let localUser = localStorage.getItem('user');
    if (localUser) {
      setUser(JSON.parse(localUser))
      // console.log(user)
    } else {
      window.location.assign('/home')
    }
  }, []);

  const nextPage = () => {
    if (pagination.page < pagination.max) {
      setPagination({ ...pagination, page: pagination.page + 1 })
    }
  };

  const prevPage = () => {
    if (pagination.page > 1) {
      setPagination({ ...pagination, page: pagination.page - 1 })
    }
  };

  const firstPage = () => {
    setPagination({ ...pagination, page: 1 })
  };

  const lastPage = (type) => {
    setPagination({ ...pagination, page: pagination.max })
  };

  const goToPage = (type, page) => {
    setPagination({ ...pagination, page: page })
  };

  const limitPage = (type, limit) => {
    setPagination({ ...pagination, limit: limit })
  };

  const onClickAction = props => {
    if (props.type === 'Download') {
      const token = localStorage.getItem('token');
      let baseUrl;
      if (props.product.product.features === "Standard Profiling Book") {
        baseUrl = `https://api.personatalenta.id/profiling/pdf/link?id=${props.product.user_id}&type=${props.product.is_has_user_id ? 0 : 1}&version=standard`
      } else if (props.product.product.features === "Complete Profiling Book") {
        baseUrl = `https://api.personatalenta.id/profiling/pdf/link?id=${props.product.user_id}&type=${props.product.is_has_user_id ? 0 : 1}&version=complete`
      } else if (props.product.product.features === "Career Profiling Book") {
        baseUrl = `https://api.personatalenta.id/profiling/pdf/career`
      }

      axios.get(baseUrl, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode" }, responseType: 'blob' })
        .then(res => {
          // setLoading(false)
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(res.data);
          a.download = `${props.product.product.features === "Career Profiling Book" ? "Karir Terbaik Untuk" : props.product.product.features} ${user.name}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }).catch(err => {
          if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
            // navigate('/');
          }
        })
    } else if (props.type === 'Web') {
      if (user.id === props.product.user_id) {
        if (props.product.product.features === "Standard Profiling Book") {
          window.location.assign(`/dashboard/profile/${user?.username}/profiling-standard-web`)
        } else if (props.product.product.features === "Complete Profiling Book") {
          window.location.assign(`/dashboard/profile/${user?.username}/profiling-complete-web`)
        } else if (props.product.product.name === "Profiling Web") {
          window.location.assign(`/dashboard/profile/${user?.username}/profiling-web`)
        } else if (props.product.product.name === "Daily Vibe Persona" || props.product.product.name === "General Persona") {
          window.location.assign(`/dashboard/profile/${user?.username}/persona-web`)
        } else if (props.product.product.name === "Matching") {
          window.location.assign(`/dashboard/profile/${user?.username}/matching-web`)
        }
      } else {
        if (props.product.product.features === "Standard Profiling Book") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/profiling-standard-web`)
        } else if (props.product.product.features === "Complete Profiling Book") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/profiling-complete-web`)
        } else if (props.product.product.name === "Profiling Web") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/profiling-web`)
        } else if (props.product.product.name === "Daily Vibe Persona" || props.product.product.name === "General Persona") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/persona-web`)
        } else if (props.product.product.name === "Matching") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/matching-web`)
        }
      }
    } else if (props.type === 'Buy') {
      if (user.id === props.product.user_id && props.product.is_has_user_id) {
        if (props.product.product.features === "Standard Profiling Book" || props.product.product.features === "Complete Profiling Book") {
          window.location.assign(`/dashboard/profile/${user?.username}/product/profiling-book`);
        } else if (props.product.product.name === "Daily Vibe Persona") {
          window.location.assign(`/dashboard/profile/${user?.username}/product/daily-vibe`)
        } else if (props.product.product.name === "General Persona") {
          window.location.assign(`/dashboard/profile/${user?.username}/product/persona`)
        } else if (props.product.product.name === "Profiling Web") {
          window.location.assign(`/dashboard/profile/${user?.username}/product/profiling-web`)
        } else if (props.product.product.name === "Matching") {
          window.location.assign(`/dashboard/profile/${user?.username}/product/matching`)
        }
      } else {
        if (props.product.product.features === "Standard Profiling Book" || props.product.product.features === "Complete Profiling Book") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/product/profiling-book`);
        } else if (props.product.product.name === "Daily Vibe Persona") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/product/daily-vibe`)
        } else if (props.product.product.name === "General Persona") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/product/persona`)
        } else if (props.product.product.name === "Profiling Web") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/product/profiling-web`)
        } else if (props.product.product.name === "Matching") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/product/matching`)
        }
      }
    }
  }

  const onChangeMenu = menu => {
    setPurchased([])
    setActiveType((menu))
    // if (menu === 'profiling') {
    //   setActiveType(0);
    // } else if (menu === 1) {
    //   setActiveType(1);
    // } else if (menu === 2) {
    //   setActiveType(2);
    // }
  }
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  return (
    <Box pt={{ sm: 5, base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        gap={{ base: "20px", xl: "20px" }} mt={10}>
        {/* <Banner
          // gridArea='1 / 1 / 2 / 2'
          avatar={user?.photo}
          name={user?.name}
          job={user?.occupation}
          dob={user?.birth_date}
        /> */}
        <Flex gap={{ sm: 1, md: 30 }} flex={1}>
          <Flex width={{ sm: '100%', md: "20%" }} cursor="pointer" onClick={() => onChangeMenu('profiling')} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeType === 'profiling' ? '#059212' : '#fff'}>
            <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeType === 'profiling' ? '#FFF' : '#000'} fontWeight={activeType === 'profiling' ? 'bold' : 'normal'}>PROFILING SERVICE</Text>
          </Flex>
          <Flex width={{ sm: '100%', md: "20%" }} cursor="pointer" onClick={() => onChangeMenu('matching')} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeType === 'matching' ? '#059212' : '#fff'}>
            <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeType === 'matching' ? '#FFF' : '#000'} fontWeight={activeType === 'matching' ? 'bold' : 'normal'}>MATCHING SERVICE</Text>
          </Flex>
          <Flex width={{ sm: '100%', md: "20%" }} cursor="pointer" onClick={() => onChangeMenu('webview')} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeType === 'webview' ? '#059212' : '#fff'}>
            <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeType === 'webview' ? '#FFF' : '#000'} fontWeight={activeType === 'webview' ? 'bold' : 'normal'}>WEBVIEW SERVICE</Text>
          </Flex>
        </Flex>
        <Divider orientation="horizontal" style={{ width: '75%' }} />
        {activeType === 'matching' ? (
          <Flex flexDir="row" gap={5} flexWrap="wrap" width={{ sm: '100%', md: '75%' }} mt={{ sm: 10, md: 0 }}>
            {purchased.length > 0 && purchased.map((item, index) => (
              <Flex key={index} borderBottomWidth={1} flexDirection="row" py={2} gap={5} minWidth="45%">
                <Flex direction="column" justifyContent="center" minWidth={70}>
                  <Avatar
                    src={item.users?.user1?.photo}
                    h='40px'
                    w='40px'
                    border='1px solid #059212'
                  // borderColor={borderColor}
                  />
                  <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" maxWidth={70}>{item?.users?.user1?.name.split(' ')[0]}</Text>
                </Flex>
                <Flex direction="column" justifyContent="center" minWidth={70}>
                  <Avatar
                    src={item.users?.user2?.photo}
                    h='40px'
                    w='40px'
                    border='1px solid #059212'
                  // borderColor={borderColor}
                  />
                  <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" maxWidth={70}>{item?.users?.user2?.name.split(' ')[0]}</Text>
                </Flex>
                <Flex direction="column" gap={1} justifyContent="center">
                  <Text>General</Text>
                  <Text>Daily</Text>
                </Flex>
                <Flex direction="column" width={{ sm: 130, md: 250 }} gap={2} justifyContent="center">
                  <Box width="100%" position="relative">
                    <LinearProgress sx={{ height: 24, borderRadius: 1, width: '100%', position: 'absolute' }} color={item.matching_type === 0 ? "warning" : item.matching_type === 1 ? "error" : "info"} variant="determinate" value={item.stats.general} />
                    <Text color="white" fontWeight="bold" zIndex={1} position="relative" textAlign="center">{item.stats.general}%</Text>
                  </Box>
                  <Box width="100%" position="relative">
                    <LinearProgress sx={{ height: 24, borderRadius: 1, width: '100%', position: 'absolute' }} color={item.matching_type === 0 ? "warning" : item.matching_type === 1 ? "error" : "info"} variant="determinate" value={item.stats.today} />
                    <Text color="white" fontWeight="bold" zIndex={1} position="relative" textAlign="center">{item.stats.today}%</Text>
                  </Box>
                  {/* <LinearProgress sx={{height: 20, borderRadius: 1, width: '100%'}} color={item.matching_type === 0 ? "warning" : item.matching_type === 1 ? "error" : "info"} variant="determinate" value={item.stats.daily} /> */}
                </Flex>
              </Flex>
            ))}
          </Flex>
        ) : (
          <TablePurchased
            columnsData={columnsDataPurchaseHistory}
            tableData={purchased}
            pagination={pagination}
            nextPage={nextPage}
            prevPage={prevPage}
            firstPage={firstPage}
            lastPage={lastPage}
            limitPage={limitPage}
            goToPage={goToPage}
            onClickAction={onClickAction}
          />
        )}
      </Grid>
    </Box>
  );
}
