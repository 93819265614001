import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
    width: '75%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    }
  },
  services: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '66vh',
    marginTop: 80
  },
  servicesContainer: {
    marginTop: 60,
    marginBottom: 60,
    maxWidth: '1040px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 100,
  },
  stepperContainer: {
    flexDirection: 'row',
    display: 'flex',
    gap: 200,
    alignItems: 'center',
    padding: 30,
    [theme.breakpoints.down('md')]: {
      gap: 120,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'start',
      gap: 10
    },
    '& .step': {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    }
  },
  containerContent: {
    filter: 'drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.1))',
    backgroundColor: '#fff',
    width: '100%'
  },
  contentTop: {
    backgroundImage: 'url(../../../../asset/img/dailyvibe.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '20px 50px 50px',
    minHeight: 170,
    '& .title': {
        letterSpacing: 10,
        color: '#fff'
    }
  },
  descContainer: {
    padding: '20px 50px 50px',
    borderBottom: '1px solid #C2C2C2',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    [theme.breakpoints.down('sm')]: {
      gap: 0,
      padding: '20px 5px 50px',
    },
    '& .title': {
        letterSpacing: 5,
    },
    '& .row': {
        display: 'flex',
        flexDirection: 'row',
        gap: 30,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'row',
          gap: 10
        },
    },
    '& .title-type': {
        letterSpacing: 5,
        margin: '15px 0',
        width: 310,
        [theme.breakpoints.down('md')]: {
          textAlign: 'center',
          width: '100%'
        },
    },
    '& .content': {
        minWidth: 100,
        margin: '15px 0',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
          flex: 1,
          width: '100%'
        },
    },
    '& .note': {
        width: 310,
        color: '#8C9197',
        [theme.breakpoints.down('md')]: {
          flex: 1,
          width: '100%'
        },
    }
  },
  backButton: {
    cursor: 'pointer'
  },
  btnBuy: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 20px',
    gap: 10,
    backgroundColor: '#1A7431',
    color: '#fff',
    borderRadius: 8,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      padding: '10px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px',
    }  
  },
  containerStepTwo: {
    backgroundColor: '#fff',
    padding: '20px 0',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    }
  },
  containerTopTwo: {
    padding: '0px 28px 20px',
    borderBottom: '1px solid #C2C2C2',
    '& .title': {
        margin: 0,
        color: '#404852'
    },
    '& .content': {
        margin: '20px 0'
    }
  },
  containerCenter: {
    padding: '40px 70px',
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    [theme.breakpoints.down('md')]: {
      padding: '40px 50px',
      gap: 10
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '40px 30px',
    },
  },
  containerLeft: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gap: 10,
    },
  },
  containerOrder: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    '& .title': {
        margin: 0,
        width: 170,
        minWidth: 170,
        [theme.breakpoints.down('md')]: {
          // padding: '40px 50px',
          width: 100,
          minWidth: 100
        },
        [theme.breakpoints.down('sm')]: {
          // flexDirection: 'column',
          width: 100,
          minWidth: 100
        },
    },
    '& .content': {
        color: '#404852',
        margin: 0
    },
    '& .note': {
        color: '#8C9197',
        margin: '10px 0'
    }
  },
  containerButton: {
    padding: '0 70px',
  },
  containerFeature: {
    padding: '20px 50px 50px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // gap: 20,
    '& .green': {
      color: '#658E3F'
    },
    '& .feat': {
      minWidth: 200
    },
    '& .row': {
      flexDirection: 'row',
      display: 'flex',
      gap: 10,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      }
    }
  }
}));
