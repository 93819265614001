import BookListItem from "components/BookListItem";
import Header from "components/header";
import React from "react";
// import Header from "../../components/Header";
// import BookListItem from "../../components/BookListItem";
import useStyles from './style';

const ServiceMenu = (props) => {
    const {active} = props;
    const styles = useStyles();
    const data = [
        {
          menu: 'Profiling',
          content: (
            <div id="1" className="book">
              <p>Profiling dengan menggunakan metoda Persona mengandung banyak informasi tentang seseorang berdasarkan data kelahirannya, termasuk tanggal, waktu, bulan, dan tahun kelahiran. Berikut adalah beberapa hal yang dapat diketahui tentang seseorang dengan profiling yang kami lakukan secara komputasi otomatis, yaitu:</p>
                <ol>
                    <li><strong>Kepribadian</strong>: memberikan wawasan tentang karakteristik kepribadian seseorang, termasuk gaya berkomunikasi, sifat bawaan, preferensi, dan kecenderungan emosional.</li>
                    <li><strong>Potensi dan Bakat</strong>: membantu mengidentifikasi potensi dan bakat alami seseorang. Misalnya, seseorang mungkin memiliki kecenderungan terhadap kreativitas, kepemimpinan, atau keterampilan analitis tertentu.</li>
                    <li><strong>Kesehatan</strong>: memberikan gambaran umum tentang kesehatan fisik seseorang dan kemungkinan potensi masalah kesehatan tertentu yang mungkin muncul.</li>
                    <li><strong>Kehidupan Cinta dan Hubungan</strong>: memberikan wawasan tentang bagaimana seseorang berhubungan dengan pasangan romantis dan orang-orang di sekitarnya.</li>
                    <li><strong>Karir dan Karier</strong>: membantu dalam pemahaman tentang jalur karir yang cocok untuk seseorang dan bagaimana meningkatkan peluang kesuksesan dalam karier.</li>
                    <li><strong>Kehidupan Finansial</strong>: memberikan gambaran tentang keberuntungan finansial seseorang, tetapi juga memberikan wawasan tentang kecenderungan dalam mengelola uang dan investasi.</li>
                    <li><strong>Tantangan dan Peluang</strong>: mengidentifikasi tantangan tertentu yang mungkin dihadapi seseorang dalam hidup mereka, serta peluang untuk pertumbuhan dan perkembangan.</li>
                    <li><strong>Hubungan dengan Pasangan, Keluarga dan Teman</strong>: memberikan informasi tentang dinamika hubungan dengan pasangan, keluarga dan persahabatan seseorang.</li>
                    <li><strong>Pengaruh Tahun dan Saat yang Berbeda</strong>: dapat digunakan untuk meramalkan bagaimana pengaruh tahun atau periode tertentu dapat mempengaruhi hidup seseorang.</li>
                </ol>
              <p>Penting untuk diingat bahwa profiling yang kami lakukan ini bukanlah ilmu pasti dan tidak dapat mengidentifikasi peristiwa secara spesifik. Profiling lebih berfungsi sebagai alat untuk membantu seseorang memahami diri sendiri dan memberikan wawasan tentang potensi dan kecenderungan dalam hidup. Interpretasi Profiling yang akurat memerlukan pengetahuan mendalam tentang sistem yang kami buat ini, dan oleh karena itu, banyak orang mencari bantuan dari seorang ahli untuk analisis yang lebih mendalam dan tepat.</p>
            </div>
          )
        },
        {
          menu: 'Persona',
          content: (
            <div id="2" className="book">
                <p>Persona merupakan bagian dari teori kepribadian yang merujuk pada citra atau masker sosial yang diproyeksikan oleh individu ke dunia luar sebagai respons terhadap tuntutan sosial dan norma-norma masyarakat.</p>
                <p>Persona merupakan gambaran dari bagaimana seseorang ingin dilihat atau dipandang oleh orang lain, sehingga mencerminkan bagian dari diri mereka yang diungkapkan kepada dunia luar. Ini dapat menjadi peran atau identitas yang dimainkan seseorang dalam berbagai situasi sosial untuk mencocokkan diri dengan harapan dan norma sosial tertentu. Mengenakan persona bukan berarti seseorang berpura-pura atau menjadi palsu. Sebaliknya, ini adalah mekanisme yang alami dan diperlukan dalam interaksi sosial, karena tidak selalu semua aspek dari diri seseorang relevan atau sesuai untuk ditampilkan di setiap situasi.</p>
                <p>Namun, Jung juga mengingatkan bahwa persona bukanlah representasi keseluruhan dari diri seseorang. Di balik persona tersebut, ada sisi yang lebih dalam dari diri yang dikenal sebagai "bayangan" (shadow). Bayangan mencakup bagian-bagian diri yang tidak disadari, termasuk pikiran, perasaan, dan dorongan yang lebih kompleks dan mungkin bertentangan dengan citra yang ditampilkan melalui persona.</p>
                <p>Penting untuk memahami persona dan bayangan sebagai bagian dari proses individu dalam menjalani kehidupan sosial yang sehat. Pengenalan dan penerimaan diri secara keseluruhan, termasuk aspek-aspek bayangan, dapat membantu seseorang mengintegrasikan diri secara lebih baik dan membangun kepribadian yang lebih otentik dan seimbang.</p>
            </div>
          )
        },
        {
            menu: "Socio Persona",
            content: (
                <div id="3" className="book">
                    <p>Socio Persona adalah layanan Sosial Media yang kami berikan untuk para pengguna Persona Talenta yang memberi berbagai manfaat sebagaimana sosial media lainnya. Berikut adalah beberapa manfaat Socio Persona sebagai sarana sosial media :</p>
                    <ol>
                        <li><strong>Komunikasi dan Koneksi</strong>: memungkinkan kita untuk berkomunikasi dan terhubung dengan teman, keluarga, dan orang lain dari berbagai belahan dunia. Ini memungkinkan pembentukan dan memelihara hubungan sosial yang kuat, terlepas dari jarak geografis.</li>
                        <li><strong>Memperluas Jaringan Sosial</strong>: memungkinkan kita untuk bertemu orang baru dengan minat dan pandangan yang serupa. Ini memperluas jaringan sosial kita dan memungkinkan kita untuk berinteraksi dengan orang-orang dari berbagai latar belakang budaya, etnis, dan sosial.</li>
                        <li><strong>Berbagi Informasi dan Pengetahuan</strong>: menjadi platform untuk berbagi berita, artikel, dan informasi penting lainnya. Ini dapat membantu meningkatkan kesadaran tentang isu-isu global, pendidikan, kesehatan, lingkungan, dan banyak lagi.</li>
                        <li><strong>Pendidikan dan Pembelajaran</strong>: memberikan akses ke sumber daya pendidikan dan pembelajaran.</li>
                        <li><strong>Kesadaran Sosial</strong>: menjadi alat penting dalam membangkitkan kesadaran tentang isu-isu sosial dan politik. Kampanye sosial dapat dengan cepat menyebar dan mendapatkan dukungan luas dari masyarakat.</li>
                        <li><strong>Pemberdayaan Individu dan Kelompok</strong>: memberdayakan individu dan kelompok untuk menyuarakan pandangan, mengorganisir aksi sosial, dan membentuk gerakan atau kampanye dengan lebih mudah.</li>
                    </ol>
                </div>
            )
        },
        {
            menu: "Matching",
            content: (
                <div id="4" className="book">
                    <p>Dalam perhitungan Persona, kecocokan atau kesesuaian dapat dihitung untuk berbagai aspek dan hubungan dalam kehidupan seseorang. Beberapa di antaranya meliputi:</p>
                    <ol>
                        <li><strong>Kecocokan Rekan Kerja</strong>: Dalam lingkungan kerja, Persona dapat membantu mengidentifikasi tingkat kesesuaian dan kompatibilitas antara rekan kerja. Hal ini dapat membantu dalam membentuk tim yang efektif dan harmonis.</li>
                        <li><strong>Kecocokan dalam Partnership Bisnis</strong>: Bagi kamu yang berencana untuk memulai bisnis bersama, Persona dapat memberikan wawasan tentang sejauh mana dua individu tersebut kompatibel dalam hal visi, misi, dan tujuan bisnis atau kemitraan bisnis.</li>
                        <li><strong>Kecocokan Pasangan / Romance</strong>: Persona dapat digunakan untuk menilai kecocokan pasangan dalam hubungan romantis. Analisis dilakukan untuk melihat kompatibilitas elemen, karakteristik kepribadian, dan hubungan antara masing-masing individu.</li>
                        <li><strong>Kecocokan Persahabatan</strong>: Persona dapat membantu menilai tingkat kesesuaian dua orang dalam sebuah persahabatan. Aspek kepribadian, tujuan hidup, dan elemen-elemen dalam Persona akan dianalisis untuk melihat sejauh mana dua individu bisa saling mendukung dan berkomunikasi dengan harmonis.</li>
                        <li><strong>Kecocokan Hubungan Keluarga</strong>: Persona juga dapat digunakan untuk mengevaluasi kecocokan antara anggota keluarga. Misalnya, seberapa baik hubungan antara orangtua dan anak, saudara-saudara, atau antara pasangan suami-istri dalam konteks keluarga.</li>
                        <li><strong>Kecocokan Diri (Self-Matching)</strong>: Selain menghitung kecocokan antara dua individu, Persona juga dapat digunakan untuk mengevaluasi kecocokan diri seseorang dengan lingkungannya, pekerjaan, atau aktivitas tertentu. Ini membantu seseorang memahami apakah pilihan-pilihan hidup yang dibuat sesuai dengan karakteristik dan potensi diri.</li>
                    </ol>
                    <table id="tableMatching">
                        <tr>
                            <th>No</th>
                            <th>Range Value</th>
                            <th>Category</th>
                            <th>Deskripsi</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>{`- < 50%`}</td>
                            <td>Average</td>
                            <td>Perlu usaha besar untuk saling mengerti dan memahami</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>51% - 60%</td>
                            <td>Moderate</td>
                            <td>Mengisi dan mengurangi kelemahan</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>61% - 70%</td>
                            <td>Intermediate</td>
                            <td>Memberi dorongan dan memperbaiki kekurangan</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>71% - 80%</td>
                            <td>Leading</td>
                            <td>Meningkatkan kekuatan bersama</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>81% - 90%</td>
                            <td>Advance</td>
                            <td>Kombinasi yang erat</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>91% - 100%</td>
                            <td>The BEST</td>
                            <td>Seiring sejalan</td>
                        </tr>
                    </table>
                    <p>Penting untuk diingat bahwa hasil perhitungan Persona hanyalah salah satu aspek dalam mengembangkan hubungan atau membuat keputusan dalam kehidupan. Faktor-faktor lain seperti komunikasi, rasa saling menghargai, nilai-nilai yang dipegang, dan kesamaan tujuan juga harus dipertimbangkan dalam membangun hubungan yang sehat dan sukses.</p>
                    
                </div>
            )
        }
      ]
    
    return (
        <div className={styles.app}>
            <Header active="service" />
            <div className={styles.containerListItem}>
                <BookListItem data={data} active={props.location.hash[1] ?? 0} />
            </div>
        </div>
    )
}

export default ServiceMenu;