import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
  },
  appHeader: {
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    gap: 40,
    fontFamily: 'Manrope',
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.05)',
    filter: 'drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.05))',
    fontSize: 16,
    backgroundColor: '#fff',
    position: 'fixed',
    top: 0,
    zIndex: 1,
    width: '100%',
    '& .containerHeader': {
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '1040px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '0 20px',
      }
    },
    '& .active': {
      fontWeight: 700,
      borderBottom: '2px solid #E24E06',
      padding: '5px 0',
    },
    '& .menu': {
      display: 'flex',
      gap: 60,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        gap: 20,
        fontSize: 14
      }
    },
    '& .logo': {
      color: '#E24E06',
      fontFamily: 'Audiowide',
      fontWeight: 400,
      fontSize: 28,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      }
    }
  },
  services: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  servicesContainer: {
    marginTop: 60,
    marginBottom: 60,
    maxWidth: '1040px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 100,
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    }
  },
}));
