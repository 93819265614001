import Footer from 'components/footerUser';
import Header from 'components/header';
import React from 'react';
import useStyles from './style';
import 'assets/css/About.css';
// import Footer from '../../components/Footer';
// import Header from '../../components/Header';

const CommunityGuideline = () => {
  const styles = useStyles();
  
  return (
    <div className={styles.app}>
      <Header />
      <div className={styles.services}>
        <div className={styles.servicesContainer}>
          <h4>Community Guideline</h4>
          <p>Panduan berikut berlaku untuk semua konten di aplikasi Persona Talenta. Dengan menggunakan aplikasi Persona Talenta, Anda menyetujui pedoman ini, Kebijakan Privasi dan Ketentuan Layanan kami. Melanggar pedoman ini dapat mengakibatkan konten dihapus, akun dinonaktifkan, dan kemungkinan pembatasan lainnya. Selanjutnya, kami berhak memblokir siapa saja yang berulang kali melanggar Pedoman Komunitas ini. Persona Talenta berhak untuk menyelidiki dan mengambil tindakan terhadap siapa saja pengguna aplikasi dan website, atas kebijakan Persona Talenta yang melanggar Kebijakan Privasi, Ketentuan Layanan dan Pedoman Komunitas ini. Persona Talenta memiliki kebijakan tanpa toleransi terkait penerapan Pedoman Komunitas dan Persyaratan Layanannya, dan akan mengambil langkah yang diperlukan untuk menegakkannya secara agresif.</p>
          <h5>Keamanan (Security)</h5>
          <h6>Ujaran Kebencian / Perilaku Menyinggung</h6>
          <ul>
            <li>Anda tidak boleh memposting sesuatu yang penuh kebencian yang berkaitan dengan jenis kelamin, identitas gender, ras, etnis, orientasi seksual, cacat fisik atau mental, asal negara, usia, atau penyakit serius seseorang.</li>
            <li>Anda tidak boleh memposting konten cabul, vulgar, eksplisit secara seksual, atau pornografi.</li>
            <li>Anda tidak boleh memposting komentar yang tidak pantas atau menghina tentang ukuran atau bentuk tubuh orang lain, termasuk mempermalukan gemuk/kurus, atau frasa lain apa pun yang dapat memengaruhi kepositifan tubuh seseorang.</li>
          </ul>
          <h6>Ancaman / Kekerasan</h6>
          <ul>
            <li>Anda tidak boleh memposting konten yang mengancam, kekerasan, atau berbahaya.</li>
            <li>Anda tidak boleh mendorong kekerasan terhadap orang atau properti mana pun.</li>
          </ul>
          <h6>Penindasan / Pelecehan</h6>
          <ul>
            <li>Anda tidak boleh terlibat dalam perilaku kasar terhadap sesama pengguna;</li>
            <li>Anda tidak boleh mendorong orang lain untuk terlibat dalam perilaku kasar terhadap sesama pengguna;</li>
            <li>Hormati keinginan sesama pengguna. Jika seseorang memblokir Anda, jangan coba menghubungi mereka dari akun lain;</li>
            <li>Anda tidak boleh terlibat dalam pencemaran nama baik, fitnah, penyiksaan, atau apa pun yang dapat mengganggu privasi orang lain.</li>
          </ul>
          <h6>Menyakiti Diri Sendiri</h6>
          <ul>
            <li>Anda tidak boleh mendukung atau mendorong bunuh diri atau melukai diri sendiri;</li>
            <li>Anda tidak boleh memposting materi apa pun yang mendukung atau mendorong bunuh diri atau melukai diri sendiri.</li>
          </ul>
          <h6>Aktivitas Ilegal</h6>
          <ul>
            <li>Anda tidak boleh memposting apa pun yang melanggar hukum Adat, lokal, nasional atau internasional yang berlaku, atau peraturan apa pun yang memiliki kekuatan hukum;</li>
            <li>Anda tidak boleh memposting informasi instruksional apa pun tentang aktivitas ilegal.</li>
          </ul>
          <h6>Terorisme</h6>
          <ul>
            <li>Anda tidak boleh mendukung atau mendorong aktivitas teroris atau ekstremisme kekerasan dalam bentuk apa pun.</li>
          </ul>
          <h5>Transparansi</h5>
          <h6>Permohonan / Spam</h6>
          <ul>
            <li>Anda tidak boleh memposting apa pun yang merupakan iklan, materi promosi, aktivitas komersial dan/atau penjualan yang tidak diminta atau tidak sah, "surat sampah", "spam", "surat berantai", "skema piramida", "kontes", "undian", atau segala bentuk ajakan lainnya;</li>
            <li>Anda tidak boleh mengiklankan atau menawarkan untuk menjual atau membeli barang atau jasa apa pun untuk tujuan bisnis apa pun yang tidak diizinkan secara khusus;</li>
            <li>Anda tidak boleh mengumpulkan alamat email atau informasi kontak lainnya dari pengguna lain dari Layanan melalui elektronik atau cara lain untuk tujuan mengirim email yang tidak diminta atau komunikasi yang tidak diminta lainnya.</li>
          </ul>
          <h6>Peniruan</h6>
          <ul>
            <li>Anda tidak boleh menyamar sebagai orang atau entitas mana pun, atau secara salah menyatakan atau menggambarkan secara keliru afiliasi Anda dengan seseorang atau entitas.</li>
          </ul>
          <h6>Hak Kekayaan Intelektual</h6>
          <ul>
            <li>Anda tidak boleh melanggar hak kekayaan intelektual siapa pun, termasuk hak cipta, paten, merek dagang, rahasia dagang, atau hak dan undang-undang kepemilikan lainnya.</li>
          </ul>
          <h5>Pribadi</h5>
          <h6>Informasi Pribadi</h6>
          <ul>
            <li>Anda tidak boleh memposting informasi pribadi, termasuk namun tidak terbatas pada kartu tanda penduduk, kartu jaminan sosial, atau informasi sensitif lainnya;</li>
          </ul>
          <h5>Kebijakan Foto / Gambar</h5>
          <p>Kebijakan Foto/Gambar ini, bersama dengan Pedoman Komunitas kami, dan Ketentuan Layanan dibuat untuk memastikan Persona Talenta tetap menjadi tempat yang aman bagi anggota komunitas kami. Panduan berikut berlaku untuk semua foto/gambar yang diposting di Layanan kami:</p>
          <ul>
            <li>Tidak ada foto/gambar telanjang.</li>
            <li>Tidak ada foto/gambar yang mengandung kekerasan grafis.</li>
            <li>Tidak ada foto/gambar porno.</li>
            <li>Wajah harus terlihat jelas.</li>
            <li>Tidak ada foto/gambar yang mengandung kekerasan senjata atau berburu.</li>
            <li>Tidak ada foto/gambar yang menggambarkan citra kebencian dalam bentuk apa pun.</li>
            <li>Tidak ada foto/gambar yang menunjukkan simbol, tindakan, atau tanda/afiliasi kelompok yang cabul atau provokatif.</li>
            <li>Tidak ada foto/gambar yang berisi informasi identitas pribadi.</li>
            <li>Tidak ada f foto/gambar yang menggambarkan aktivitas ilegal.</li>
          </ul>
          <p>Kami meminta agar Pengguna memperlakukan semua orang dengan rasa hormat, kejujuran, dan kebaikan untuk mempromosikan ruang yang aman untuk berbagi cerita dan pengalaman pribadi.Kami juga mendesak Pengguna untuk berhati-hati dan membuat penilaian yang baik sebelum berkomunikasi dengan orang lain di Persona Talenta.</p>
        </div> 
      </div>
      <Footer />
    </div>
  );
}

export default CommunityGuideline;
