import Footer from 'components/footerUser';
import Header from 'components/header';
import React from 'react';
import useStyles from './style';
// import Footer from '../../components/Footer';
// import Header from '../../components/Header';

const PrivacyPolicy = () => {
  const styles = useStyles();
  
  return (
    <div className={styles.app}>
      <Header />
      <div className={styles.services}>
        <div className={styles.servicesContainer}>
          <h4>Privacy Policy</h4>
          <p>Kebijakan Privasi ini terakhir diperbarui pada 15 Maret 2023</p>
          <h5>Kebijakan Kami</h5>
          <p>Selamat datang di website (“Website”) atau aplikasi seluler (“Aplikasi”) PT Persona Talenta Digital. (“Persona Talenta”, “kami”, “kita” dan/atau “milik kami”). Kebijakan Privasi ini dibuat untuk memberikan informasi tentang perusahaan dan layanan kami, serta layanan terkait lainnya (bersama dengan Aplikasi dan Website, "Layanan") kepada pengguna dan pengunjung kami ("Pengguna", "Anda"). Kebijakan Privasi ini menjelaskan praktik Persona Talenta sehubungan dengan informasi yang terkait atau dapat secara wajar dikaitkan dengan individu (“Data Pribadi”) dan informasi lain yang kami kumpulkan dari Pengguna. Kebijakan Privasi ini dimasukkan kedalam dan merupakan bagian dari Ketentuan Layanan kami yang tersedia melalui Aplikasi dan di personatalenta.id. Harap dibaca Kebijakan Privasi ini dengan cermat. Jika Anda Tidak Setuju Dengan Ketentuan Kebijakan Privasi Ini, Anda Dilarang Akses Dan Jangan Gunakan Aplikasi Ini.</p>
          <h5>Informasi yang Kami Kumpulkan</h5>
          <p>Saat Anda berinteraksi dengan kami melalui Layanan, kami dapat mengumpulkan Data Pribadi dan informasi dari Anda, sebagaimana dijelaskan lebih lanjut:</p>
          <p><strong>Informasi Profil: </strong>Kami mengumpulkan informasi yang diberikan dari Anda, termasuk: (1) Nama atau alias Anda; (2) Tanggal lahir; (3) Waktu kelahiran Anda; (4) Lokasi kelahiran Anda; (5) Gender Anda; (6) Nama pengguna; (7) Lokasi Anda berada; dan (8) Foto atau Gambar untuk membuat profil Anda. Anda dapat mengatur profil Anda ke private atau public, pengaturan profil ini mengelola interaksi Anda dengan pengguna lain. Pada saat Anda mengatur profil Anda ke private, pengguna lain yang belum Anda tambahkan sebagai teman, tidak dapat melihat informasi berkaitan atau tentang Anda, namun semua Pengguna dapat melakukan pencarian Anda di Layanan, dan dapat meminta pertemanan dengan Anda. Pengguna lain yang telah menjadi teman Anda, dapat menggunakan keseluruhan fungsi Layanan untuk Anda. Pengaturan public Anda, maka Pengguna lain dapat menjadi teman Anda secara langsung dengan Anda dan dapat menggunakan keseluruhan fungsi Layanan untuk Anda.</p>
          <p><strong>Data Pribadi yang Anda berikan Melalui Layanan: </strong>Kami mengumpulkan Data Pribadi dari Anda, ketika Anda secara sukarela memberikan informasi tersebut kepada kami, seperti ketika Anda mendaftar untuk akses ke Aplikasi atau menggunakan Layanan ini. Informasi ini dapat mencakup: (1) alamat email; (2) nomor telepon; (3) ID Apple; (4) ID Google; dan/atau (5) foto jika dibagikan dengan Pengguna lain di Layanan, menghubungi kami dengan pertanyaan, menanggapi survei kami. Layanan kami juga memungkinkan Anda untuk memperbarui status Anda, mengomentari status Pengguna lain, mendapatkan informasi persona pengguna lain, melakukan pencocokan, berkomunikasi dengan Pengguna lain. Saat Anda mengambil tindakan tersebut, Anda dapat memberikan Data Pribadi kepada kami. Harap diperhatikan, untuk mengunggah foto ke Layanan, Anda dapat mengizinkan akses ke kamera dan album foto Anda.</p>
          <p><strong>Kontak: </strong>Anda dapat memilih untuk membagikan kontak Anda yang tersimpan di ponsel Anda untuk menggunakan fungsi tertentu dari Aplikasi. Kami hanya akan menggunakan informasi kontak untuk membantu Anda menemukan teman atau kontak yang menggunakan Aplikasi Persona Talenta jika Anda memilih untuk melakukannya.</p>
          <p><strong>Dengan secara sukarela memberikan Data Pribadi kepada kami, Anda menyetujui penggunaannya oleh kami sesuai dengan Kebijakan Privasi ini.</strong></p>
          <h5>Informasi lainnya</h5>
          <p><strong>Data yang Dikumpulkan Secara Otomatis Secara Pasif: </strong>Saat Anda berinteraksi dengan Persona Talenta melalui Website atau aplikasi, kami dan pihak ketiga yang menyediakan fungsionalitas di Website atau aplikasi dapat melibatkan, menerima, mengumpulkan, dan menyimpan jenis informasi tertentu melalui alat pengumpulan data otomatis termasuk cookie dan teknologi serupa, dan token autentikasi terenkripsi dan teknologi serupa. Saat Anda berinteraksi dengan kami melalui Aplikasi, kami juga mengumpulkan informasi secara otomatis menggunakan teknologi yang berbeda. Informasi tersebut, yang dikumpulkan secara pasif menggunakan berbagai teknologi, dapat mencakup namun tidak terbatas pada informasi tentang perangkat Anda dan halaman rujukan/keluar. </p>
          <p>Persona Talenta dapat menyimpan informasi tersebut sendiri atau informasi tersebut dapat dimasukkan dalam database yang dimiliki dan dikelola oleh afiliasi Persona Talenta, agen atau penyedia layanan. Website dapat menggunakan informasi tersebut dan menggabungkannya dengan informasi lain untuk melacak, misalnya, jumlah total pengunjung Website kami, jumlah pengunjung ke setiap halaman Website kami, dan nama domain penyedia layanan Internet pengunjung kami. Informasi yang kami kumpulkan akan memungkinkan Persona Talenta untuk membuat keputusan yang terdidik tentang cara meningkatkan Website dan memberikan pengalaman Website produk yang lebih baik bagi pengguna kami.</p>
          <p><strong>Cookie dan Teknologi Serupa: </strong>Dalam mengoperasikan Website, kami dapat menggunakan teknologi yang disebut "cookies". Cookie adalah informasi yang diberikan oleh perangkat yang menghosting Website kami ke browser Anda saat Anda mengakses Website. Cookie kami membantu menyediakan fungsionalitas tambahan ke Website dan membantu kami menganalisis penggunaan Website dengan lebih akurat. Misalnya, Website kami dapat mengatur cookie pada browser Anda yang memungkinkan Anda untuk mengakses Website tanpa perlu mengingat dan kemudian memasukkan kata sandi lebih dari sekali selama kunjungan ke Website. Cookie tidak digunakan dalam Aplikasi seluler.</p>
          <p><strong>Pelacakan Online: </strong>Kami dapat mengizinkan penyedia layanan pihak ketiga untuk menggunakan cookie atau teknologi serupa untuk mengumpulkan informasi tentang aktivitas penjelajahan Anda dari waktu ke waktu dan di berbagai Website web setelah Anda menggunakan Website. Informasi yang dikumpulkan melalui cookie ini atau teknologi serupa tunduk pada kebijakan privasi pihak ketiga tersebut di mana Persona Talenta tidak memiliki kendali.</p>
          <p><strong>Jangan Lacak: </strong>Website kami saat ini tidak menanggapi sinyal “Jangan Lacak” (“DNT”) dan beroperasi seperti yang dijelaskan dalam Kebijakan Privasi ini terlepas dari apakah sinyal DNT diterima atau tidak. Jika kami menanggapi sinyal DNT di masa mendatang, kami akan memperbarui Kebijakan Privasi ini untuk menjelaskan bagaimana kami melakukannya.</p>
          <p><strong>Data Agregat: </strong>Dalam upaya berkelanjutan untuk lebih memahami dan melayani Pengguna Layanan, Persona Talenta dapat melakukan penelitian tentang demografi, minat, lokasi, dan perilaku pelanggan Pengguna kami dengan Layanan berdasarkan Data Pribadi dan informasi lain yang diberikan kepada kami. Penelitian ini dapat dikompilasi dan dianalisis secara agregat, dan Persona Talenta dapat membagikan data agregat ini dengan afiliasi, agen, dan mitra bisnis kami untuk tujuan apa pun. Informasi agregat ini tidak mengidentifikasi Anda secara pribadi. Persona Talenta juga dapat mengungkapkan statistik pengguna agregat untuk menjelaskan layanan kami kepada mitra bisnis saat ini dan calon mitra, dan kepada pihak ketiga lainnya untuk tujuan lain yang sah menurut hukum.</p>
          <p><strong>Informasi Lokasi: </strong>Layanan kami dapat mengumpulkan dan menggunakan informasi lokasi Anda (misalnya, dengan menggunakan GPS pada perangkat seluler Anda), hanya yang terkait dengan zona waktu atau lokasi umum Anda, untuk menyediakan fungsionalitas tertentu dari Layanan kami (misalnya, push pemberitahuan pada waktu lokal tertentu). Informasi lokasi Anda hanya akan digunakan untuk tujuan internal. Kami juga dapat menggunakan informasi lokasi Anda secara agregat, seperti yang dijelaskan di atas.</p>
          <p><strong>Pemberitahuan Push: </strong>Kami dapat meminta untuk mengirimi Anda pemberitahuan push mengenai akun atau Aplikasi Anda. Jika Anda ingin berhenti menerima komunikasi ini, Anda dapat menonaktifkannya di pengaturan perangkat Anda.</p>
          <p><strong>Analitik: </strong>Kami dapat mengizinkan penyedia layanan pihak ketiga untuk menggunakan cookie atau teknologi serupa untuk mengumpulkan informasi tentang aktivitas penjelajahan Anda dari waktu ke waktu dan di berbagai Website setelah Anda menggunakan Website. Misalnya, Google Analytics, layanan analisis web yang disediakan oleh Google, Inc. (“Google”) menggunakan cookie untuk menganalisis bagaimana pengguna menggunakan Website dan meningkatkan pengalaman Anda saat menggunakan Layanan. Untuk informasi selengkapnya tentang cara Google menggunakan data ini, buka <a href="www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/</a>.</p>
          <p><strong>Informasi Perangkat:</strong>Kami dapat mengumpulkan informasi tentang perangkat Anda saat Anda menggunakan Layanan. Informasi tersebut dapat mencakup jenis perangkat, keluarga perangkat, sistem operasi, operator, id perangkat unik, zona waktu, jenis browser, versi, dan bahasa.</p>
          <p><strong>Informasi Penggunaan: </strong>Kami dapat mengumpulkan informasi mengenai aktivitas Anda saat menggunakan Layanan. Informasi tersebut dapat mencakup hari dan waktu login, fitur yang dikunjungi atau digunakan, garis lintang, garis bujur, dan pencarian dalam Layanan.</p>
          <h5>Verifikasi</h5>
          <p>Untuk melindungi Data Pribadi Anda dari akses atau penghapusan yang tidak sah, kami mungkin meminta Anda untuk memverifikasi kredensial login Anda sebelum Anda dapat mengajukan permintaan untuk mengetahui atau menghapus Data Pribadi. Jika Anda tidak memiliki akun dengan kami, atau jika kami mencurigai adanya aktivitas penipuan atau jahat, kami dapat meminta Anda untuk memberikan Data Pribadi tambahan untuk verifikasi. Jika kami tidak dapat memverifikasi identitas Anda, kami tidak akan memberikan atau menghapus Data Pribadi Anda. Agen Resmi: Anda dapat mengajukan permintaan untuk mengetahui atau permintaan untuk menghapus Data Pribadi Anda melalui agen resmi. Jika Anda melakukannya, agen harus menunjukkan izin tertulis yang ditandatangani untuk bertindak atas nama Anda dan Anda mungkin juga diminta untuk memverifikasi identitas Anda secara independen dengan kami.</p>
          <h5>Penggunaan Data Pribadi Anda dan Informasi Lainnya oleh Kami</h5>
          <p>Persona Talenta menggunakan Data Pribadi yang Anda berikan dengan cara yang konsisten dengan Kebijakan Privasi ini. Jika Anda memberikan Data Pribadi untuk alasan tertentu, kami dapat menggunakan Data Pribadi sehubungan dengan alasan pemberiannya. Misalnya, jika Anda menghubungi kami melalui email, kami akan menggunakan Data Pribadi yang Anda berikan untuk menjawab pertanyaan Anda atau mencoba menyelesaikan masalah Anda. Juga, jika Anda memberikan Data Pribadi untuk mendapatkan akses ke Aplikasi, kami akan menggunakan Data Pribadi Anda untuk memberi Anda akses ke Aplikasi dan untuk menganalisis penggunaan Aplikasi oleh Anda.</p>
          <h5>Pengungkapan Kami atas Data Pribadi Anda dan Informasi Lainnya</h5>
          <p>Persona Talenta tidak akan menjalankan bisnis penjualan Data Pribadi Anda. Kami menganggap data ini sebagai bagian penting dari hubungan kami dengan Anda. Namun, ada keadaan tertentu di mana kami dapat membagikan Data Pribadi Anda dalam keadaan berikut tanpa pemberitahuan lebih lanjut kepada Anda:</p>
          <p><strong>Pengalihan Bisnis: </strong>Jika kami melakukan atau ikut serta dalam merger, akuisisi, uji tuntas pembiayaan, reorganisasi, kebangkrutan, kurator, penjualan semua atau sebagian aset kami, atau transisi layanan ke penyedia lain, Data Pribadi Anda dan informasi lainnya dapat dibagikan dalam proses uji tuntas dengan rekanan dan pihak lain yang membantu transaksi dan dialihkan ke penerus atau afiliasi sebagai bagian dari transaksi tersebut bersama dengan aset lainnya. Saat kami mengembangkan bisnis, mungkin menjual atau membeli bisnis atau aset. Dalam hal penjualan perusahaan, merger, reorganisasi, pembubaran atau peristiwa serupa, Data Pribadi dapat menjadi bagian dari aset yang dialihkan.</p>
          <p><strong>Agen, Konsultan, dan Pihak Ketiga Terkait: </strong>Persona Talenta, seperti banyak bisnis lainnya, terkadang mempekerjakan perusahaan lain untuk menjalankan fungsi terkait bisnis tertentu dan untuk membantu kami memahami dan meningkatkan penggunaan Layanan kami. Contoh fungsi tersebut termasuk hosting web, memelihara basis data, dan memproses pembayaran. Kami dapat membagikan informasi apa pun yang kami terima dengan vendor dan penyedia layanan yang disimpan sehubungan dengan penyediaan Layanan.</p>
          <p><strong>Persyaratan Hukum: </strong>Persona Talenta dapat mengungkapkan Data Pribadi Anda jika diharuskan oleh hukum atau dengan itikad baik bahwa tindakan tersebut diperlukan untuk (i) mematuhi kewajiban hukum, (ii) melindungi dan membela hak atau properti Persona Talenta, (iii) bertindak dalam keadaan mendesak untuk melindungi keselamatan pribadi pengguna Layanan atau publik, atau (iv) melindungi dari tanggung jawab hukum.</p>
          <p><strong>Bacaan dan Pengguna Lain: </strong>Nama dan gambar profil Anda (jika Anda memilih untuk memberikannya) akan terlihat oleh semua Pengguna. Persona Talenta dapat menggunakan Data Pribadi Anda untuk secara umum, berkala menghasilkan analisa pribadi, kepribadian, profiling dan lainnya tentang Anda berdasarkan metoda bazi atau delapan aksara atau metoda lain yang digunakan kami (“Bacaan Pribadi”) dan juga, ketika diminta oleh Anda atau Pengguna lain, tentang kecocokan Anda dengan Pengguna lain (“Bacaan Kompatibilitas”). </p>
          <p>Bacaan Pribadi dan Bacaan Kompatibilitas Anda akan menyertakan nama, foto profil, keterangan atau deskripsi  Anda, tetapi tidak akan mengungkapkan tanggal lahir dan jam lahir Anda, kecuali Anda mengungkapkan sendiri didalam profile Anda, tetapi pengguna dapat secara tidak langsung mengungkapkan informasi Pribadi lainnya yang terkait dengan Data Pribadi Anda kepada Pengguna lain. Bacaan Pribadi Anda dan setiap pembaruan status, foto, komentar dan lainnya yang Anda bagikan melalui Layanan (“Konten Bersama”), dapat muncul di Pengguna yang Anda berteman melalui Layanan.</p>
          <p>Teman Anda dapat menggunakan Layanan dan Layanan Media Sosial lain untuk berbagi Konten Bersama Anda dengan Pengguna lain dan pihak ketiga. Teman Anda juga dapat melihat Bacaan Pribadi Anda dengan melihat profil Anda, setiap Pengguna dapat juga membuat Bacaan Kompatibilitas dengan Anda dan membagikan Bacaan Pribadi dan Bacaan Kompatibilitas tersebut melalui Layanan atau Layanan Media Sosial lain. Layanan memungkinkan untuk menyarankan agar Anda terhubung dengan Pengguna lain yang menerima Bacaan Pribadi yang serupa dengan Anda. Layanan dapat mencoba memfasilitasi koneksi ini dengan membagikan nama pengguna, foto profil, dan ringkasan singkat Pembacaan Pribadi Anda dengan Pengguna tersebut. </p>
          <p><strong>Mengakses Aplikasi Melalui Apple atau Google: </strong>Jika Anda menggunakan Apple Keychain untuk masuk ke Aplikasi secara otomatis, informasi login pengguna Anda dibagikan dengan Apple, Inc. Untuk informasi selengkapnya tentang cara Apple, Inc. menggunakan data ini, kunjungi <a href="https://www.apple.com/privacy/">https://www.apple.com/privacy/</a>. Jika Anda mengakses Aplikasi melalui Google Play store, Google LLC akan mengumpulkan data terkait penggunaan Anda atas Aplikasi seperti yang dijelaskan secara lebih rinci di <a href="https://play.google.com/about/developer-distribution-agreement.html">https://play.google.com/about/developer-distribution-agreement.html</a>. Informasi tersebut akan tunduk pada kebijakan privasi Google LLC yang tersedia di <a href="https://www.google.com/intl/en_us/policies/privacy/?fg=1">https://www.google.com/intl/en_us/policies/privacy/?fg=1</a>.</p>
          <h5>Pilihan Anda</h5>
          <p>Anda dapat memilih untuk mengatur profil Anda ke "private" yang akan mencegah Pengguna melihat Bacaan Pribadi Anda, tetapi harap perhatikan bahwa mengatur profil Anda ke private mungkin tidak mencegah Pengguna membuat Bacaan Kompatibilitas dengan Anda. Juga, harap perhatikan bahwa menyetel profil Anda ke private mungkin tidak mencegah Pengguna lain melihat header dan indikasi wawasan pribadi Anda jika mereka berbagi persona yang sama dengan Anda.</p>
          <h5>Pengecualian</h5>
          <p>Kebijakan Privasi ini tidak berlaku untuk Data Pribadi apa pun yang dikumpulkan oleh Persona Talenta selain Data Pribadi yang dikumpulkan melalui Layanan. Kebijakan Privasi ini tidak berlaku untuk informasi yang tidak diminta yang Anda berikan kepada Persona Talenta melalui Layanan atau melalui cara lain apa pun. Ini termasuk, namun tidak terbatas pada, informasi yang diposting ke area publik Layanan, seperti socio persona, ide untuk produk baru atau modifikasi produk yang sudah ada, dan pengiriman yang tidak diminta lainnya (secara kolektif disebut "Informasi yang Tidak Diminta"). Semua Informasi yang Tidak Diminta akan dianggap tidak rahasia dan Persona Talenta bebas untuk mereproduksi, menggunakan, mengungkapkan, dan mendistribusikan Informasi yang Tidak Diminta tersebut kepada orang lain tanpa batasan atau atribusi.</p>
          <h5>Anak-anak</h5>
          <p>Persona Talenta tidak dengan sengaja mengumpulkan Data Pribadi dari anak-anak di bawah usia 16 tahun. Jika Anda berusia di bawah 16 tahun, mohon untuk tidak mengirimkan Data Pribadi apa pun melalui Layanan, karena Anda tidak akan dapat menggunakan Layanan. Kami mendorong orang tua dan wali hukum untuk memantau penggunaan Internet dan aplikasi seluler anak-anak Anda, dan untuk membantu menegakkan Kebijakan Privasi kami dengan menginstruksikan anak-anak Anda untuk tidak pernah memberikan Data Pribadi pada Layanan tanpa izin Anda. </p>
          <p>Jika Anda memiliki alasan untuk meyakini bahwa seorang anak di bawah usia 16 tahun telah memberikan Data Pribadi kepada Persona Talenta melalui Layanan, silakan hubungi kami, dan kami akan berusaha untuk menghapus informasi tersebut dari basis data kami. Namun bagi Anda sebagai pengguna, dapat menambahkan profil orang lain pada fitur Colleague di Layanan tanpa batasan usia, dan data yang Anda tambahkan tersebut menjadi tanggung jawab Anda sepenuhnya, Persona Talenta tidak bertanggung jawab terhadap kerugian, kerusakan, kesalahan, penyalah gunaan dan akibatnya dari data yang Anda tambahkan tersebut, dan sepenuhnya menjadi tanggung jawab Anda secara pribadi.</p>
          <h5>Tautan ke Website Pihak Ketiga atau Aplikasi Seluler Lainnya</h5>
          <p>Kebijakan Privasi ini hanya berlaku untuk Layanan ini. Layanan dapat berisi tautan ke Website Pihak Ketiga atau aplikasi seluler lainnya yang tidak dioperasikan atau dikendalikan oleh Persona Talenta (“Aplikasi Pihak Ketiga”). Kebijakan Privasi ini tidak berlaku untuk Website tersebut atau Aplikasi Pihak Ketiga dan dengan menyediakan tautan tersebut, kami tidak menyiratkan bahwa Persona Talenta mendukung atau telah meninjau Website atau Aplikasi Pihak Ketiga. Kami tidak bertanggung jawab atas praktik privasi dari Website Pihak Ketiga atau Aplikasi Seluler mana pun. Kami menyarankan untuk menghubungi Website atau Aplikasi tersebut secara langsung untuk mendapatkan informasi tentang praktik privasi mereka.</p>
          <h5>Mengintegrasikan Layanan Media Sosial</h5>
          <p>Melalui Layanan ini, Anda dapat memilih untuk mengakses layanan media sosial tertentu (“Layanan Media Sosial”). Saat Anda memilih untuk mengakses dan menggunakan Layanan Media Sosial melalui Aplikasi Pihak Ketiga tersebut, Anda akan membagikan informasi Anda (yang akan mencakup Data Pribadi jika Anda atau pengguna lain memilih untuk membagikan informasi tersebut) dengan Layanan Media Sosial tersebut. Seperti Aplikasi Pihak Ketiga lainnya, informasi yang Anda atau orang lain bagikan dengan Layanan Media Sosial akan diatur oleh kebijakan privasi dan persyaratan layanan dari penyedia Layanan Media Sosial tersebut dan bukan oleh Kebijakan Privasi atau Ketentuan Layanan kami. </p>
          <p>Anda juga dapat mengubah pengaturan privasi Anda dengan Layanan Media Sosial, misalnya, mengontrol informasi apa yang diungkapkan oleh Layanan Media Sosial kepada entitas lain, termasuk Persona Talenta. Kami tidak bertanggung jawab atas praktik privasi Layanan Media Sosial apa pun. Persona Talenta tidak memiliki kewajiban atau tanggung jawab atas praktik privasi atau tindakan lain dari Layanan Media Sosial apa pun.</p>
          <p>Anda juga dapat memiliki opsi untuk memposting aktivitas Layanan Anda ke Layanan Media Sosial saat Anda mengakses konten melalui Layanan; Anda mengakui bahwa jika Anda memilih untuk menggunakan fitur ini, teman, pengikut, dan pelanggan Anda di Layanan Media Sosial apa pun yang telah Anda aktifkan akan dapat melihat, berkomentar, dan bereaksi terhadap aktivitas tersebut.</p>
          <h5>Keamanan</h5>
          <p>Persona Talenta menerapkan langkah-langkah teknis, terorganisasi dan mengambil langkah-langkah yang wajar untuk melindungi Data Pribadi Anda yang diberikan melalui Layanan dari kehilangan, penyalahgunaan, dan akses, pengungkapan, perubahan, atau penghancuran yang tidak sah. Namun, baik Internet maupun transmisi email apa pun tidak pernah sepenuhnya aman atau bebas dari kesalahan. Secara khusus, email yang dikirim ke atau dari Layanan mungkin tidak aman. Oleh karena itu, Anda harus berhati-hati dalam memutuskan informasi apa yang Anda kirimkan kepada kami melalui email. Persona Talenta tidak dapat dan tidak menjamin bahwa Data Pribadi apa pun yang diberikan kepada Persona Talenta tidak akan dipublikasikan dalam keadaan apa pun. Harap ingat hal ini saat mengungkapkan Data Pribadi apa pun kepada Persona Talenta melalui Internet.</p>
          <h5>Syarat dan Ketentuan lainnya</h5>
          <p>Akses Anda ke dan penggunaan Layanan tunduk pada Ketentuan Layanan di personatalenta.id dan Aplikasi Persona Talenta di App Store dan Google Play.</p>
          <p><strong>Perubahan Kebijakan Privasi Persona Talenta: </strong>Layanan dan bisnis kami dapat berubah dari waktu ke waktu; akibatnya, Persona Talenta mungkin perlu melakukan perubahan pada Kebijakan Privasi ini. Persona Talenta berhak untuk memperbarui atau memodifikasi Kebijakan Privasi ini setiap saat dan dari waktu ke waktu tanpa pemberitahuan sebelumnya. Harap tinjau kebijakan ini secara berkala, dan terutama sebelum Anda memberikan Data Pribadi apa pun. Kebijakan Privasi ini terakhir diperbarui pada tanggal yang disebutkan di atas. Penggunaan Layanan yang berkelanjutan setelah perubahan atau revisi apa pun pada Kebijakan Privasi ini akan menunjukkan persetujuan Anda dengan ketentuan Kebijakan Privasi yang direvisi tersebut.</p>
          <p><strong>Pengguna Internasional: </strong>Jika Anda menggunakan Layanan Persona Talenta dari luar Indonesia, Anda secara tegas mengakui dan menyetujui bahwa Data Pribadi Anda dan informasi lainnya akan diproses di Indonesia dan di negara lain. Undang-undang privasi dan keamanan data mungkin kurang ketat atau berbeda dengan undang-undang yang berlaku di negara tempat tinggal Anda. Anda secara tegas menyetujui pemrosesan Data Pribadi Anda dan informasi lainnya sesuai dengan Kebijakan ini.</p>
          <h5>Akses ke informasi; Menghubungi Persona Talenta:</h5>
          <p>Untuk menjaga Data Pribadi Anda tetap akurat, terkini, dan lengkap, harap perbarui profil Anda atau hubungi kami sebagaimana ditentukan di bawah ini. Kami akan mengambil langkah-langkah yang wajar untuk memperbarui atau memperbaiki Data Pribadi milik kami yang sebelumnya telah Anda kirimkan melalui Layanan. Jangan ragu untuk menghubungi kami di alamat permintaan yang kami tentukan contact@persontalenta.id jika Anda memiliki pertanyaan tentang Kebijakan Privasi Persona Talenta, praktik informasi kami, atau jika Anda perlu mengakses Kebijakan Privasi ini dalam format yang berbeda.</p>
        </div> 
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
