import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useStyles from './style';
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useWindowDimensions from "hooks/useWindowDimensions";

const BookListItem = data => {
  const styles = useStyles();
  const [activeMenu, setActiveMenu] = useState(data.data[data.active ?? 0])
  const [mobileOpen, setMobileOpen] = useState(false);
  const { width } = useWindowDimensions();
  const theme = useTheme();

  useEffect(() => {
    setActiveMenu(data.data[data.active ?? 0])
  }, [data])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const container = window !== undefined ? () => window.document.body : undefined;
  const drawer = (
    <div>
      <List>
        {data.data.map((text, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{paddingLeft: 0}} onClick={() => setActiveMenu(text)}>
              <ListItemText><strong>{text.menu}</strong></ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>      
    </div>
  );  

  return (
    <>
      {/* {width < theme.breakpoints.values.md && (
        <div>
          <div className={styles.menu} onClick={() => setMobileOpen(!mobileOpen)}>
            <p>{activeMenu.menu}</p>
            <KeyboardArrowDownIcon />
          </div>
        </div>
      )} */}
      <div className={styles.bottomContainer}>
        <Box
          component="nav"
          sx={{ display: {xs: 'none', sm: 'none', md: 'block'}, width: { sm: 190 }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'block', md: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 190 },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 190, position: 'relative' },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <div className={styles.containerContentMenu}>
          {activeMenu.content}
        </div>
      </div>

    </>
  )
}

export default BookListItem;
