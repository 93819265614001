// Chakra imports
import { Box, Button, Flex, Grid, others, Text } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";

// Assets
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

export default function PersonaWeb(props) {
  const [user, setUser] = useState();
  const [localUser, setLocalUser] = useState()
  const [activeCard, setActiveCard] = useState(0);
  const [open, setOpen] = useState('');
  const [cards, setCards] = useState([]);
  const [isUnlocked, setIsUnlocked] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    let locUser = localStorage.getItem('user');
    setLocalUser(JSON.parse(locUser))
    if (props?.match?.params?.id && props?.match?.params?.type) {
      axios.get(`https://api.personatalenta.id/user/${props.match.params.type}/${props.match.params.id}`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode",  'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' }})
      .then(res => {
        setUser(res.data.user ?? res.data.colleagues)
      }).catch(err => {
        if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
          // navigate('/');
        }
      })
    } else {
      if(locUser) {
        setUser(JSON.parse(locUser))
      } else {
        window.location.assign('/home')
      }
    }
  }, []);

  useEffect(() => {
    window.onscroll = function() {myFunction()};

    var navbar = document.getElementById("sticky");
    // var type = document.getElementById("type");
    var sticky = navbar.offsetTop - 150;

    function myFunction() {
    if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky")
        // type.classList.add("sticky")
    } else {
        navbar.classList.remove("sticky");
        // type.classList.remove("sticky");
    }
    }
  }, [window])

  useEffect(() => {
    const token = localStorage.getItem('token');
    let type = 'profile';
    if (activeCard === 0) {
      type = 'profile';
    } else if (activeCard === 1) {
      type = 'this_year'
    } else if (activeCard === 2) {
      type = 'today'
    }
    if (token) {
      axios.get(`https://api.personatalenta.id/persona/${type}?user_id=${user?.id}&buyer_id=${localUser?.id}${activeCard === 2 ? `&date=${moment().format('YYYY-MM-DD')}` : ''}&is_has_user_id=${props?.match?.params?.type === 'friend' || props.match.params.username ? 'true' : 'false'}`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode",  'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' }})
      .then(res => {
        setCards(res?.data?.cards)
        setIsUnlocked(res?.data?.is_unlocked)
      }).catch(err => {
        if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
          // navigate('/');
        }
      })
    }

  }, [user, localUser, activeCard])

  const onClickCard = index => {
    setActiveCard(index)
  }

  const onClickOpen = text => {
    if (isUnlocked) {
      if (text === open) {
        setOpen('')
      } else {
        setOpen(text)
      }
    } else {
      if (props?.match?.params?.id) {
        if (activeCard === 0 || activeCard === 1) {
          window.location.assign(`/dashboard/friend-list/${props.match.params.type}/${props?.match?.params?.id}/product/persona`)
        } else {
          window.location.assign(`/dashboard/friend-list/${props.match.params.type}/${props?.match?.params?.id}/product/daily-vibe`)
        }
      } else {
        if (activeCard === 0 || activeCard === 1) {
          window.location.assign(`/dashboard/profile/${user?.username}/product/persona`)
        } else {
          window.location.assign(`/dashboard/profile/${user?.username}/product/daily-vibe`)
        }
      }
    }
  }

  return (
    <Box pt={{ sm: 0, base: "130px", md: "80px", xl: "80px" }} mt={10}>
      {/* Main Fields */}
      <Grid
        // templateColumns={{
        //   base: "1fr",
        //   lg: "1.34fr 1fr 1.62fr",
        // }}
        // templateRows={{
        //   base: "repeat(3, 1fr)",
        //   lg: "1fr",
        // }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          avatar={user?.photo}
          name={user?.name}
          job={user?.occupation}
          dob={user?.birth_date}
          edit={!props?.match?.params?.id}
        />
        <Flex direction="row" gap={5} id="sticky" flexWrap="wrap">
          <Button
            bgColor={activeCard === 0 ? "blue.500" : "gray.100"}
            color={activeCard === 0 ? "white" : "black"}
            borderColor="blue.500"
            borderWidth={1}
            fontSize={{sm: 'xs', md: 'sm'}}
            fontWeight='bold'
            borderRadius='5px'
            onClick={() => onClickCard(0)}
            px={{sm: '10px', md: '30px'}}
            py='5px'>
            Profile
          </Button>
          <Button
            bgColor={activeCard === 1 ? "blue.500" : "gray.100"}
            color={activeCard === 1 ? "white" : "black"}
            borderColor="blue.500"
            borderWidth={1}
            fontSize={{sm: 'xs', md: 'sm'}}
            fontWeight='bold'
            borderRadius='5px'
            onClick={() => onClickCard(1)}
            px={{sm: '10px', md: '30px'}}
            py='5px'>
            Annually
          </Button>
          <Button
            bgColor={activeCard === 2 ? "blue.500" : "gray.100"}
            color={activeCard === 2 ? "white" : "black"}
            borderColor="blue.500"
            borderWidth={1}
            fontSize={{sm: 'xs', md: 'sm'}}
            fontWeight='bold'
            borderRadius='5px'
            onClick={() => onClickCard(2)}
            px={{sm: '10px', md: '30px'}}
            py='5px'>
            Daily Vibe
          </Button>
        </Flex>
        {cards.length > 0 && (
          <Flex direction="column" gap={3} width={{md: '100%', lg: "75%"}}>
            {cards.map((card, index) => (
                <Flex direction="column" key={index} gap={1}>
                  <Text fontWeight="bold" fontSize={{sm: "sm", md: "md"}}>{card.title}</Text>
                  {card.texts.map((text) => (
                    <>
                      {
                        text.map((item, i) => {
                          return (
                            <Flex key={i} direction="column" gap={1} borderWidth={1} borderRadius={15} padding={2} borderColor="#3572EF">
                              <Text fontWeight="bold" fontSize={{sm: "xs", md: "md"}}>{item.title != 'null' ? item.title : ""}</Text>
                              <Text textOverflow="ellipsis" overflow="hidden" noOfLines={item.text === open ? 0 : 3} fontSize={{sm: "xs", md: "md"}}>{item.text}</Text>
                              <Text color="#050C9C" fontSize={{sm: "xs", md: "md"}} fontStyle="italic" textAlign="end" mt={0} cursor="pointer" onClick={() => onClickOpen(item.text)}>{isUnlocked && item.text !== open ? "Read more" : isUnlocked && item.text === open ? "Show less" : "Deep dive"}</Text>
                            </Flex>
                          )
                        })
                      }
                    </>
                  ))}
                </Flex>
              )
            )}
          </Flex>
        )}
      </Grid>
    </Box>
  );
}
