import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 50
  },
  containerListItem: {
    marginTop: 80,
    maxWidth: "1080px",
    width: "100%",
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 30
  },
  menu: {
    backgroundColor: "navy",
    padding: "5px 10px",
    textAlign: "center",
    color: "white",
    width: "200px"
  },
  menuLeft: {
    backgroundColor: "#DCE3F2",
    padding: "5px 10px",
    textAlign: "start",
    color: "black",
    width: "200px"
  },
  menuRight: {
    border: "1px solid lightsteelblue",
    padding: "5px 10px",
    textAlign: "end",
    color: "black",
    width: "200px"
  },
  containerHow: {
    display: "flex",
    flex: "row",
    gap: 20
  },
  explaination: {
    textAlign: "center"
  },
  yellow: {
    color: '#E24E06',
    fontWeight: 700,
    border: "1px solid #E24E06",
    borderRadius: 5,
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: '#E24E06',
      color: "#fff"
    }
  },
  containerImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  imgDashboard: {
    width: "80%",
    textAlign: "center",
    marginBottom: 10
  }
}));
