// Chakra imports
import { Box, Button, Flex, Grid, Text } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";

// Assets
import React, { useEffect, useState } from "react";
import axios from "axios";

export default function ProfilingStandardWeb(props) {
  const [user, setUser] = useState();
  const [activeCard, setActiveCard] = useState();
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (props?.match?.params?.id && props?.match?.params?.type) {
      axios.get(`https://api.personatalenta.id/user/${props.match.params.type}/${props.match.params.id}`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode",  'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' }})
      .then(res => {
        setUser(res.data.user ?? res.data.colleagues)
      }).catch(err => {
        if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
          // navigate('/');
        }
      })
    } else {
      let localUser = localStorage.getItem('user');
      if(localUser) {
        setUser(JSON.parse(localUser))
      } else {
        window.location.assign('/home')
      }
    }
  }, []);
  
  // useEffect(() => {
  //   window.onscroll = function() {myFunction()};

  //   var navbar = document.getElementById("sticky");
  //   // var type = document.getElementById("type");
  //   var sticky = navbar.offsetTop - 110;

  //   function myFunction() {
  //   if (window.pageYOffset >= sticky) {
  //       navbar.classList.add("sticky")
  //       // type.classList.add("sticky")
  //   } else {
  //       navbar.classList.remove("sticky");
  //       // type.classList.remove("sticky");
  //   }
  //   }
  // }, [window])

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
        axios.get(`https://api.personatalenta.id/profiling/pdf/link/webview?id=${user?.id}&type=${props.match.params.type === 'friend' ? 0 : 1}&version=standard`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode",  'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' }})
        .then(res => {
            setCards(res?.data?.chapters)
        }).catch(err => {
          if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
            // navigate('/');
          }
        })
      }

  }, [user, activeCard])

  const onClickCard = text => {
    if (text === activeCard) {
      setActiveCard('')
    } else {
      setActiveCard(text)
    }
  }

  return (
    <Box pt={{ sm: 0, base: "130px", md: "80px", xl: "80px" }} mt={10}>
      {/* Main Fields */}
      <Grid
        // templateColumns={{
        //   base: "1fr",
        //   lg: "1.34fr 1fr 1.62fr",
        // }}
        // templateRows={{
        //   base: "repeat(3, 1fr)",
        //   lg: "1fr",
        // }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          avatar={user?.photo}
          name={user?.name}
          job={user?.occupation}
          dob={user?.birth_date}
          edit={!props?.match?.params?.id}
        />
        {/* <Flex direction="row" gap={5} id="sticky">
          <Button
            bgColor={activeCard === 0 ? "blue.500" : "gray.100"}
            color={activeCard === 0 ? "white" : "black"}
            borderColor="blue.500"
            borderWidth={1}
            fontSize='sm'
            fontWeight='bold'
            borderRadius='5px'
            onClick={() => onClickCard(0)}
            px='30px'
            py='5px'>
            Persona
          </Button>
          <Button
            bgColor={activeCard === 1 ? "blue.500" : "gray.100"}
            color={activeCard === 1 ? "white" : "black"}
            borderColor="blue.500"
            borderWidth={1}
            fontSize='sm'
            fontWeight='bold'
            borderRadius='5px'
            onClick={() => onClickCard(1)}
            px='30px'
            py='5px'>
            Talent
          </Button>
          <Button
            bgColor={activeCard === 2 ? "blue.500" : "gray.100"}
            color={activeCard === 2 ? "white" : "black"}
            borderColor="blue.500"
            borderWidth={1}
            fontSize='sm'
            fontWeight='bold'
            borderRadius='5px'
            onClick={() => onClickCard(2)}
            px='30px'
            py='5px'>
            Career
          </Button>
        </Flex> */}
        {cards.length > 0 && (
          <Flex direction="column" gap={3} width={{md: "100%", lg: "75%"}}>
            {cards.map((card, index) => (
                <Flex direction="column" key={index} gap={1}>
                  <Text fontWeight="bold" fontSize="lg">{card.title}</Text>
                  {card.sections.map((section, ind) => (
                    <>
                      <Text fontWeight="bold" fontSize={{sm: "sm", md: "md"}}>{section.title}</Text>
                      {section.texts.map((text, inde) => (
                        <>
                          {
                            text.map((item, i) => {
                              // if (item.title && item.text) {
                                return (
                                  <Flex key={i} direction="column" gap={1} borderWidth={1} borderRadius={15} padding={2} borderColor="#3572EF">
                                    <Text fontWeight="bold" fontSize={{sm: "xs", md: "md"}}>{item.title}</Text>
                                    <Text fontSize={{sm: "xs", md: "md"}} textOverflow="ellipsis" overflow="hidden" noOfLines={item.text === activeCard ? 0 : 3}>{item.text}</Text>
                                    <Text color="#050C9C" fontSize={{sm: "xs", md: "md"}} fontStyle="italic" textAlign="end" mt={0} cursor="pointer" onClick={() => onClickCard(item.text)}>{item.text === activeCard ? "Show less" : "Read more"}</Text>
                                  </Flex>
                                )
                              // }
                            })
                          }
                        </>
                      ))}

                    </>
                  ))}
                </Flex>
              )
            )}
          </Flex>
        )}
      </Grid>
    </Box>
  );
}
