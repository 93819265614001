/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './style';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Header from 'components/header';
import Footer from 'components/footerUser';
import { Flex } from '@chakra-ui/react';
import Gambar1 from '../../assets/img/Home1.webp';
import Gambar2 from '../../assets/img/Home2.webp';
import Gambar3 from '../../assets/img/Home3.webp';
import Gambar4 from '../../assets/img/Home4.webp';

const Home = () => {
  const styles = useStyles();

  return (
    <div className={styles.app}>
      <Header active="home" />
      <div className={styles.heroContainer}>
        <div className={styles.hero}>
          <Swiper
            slidesPerView={1}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}      
            loop
            modules={[Pagination, Autoplay]}
          >
            <SwiperSlide>
              <Flex flexDirection={{sm: 'column', lg: "row"}} gap={5}>
                <img src={Gambar1} alt="profile" className={styles.img} />
                <Flex flexDirection="column">
                  <span className="title">Apakah Kamu Sedang Mencari Kerja? Atau Merasa kurang cocok dengan KARIR KAMU SAAT INI….?</span>
                  <p className="content">Temukan jalan karir terbaikmu dengan memahami kepribadianmu ! Cocokkan passion dengan profesi ideal, dan capai kesuksesan dengan cara yang lebih cerdas.</p>
                  <p className="content">PROFILING Persona Talenta dapat membantu kamu menemukan PILIHAN KARIR TERBAIK BUAT KAMU.</p>
                </Flex>
              </Flex>
            </SwiperSlide>

            <SwiperSlide>
              <Flex flexDirection={{sm: 'column', lg: "row"}} gap={5}>
                <img src={Gambar2} alt="profile" className={styles.img} />
                <Flex flexDirection="column">
                  <span className="title">Kenapa aku nggak cocok kerja bareng Dia! Apakah WORKSTYLE ku terlalu berbeda jauh ya?</span>
                  <p className="content">Kenali karakter kerja kamu dan temanmu Kamu akan memegang kendali, lihai menghadapi berbagai gaya kerja teman-temanmu</p>
                  <p className="content">PROFILING Persona Talenta membantumu menemukan KARAKTER KERJA DAN GAYA KERJA teman-temanmu</p>
                </Flex>
              </Flex>
            </SwiperSlide>
            <SwiperSlide>
              <Flex flexDirection={{sm: 'column', lg: "row"}} gap={5}>
                <img src={Gambar3} alt="profile" className={styles.img} />
                <Flex flexDirection="column">
                  <span className="title">Hubungan CINTA, SOSIAL DAN KELUARGA kok sulit harmoni ya! Bagaiman caranya untuk dealing dan interaksi?</span>
                  <p className="content">Pernahkan kamu mengalami kesulitan untuk membangun hubungan dengan pasangan, teman bahkan keluarga mu !</p>
                  <p className="content">PROFILING Persona Talenta membantu kamu menemukan KEPRIBADIAN DALAM MEMBANGUN HUBUNGAN Dan Cara-cara Dealing dan Interaksimu.</p>
                </Flex>
              </Flex>
            </SwiperSlide>
            <SwiperSlide>
              <Flex flexDirection={{sm: 'column', lg: "row"}} gap={5}>
                <img src={Gambar4} alt="profile" className={styles.img} />
                <Flex flexDirection="column">
                  <span className="title">Pernahkah kamu bertanya, seberapa besar KECOCOKANMU DENGAN PASANGAN, SAHABAT, ATAU REKAN KERJAMU?</span>
                  <p className="content">Temukan jawabannya sekarang dan perkuat hubunganmu dengan insight yang tepat!</p>
                  <p className="content">MATCHING Persona Talenta membantu kamu berapa % persen kompatibilitas kamu dengan pasangan, sahabat, rekan yang bisa kamu pilih, tentukan sendiri !</p>
                </Flex>
              </Flex>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.bottomInfo}>
          <a href="/about-us" className="containerInfo">
            <h6 className="titleInfo">PERSONA</h6>
            <div className="aboutInfo">
              <p>Membantu Kamu : Mengenal KARAKTER – KECOCOKAN diri, pasangan dan rekan.</p>
              <p>Dengan PROFILING dan MATCHING.</p>
            </div>
          </a>
          <a href="/service" className="containerInfo">
            <h6 className="titleInfo">TANYA JAWAB</h6>
            <div className="aboutInfo">
              <p>Bagaimana MENGGUNAKAN Layanan, BERBAYAR atau GRATIS, tatacara pembelian ONE TIME PAYMENT atau BERLANGGANAN.</p>
              {/* <ul>
                <li>Bagaimana MENGGUNAKAN Layanan, BERBAYAR atau GRATIS, tatacara pembelian ONE TIME PAYMENT atau BERLANGGANAN.</li>
                <li>Profiling seperti apa yang dibuat oleh Persona ini ?</li>
                <li>Apakah layanan in merupakan layanan berbayar?</li>
              </ul> */}
            </div>
          </a>
          <a href="/signup" className="containerSignup">
            <div>
              <h6 className="titleInfo-signup">BUAT AKUN SIGN UP</h6>
            </div>
            <div className="aboutSignup">
              <p>CREATE akun PERSONA Mudah, cepat dan valuable.</p>
              <p>YUK DAFTAR !!!</p>
            </div>
          </a>
          <a href="/service" className="containerInfo">
            <h6 className="titleInfo">SERVICE</h6>
            <div className="aboutInfo">
              <p>Baca dulu yah … PROFILING : Career, Workstyle, Relation, Standard, Complete</p>
              <p>MATCHING: Romance, Friendship, Partnership</p>
            </div>
          </a>
          <a href="/pricing" className="containerInfo">
            <h6 className="titleInfo">PRICING</h6>
            <div className="aboutInfo">
              <p>Layanan apa saja yang GRATIS Dan Layanan yang BERBAYAR SEKALI atau BERLANGGANAN</p>
            </div>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
