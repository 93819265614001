import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import useStyles from './style';
import { useTheme } from '@mui/material/styles';
import logo from 'assets/img/logo.png';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Hamburger from 'hamburger-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Flex, Text } from '@chakra-ui/react';

const Header = props => {
  const { active } = props;
  const [user, setUser] = useState();
  // const navigate = useNavigate();
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme()
  const { width } = useWindowDimensions();
  const [isOpen, setOpen] = useState(false)
  const [accordionOpen, setAccordionOpen] = useState()
  const [openSub, setOpenSub] = useState(false)

  useEffect(() => {
    // console.log(theme.breakpoints.values.md)
    const token = localStorage.getItem('token');
    let currentUser = localStorage.getItem('user');
    if (token) {
      axios.get(`https://api.personatalenta.id/user/current`, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode", 'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' } })
        .then(res => {
          if (res?.data) {
            setUser(res?.data?.user)
            localStorage.setItem('user', JSON.stringify(res?.data?.user))
          }
        }).catch(err => {
          if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
            // navigate('/');
          }
        })
    }
    if (currentUser) {
      currentUser = JSON.parse(currentUser);
      setUser(currentUser)
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload()
  }


  return (
    <>
      {width < theme.breakpoints.values.md ? (
        <>
          <div className={styles.header}>
            <div className={styles.appHeader}>
              <div className="brandName">
                <img src={logo} className="App-logo" alt="logo" />
                <p className="title">PERSONA</p>
              </div>
              <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>
          </div>
          <Drawer
            anchor={"right"}
            open={isOpen}
            onClose={() => setOpen(false)}
          >
            <div className={styles.hamburgerMenu}>
              <div className="itemBurger">
                <div className={active === "home" ? "active" : ""}>
                  <a href='/'>
                    <span>HOME</span>
                  </a>
                </div>
                <div className={active === "profiling" ? "active" : ""}>
                  <a href='/profiling'>
                    <span>PROFILING</span>
                  </a>
                </div>
                {/* <div className={active === "profiling" ? "active" : ""}>
                  <div className="accordion">
                    <a href='/service'>
                      <span>PROFILING</span>
                    </a>
                    <Flex cursor="pointer" onClick={() => setAccordionOpen(accordionOpen === 'service' ? false : 'service')}>
                      {accordionOpen !== 'service' ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ExpandLessIcon />
                      )}
                    </Flex>
                  </div>
                </div> */}
                {/* {accordionOpen === 'service' && (
                <Flex direction="column" gap="10px" pl={5}>
                  <Flex direction="row" justifyContent="space-between">
                    <a href='/service#0'>
                      <Text>Description</Text>
                    </a>
                    <Flex cursor="pointer" onClick={() =>setOpenSub(!openSub)}>
                      {openSub ? (
                        <ExpandLessIcon />
                        ) : (
                        <ExpandMoreIcon />
                      )}
                    </Flex>
                  </Flex>
                  {openSub && (
                    <Flex direction="column" gap="10px" pl={5}>
                      <a href="/service-menu#0">
                        <Text>Profiling</Text>
                      </a>
                      <a href="/service-menu#1">
                        <Text>Persona</Text>
                      </a>
                      <a href="/service-menu#2">
                        <Text>Socio Persona</Text>
                      </a>
                      <a href="/service-menu#3">
                        <Text>Matching</Text>
                      </a>
                    </Flex>
                  )}
                  <a href='/service#1'>
                    <Text>Pricing Profiling</Text>
                  </a>
                  <a href='/service#2'>
                    <Text>Pricing Persona</Text>
                  </a>
                  <a href='/service#3'>
                    <Text>Pricing Matching</Text>
                  </a>
                </Flex>
              )} */}
                <div className={active === "matching" ? "active" : ""}>
                  <a href='/matching'>
                    <span>MATCHING</span>
                  </a>
                </div>

                <div className={active === "dashboard" ? "active" : ""}>
                  <div className="accordion">
                    <a href={user ? '/dashboard/persona' : '/user/dashboard'}>
                      <span>DASHBOARD</span>
                    </a>
                    {user && (
                      <Flex cursor="pointer" onClick={() => setAccordionOpen(accordionOpen === 'dashboard' ? false : 'dashboard')}>
                        {accordionOpen !== 'dashboard' ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ExpandLessIcon />
                        )}
                      </Flex>
                    )}
                  </div>
                </div>
                {accordionOpen === 'dashboard' && (
                  <Flex direction="column" gap="10px" pl={5}>
                    <a href="/dashboard/profile">
                      <Text>Profile</Text>
                    </a>
                    <a href="/dashboard/friend-list">
                      <Text>Friend List</Text>
                    </a>
                    <a href="/dashboard/purchase-history">
                      <Text>Purchase History</Text>
                    </a>
                  </Flex>
                )}
                <div>
                  {user ? (
                    <div>
                      <div className={styles.menu} onClick={handleClick}>
                        <h6>Anda <span className="yellow">{user?.name}</span></h6>
                      </div>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                      </Menu>
                    </div>
                  ) : (
                    <a href='/login' className={styles.btnLogin}>
                      <span className="label">Login</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </Drawer>
        </>
      ) : (
        <>
          <header className={styles.header}>
            <div className={styles.appHeader}>
              <div className="brandName">
                <img src={logo} className="App-logo" alt="logo" />
                <p className="title">PERSONA</p>
              </div>
              <div className="item">
                <div className={active === "home" ? "active" : ""}>
                  <a href='/'>
                    <span>HOME</span>
                  </a>
                </div>
                <div className={active === "profiling" ? "active" : ""}>
                  <a href='/profiling'>
                    <span>PROFILING</span>
                  </a>
                </div>
                <div className={active === "matching" ? "active" : ""}>
                  <a href='/matching'>
                    <span>MATCHING</span>
                  </a>
                </div>
                <div className={active === "dashboard" ? "active" : ""}>
                  <a href={user ? '/dashboard/persona' : '/user/dashboard'}>
                    <span>DASHBOARD</span>
                  </a>
                </div>
              </div>
              <div>
                {user ? (
                  <div>
                    <div className={styles.menu} onClick={handleClick}>
                      <h6>Anda <span className="yellow">{user?.name}</span></h6>
                    </div>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <a href='/login' className={styles.btnLogin}>
                    <span className="label">Login</span>
                  </a>
                )}
              </div>
            </div>
          </header>
          <div className={styles.whiteHeader}></div>
        </>
      )}
    </>
  );
}

export default Header;
