import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  header: {
    marginTop: 0,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.05)',
    filter: 'drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.05))',
    fontFamily: 'Manrope',
    // backgroundImage: 'linear-gradient(10deg, #FDD809, #E24E06, #ED1B33);',
    position: 'fixed',
    fontWeight: 500,
    color: '#000',
    fontSize: 20,
    top: 0,
    zIndex: 9,
    width: '100%',
    backgroundColor: '#fff'
  },
  // whiteHeader: {
  //     backgroundColor: "#fff",
  //     position: "fixed",
  //     top: 0,
  //     height: 20,
  //     zIndex: 9,
  //     width: "100%"
  // },
  menu: {
    '& h6': {
      maxWidth: 250,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: '#050C9C'
    }
  },
  appHeader: {
    // gap: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 20px',
    maxWidth: '1040px',
    '& .brandName': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    '& .title': {
      color: '#000',
      margin: 0,
      fontSize: 6,
      fontFamily: 'Audiowide',
    },
    '& .item': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 700,
      gap: 40
    },
    '& .active': {
      fontWeight: 700,
      borderBottom: '5px solid #3572EF',
      padding: '5px 0',
    }
  },
  hamburgerMenu: {
    // position: "fixed",
    // zIndex: 99,
    backgroundColor: "#fff",
    // width: "100%",
    // top: 50,
    fontFamily: 'Manrope',
    color: '#000',
    fontSize: 16,
    width: '50vw',
    padding: '10px 20px',
    '& .itemBurger': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      // alignItems: 'end',
      gap: 20,
      [theme.breakpoints.down('sm')]: {
        gap: 10
      }
    },
    '& .active': {
      fontWeight: 700,
      borderBottom: '2px solid #E24E06',
      padding: '5px 0',
      // width: 'fit-content'
    } ,
    '& .accordion': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  btnLogin: {
    backgroundColor: "#3572EF",
    padding: '5px 10px',
    color: '#fff',
    borderRadius: 5,
    '& .label': {
      color: '#fff'
    }
  }
}));
