// Chakra imports
import { Avatar, Box, Flex, Grid, Text, useColorModeValue } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";

// Assets
import banner from "assets/img/dailyvibe.png";
import logo from "assets/img/logo.png";
// import avatar from "assets/img/avatars/avatar4.png";
import React, { useEffect, useState } from "react";
import ServiceTable from "views/admin/profile/components/ServiceTable";
import { columnsDataService } from "views/admin/profile/variables/columnsData";
import tableDataService from "views/admin/profile/variables/tableDataService.json";
import axios from "axios";
import { LinearProgress } from "@mui/material";

export default function MatchingHistory(props) {
  const {match} = props
  const [user, setUser] = useState();
  const [history, setHistory] = useState([])

  useEffect(() => {
    let localUser = localStorage.getItem('user');
    if(localUser) {
      setUser(JSON.parse(localUser))
    } else {
      window.location.assign('/home')
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`https://api.personatalenta.id/friends/matching/list`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode"}})
    .then(res => {
      if (res?.data?.data) {
        setHistory(res.data.data)
      }
    }).catch(err => {
      if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
        // navigate('/');
      }
    })
  }, [])

  return (
    <Box pt={{ sm: 0, base: "0px", md: "50px", xl: "50px" }} mt={{sm: 5, md: 10}}>
      {/* Main Fields */}
      <Grid
        gap={{ base: "20px", xl: "20px" }}>
        {/* <Banner
          avatar={user?.photo}
          name={user?.name}
          job={user?.occupation}
          dob={user?.birth_date}
          edit={false}
        /> */}
        <Flex flexDir="row" gap={5} flexWrap="wrap" width={{sm: '100%', md: '75%'}} mt={{sm: 10, md: 0}}>
          {history.length > 0 && history.map((item, index) => (
            <Flex key={index} borderBottomWidth={1} flexDirection="row" py={2} gap={5} minWidth="45%">
              <Flex direction="column" justifyContent="center" minWidth={70}>
                <Avatar
                  src={item.users?.user1?.photo}
                  h='40px'
                  w='40px'
                  border='1px solid #059212'
                  // borderColor={borderColor}
                />
                <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" maxWidth={70}>{item?.users?.user1?.name.split(' ')[0]}</Text>
              </Flex>
              <Flex direction="column" justifyContent="center" minWidth={70}>
                <Avatar
                  src={item.users?.user2?.photo}
                  h='40px'
                  w='40px'
                  border='1px solid #059212'
                  // borderColor={borderColor}
                />
                <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" maxWidth={70}>{item?.users?.user2?.name.split(' ')[0]}</Text>
              </Flex>
              <Flex direction="column" gap={1} justifyContent="center">
                <Text>General</Text>
                <Text>Daily</Text>
              </Flex>
              <Flex direction="column" width={{sm: 130, md: 250}} gap={2} justifyContent="center">
                <Box width="100%" position="relative">
                  <LinearProgress sx={{height: 24, borderRadius: 1, width: '100%', position: 'absolute'}} color={item.matching_type === 0 ? "warning" : item.matching_type === 1 ? "error" : "info"} variant="determinate" value={item.stats.general} />
                  <Text color="white" fontWeight="bold" zIndex={1} position="relative" textAlign="center">{item.stats.general}%</Text>
                </Box>
                <Box width="100%" position="relative">
                  <LinearProgress sx={{height: 24, borderRadius: 1, width: '100%', position: 'absolute'}} color={item.matching_type === 0 ? "warning" : item.matching_type === 1 ? "error" : "info"} variant="determinate" value={item.stats.today} />
                  <Text color="white" fontWeight="bold" zIndex={1} position="relative" textAlign="center">{item.stats.today}%</Text>
                </Box>
                {/* <LinearProgress sx={{height: 20, borderRadius: 1, width: '100%'}} color={item.matching_type === 0 ? "warning" : item.matching_type === 1 ? "error" : "info"} variant="determinate" value={item.stats.daily} /> */}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Grid>
    </Box>
  );
}
