import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Card, Grid, Typography } from "@mui/material";
import useStyles from './style';
import { pricingProfilingStatic } from "constant/product";
import { Flex } from "@chakra-ui/react";
// import {pricingProfilingStatic} from '../../constant/product'

const PricingProfiling = props => {
  const classes = useStyles();
  const [pricingPlans, setPricingPlans] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    let tmpPricing = pricingProfilingStatic;
    axios.get(`https://api.personatalenta.id/product/list`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode",  'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' }})
    .then(res => {
      const data = res.data.products.filter((product) => product.type === "profiling")
      data.map((product) => {
        if (product.code === "profiling_standard") {
          tmpPricing[3].id = product.id
          tmpPricing[3].discountPrice = product.price
          tmpPricing[3].price = product.old_price
          tmpPricing[3].name = product.name
        } else if (product.code === "profiling_workstyle") {
          tmpPricing[1].id = product.id
          tmpPricing[1].discountPrice = product.price
          tmpPricing[1].price = product.old_price
          tmpPricing[1].name = product.name
        } else if (product.code === "profiling_complete") {
          tmpPricing[4].id = product.id
          tmpPricing[4].discountPrice = product.price
          tmpPricing[4].price = product.old_price
          tmpPricing[4].name = product.name
        } else if (product.code === 'profiling_career') {
          tmpPricing[0].id = product.id
          tmpPricing[0].discountPrice = product.price
          tmpPricing[0].price = product.old_price
          tmpPricing[0].name = product.name
        } else if (product.code === "profiling_relationship") {
          tmpPricing[2].id = product.id
          tmpPricing[2].discountPrice = product.price
          tmpPricing[2].price = product.old_price
          tmpPricing[2].name = product.name
        }
      })
      setPricingPlans(tmpPricing)
    }).catch(err => {
      if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
        // navigate('/');
      }
    })
  }, [])

  const handleBuy = plan => {
    console.log(props)
    if (props?.user) {
      window.location.assign(`/dashboard/profile/${props?.user?.username}/checkout/${plan?.id}`)
    } else {
      window.location.assign('/login')
    }
  }

  return (
    <Grid container spacing={3} width="100%" flexWrap="nowrap" overflow="auto" style={{ scrollbarWidth: 'none' }}>
      {pricingPlans.map((plan, index) => (
        <Grid item md={3} width={1080/4} key={index} className={classes.gridPrice}>
          <Card className={classes.pricingCard}>
            <Typography variant="h6" component="div" gutterBottom className={classes.pricingCardTitle}>
              {plan.name}
            </Typography>
            <Grid container spacing={1} className={classes.containerPricing}>
              <Typography variant="h6" component="del" className={classes.pricingCardPrice}>
                Rp {new Intl.NumberFormat('id-ID').format(plan.price)}
              </Typography>
              <Typography variant="h6" component="ins" className={classes.pricingCardPrice}>
                Rp {new Intl.NumberFormat('id-ID').format(plan.discountPrice)}
              </Typography>
            </Grid>
            <Flex justifyContent="center">
              <div className={classes.btnBuy} onClick={() => handleBuy(plan)}>
                <span>Beli</span>
              </div>
            </Flex>
            <Typography variant="caption" component="div" className={classes.pricingCardDesc}>
              {plan.desc}
            </Typography>
          </Card>
          <Card className={classes.cardDesc}>
            <Typography variant="h6" component="div" className={classes.titleService}>
              Service {plan.name}:
            </Typography>
            <Typography variant="body1" component="div" gutterBottom className={classes.pricingCardFeatures}>
              <ul>
                {plan.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </Typography>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default PricingProfiling;
