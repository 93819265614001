/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from "axios";
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import useStyles from './style';
import StepIcon from 'components/StepIcon';
import Back from 'assets/svg/back';
import PaymentMethod from 'components/PaymentMethod';
import { Button, Flex } from '@chakra-ui/react';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#8C9197',
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1A7431',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1A7431',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#1A7431',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const steps = ['Select Service', 'Confirm Order', 'Payment'];


const StandardProfiling = props => {
  const [activeStep, setActiveStep] = useState(0);
  const [service, setService] = useState([]);
  const [user, setUser] = useState({});
  const styles = useStyles();

  // useEffect(() => {
  //   let localUser = localStorage.getItem('user');
  //   const token = localStorage.getItem('token');
  //   if (localUser && token) {
  //     setUser(JSON.parse(localUser))
  //     axios.get(`https://api.personatalenta.id/product/list`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode"}})
  //     .then(res => {
  //       const data = res.data.products.filter((product) => product.code === "profiling_basic")
  //       setService(data)
  //     }).catch(err => {
  //       if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
  //         // navigate('/');
  //       }
  //     })  
  //   } else {
  //     // navigate('/')
  //   }
  // }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (props?.match?.params?.id && props?.match?.params?.type) {
      axios.get(`https://api.personatalenta.id/user/${props?.match?.params?.type}/${props.match.params.id}`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode",  'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' }})
      .then(res => {
        setUser(res.data.user ?? res.data.colleagues)
      }).catch(err => {
        if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
          // navigate('/');
        }
      })
    } else {
      let localUser = localStorage.getItem('user');
      if(localUser) {
        setUser(JSON.parse(localUser))
      } else {
        window.location.assign('/home')
      }
    }
    if (token) {
      axios.get(`https://api.personatalenta.id/product/list`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode"}})
      .then(res => {
        const data = res.data.products.filter((product) => product.code === "profiling_basic")
        setService(data)
      }).catch(err => {
        if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
          // navigate('/');
        }
      })  
    } else {
      // navigate('/')
    }
  }, []);


  return (
    <div className={styles.app}>
      <div className={styles.services}>
        <div className={styles.containerContent}>
          <div className={styles.stepperContainer}>
            <div onClick={activeStep === 0 ? () => window.history.go(-1) : () => setActiveStep(activeStep - 1)} className={styles.backButton}>
              <Back />
            </div>
            <div className="step">
              <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>
          </div>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 && (
                <div>
                  <div className={styles.bottomContainer}>
                    <div className={styles.containerPricing}>
                      <div className="row spaceBetween">
                        {/* <h6 className="title-type">PRICING</h6> */}
                        <div className={styles.containerButtonBuy}>
                          <h6 className="content">Rp {new Intl.NumberFormat('id-ID').format(service[0]?.price)}</h6>
                        </div>
                      </div>
                      <div className="row spaceBetween">
                        <p className="note">This is one-time charge service. After you own basic profiling, you don’t have to buy this service again.</p>
                        <div className={styles.containerButtonBuy}>
                          <div className={styles.btnBuy} onClick={() => setActiveStep(1)}>
                            <span>Buy Now</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.containerFeature}>
                    <div className="row">
                      <Flex direction={{sm: 'column'}}>
                        <p className="feat-long"><strong>Persona</strong></p>
                        <p className="feat-long"><span>•</span> Character in Brief</p>
                        <p className="feat-long"><span>•</span> Good Characteristic</p>
                        <p className="feat-long"><span>•</span> Bad Characteristic</p>
                        <p className="feat-long"><span>•</span> Character Bright and Dark</p>
                        <p className="feat-long"><span>•</span> Self Confidence</p>
                        <p className="feat-long"><span>•</span> Character Identification & To Deal With</p>
                        <p className="feat-long"><span>•</span> Dealing and Interacting With</p>
                      </Flex>
                      <Flex direction={{sm: 'column'}}>
                        <p className="feat-short"><strong>Career</strong></p>
                        <p className="feat-short"><span>•</span> Career Choices</p>
                        <p className="feat-short"><span>•</span> Suitable Career</p>
                        <p className="feat-short"><span>•</span> Career Environment</p>
                        <p className="feat-short"><span>•</span> Career Life</p>
                        <p className="feat-short"><span>•</span> Career Roles</p>
                        <p className="feat-short"><span>•</span> Career And Wealth</p>
                        <p className="feat-short"><span>•</span> Path To Growth</p>
                      </Flex>
                      <Flex direction={{sm: 'column'}}>
                        <p className="feat-long"><strong>Talent</strong></p>
                        <p className="feat-long"><span>•</span> Talent in Brief</p>
                        <p className="feat-long"><span>•</span> Business Acumen</p>
                        <p className="feat-long"><span>•</span> Thinking Judgment</p>
                        <p className="feat-long"><span>•</span> Money Matters</p>
                        <p className="feat-long"><span>•</span> Hidden Nature & When Under Pressure</p>
                        <p className="feat-long"><span>•</span> Work Style and Leadership</p>
                      </Flex>
                    </div>
                  </div>
                </div>
              )}
              {activeStep === 1 && (
                <div className={styles.containerStepTwo}>
                  <div className={styles.containerTopTwo}>
                    <h4 className="title">Service Checkout</h4>
                    <p className="content">Please check again the data you provided below and service you want to order. </p>
                  </div>
                  <div className={styles.containerCenter}>
                    <div className={styles.containerLeft}>
                      <div className={styles.containerOrder}>
                        <h6 className="title">Name</h6>
                        <p className="content">{user.name}</p>
                      </div>
                      <div className={styles.containerOrder}>
                        <h6 className="title">Birth Date</h6>
                        <p className="content">{user.birth_date}</p>
                      </div>
                      <div className={styles.containerOrder}>
                        <h6 className="title">Gender</h6>
                        <p className="content">{user.gender === 0 ? "Male" : "Female"}</p>
                      </div>
                    </div> 
                    <div className={styles.containerLeft}>
                      <div className={styles.containerOrder}>
                        <h6 className="title">Service Type</h6>
                        <p className="content">Basic Profiling</p>
                      </div>
                      <div className={styles.containerOrder}>
                        <h6 className="title">Total Price</h6>
                        <div>
                          <p className="content">Rp {new Intl.NumberFormat('id-ID').format(service[0]?.price)}</p>
                          <p className="note">Price exclude tax and fee</p>
                          <Button
                            borderWidth={1}
                            borderColor="#1A7431"
                            bgColor="#1A7431"
                            color='#fff'
                            fontSize='sm'
                            w="100%"
                            fontWeight='bold'
                            borderRadius='5px'
                            px='10px'
                            py='10px'
                            onClick={() => setActiveStep(activeStep + 1)}
                          >
                            {"Lanjutkan pembayaran"}
                          </Button>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              )}
              {activeStep === 2 && (
                <PaymentMethod productId={service[0].id} user={user} isUser={props?.match?.params?.type !== 'colleague'} />
              )}
            </React.Fragment>
          )}
        </div> 
      </div>
    </div>
  );
}

export default StandardProfiling;
