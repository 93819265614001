import Footer from 'components/footerUser';
import Header from 'components/header';
import React from 'react';
import useStyles from './style';
// import Footer from '../../components/Footer';
// import Header from '../../components/Header';
import 'assets/css/About.css';

const Disclaimer = () => {
  const styles = useStyles();
  
  return (
    <div className={styles.app}>
      <Header />
      <div className={styles.services}>
        <div className={styles.servicesContainer}>
          <h4>Disclaimer</h4>
          <p>Semua informasi di website www.personatalenta.id dan aplikasi Persona Talenta diterbitkan dengan itikad baik dan untuk tujuan hiburan dan informasi umum semata. Kami tidak membuat jaminan terhadap dan tentang kelengkapan, keandalan dan ketepatan informasi ini. Setiap tindakan yang Anda lakukan atas informasi yang Anda temukan di website www.personatalenta.id dan aplikasi Persona Talenta, secara ketat menjadi tanggung jawab dan resiko Anda sendiri. Kami tidak bertanggung jawab atas segala kerugian, kerusakan dan atau segala hal yang bersifat sama dengan kerugian dan atau kerusakan yang timbul sehubungan dengan penggunaan website dan aplikasi kami.</p>
          <p>Dari website atau aplikasi kami, Anda dapat mengunjungi situs lain dengan mengikuti hyperlink ke situs eksternal tersebut. Sementara kami berusaha untuk memberikan kualitas dengan tambahan berbagai link ke website-website yang berguna dan tepat, meskipun begitu kami tidak memiliki kontrol atas isi dan sifat dari website tersebut. Link tersebut ke website-website lain tidak merupakan rekomendasi untuk semua konten yang ditemukan diwebsite tersebut. Pemilik website dan konten dapat berubah tanpa pemberitahuan dan dapat terjadi sebelum kami memiliki kesempatan untuk menghapus link yang mungkin telah rusak atau tidak dapat di akses.</p>
          <p>Perlu juga menyadari bahwa ketika Anda meninggalkan website atau aplikasi kami, website lain mungkin memiliki kebijakan privasi yang berbeda dan istilah yang berada di luar kendali kami. Pastikan untuk memeriksa Kebijakan Privasi website tersebut juga sebagai " Ketentuan Layanan " mereka sebelum terlibat dalam bisnis atau meng-upload informasi.</p>
          <h5>Persetujuan</h5>
          <p>Dengan menggunakan dan membaca website dan aplikasi kami, Anda dengan ini setuju secara langsung maupun tidak langsung untuk menyetujui disclaimer kami dan setuju dengan ketentuan-ketentuannya.</p>
        </div> 
      </div>
      <Footer />
    </div>
  );
}

export default Disclaimer;
