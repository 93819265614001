import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
  },
  appHeader: {
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    gap: 40,
    fontFamily: 'Manrope',
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.05)',
    filter: 'drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.05))',
    fontSize: 16,
    '& .containerHeader': {
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '1040px',
      width: '100%'
    },
    '& .active': {
      fontWeight: 700,
      borderBottom: '2px solid #E24E06',
      padding: '5px 0',
    },
    '& .menu': {
      display: 'flex',
      gap: 60,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        gap: 20,
      }
    },
    '& .logo': {
      color: '#E24E06',
      fontFamily: 'Audiowide',
      fontWeight: 400,
      fontSize: 28,
    },
    '& .yellow': {
        color: '#E24E06'
    }
  },
  menu: {
    cursor: 'pointer',
  },
  services: {
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '66vh'
  },
  servicesContainer: {
    marginTop: 60,
    marginBottom: 60,
    maxWidth: '1040px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 100,
  },
  breadcrumbsContainer: { 
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between'
  },
  containerContent: {
    flexDirection: 'row',
    display: 'flex',
    gap: 20,
    marginTop: 30,
  },
  menuContainer: {
    filter: 'drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.1))',
    minWidth: 245,
    '& .account': {
        backgroundColor: '#E24E06',
        color: '#fff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px 20px',
        gap: 12
    },
    '& .active': {
        backgroundColor: '#FCEDE6',
        padding: '10px 20px',
    },
    '& .item': {
        backgroundColor: '#fff',
        padding: '10px 20px',
        cursor: 'pointer',
    }
  },
  containerSectionServices: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #F5F6F8',
    boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.1)',
    filter: 'drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.1))',
    padding: '52px 60px',
    minWidth: 655,
    gap: 20,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .row': {
        display: 'flex',
        flexDirection: 'row',
    },
    '& .spaceBetween': {
        justifyContent: 'space-between',
    },
    '& .alignItemsCenter': {
        alignItems: 'center',
    },
    '& .add-friend': {
        gap: 20
    },
    '& .label': {
        margin: '15px 0',
        minWidth: 140,
        '& .option': {
          color: "gray"
        }
    }
  },
  rightContainer: {
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    '& .btnEdit': {
        backgroundColor: '#E24E06',
        color: '#fff',
        padding: '10px 25px',
        borderRadius: 8,
        fontWeight: 700,
    },
    '& .item': {
        gap: 20,
    }
  },
  btnAddFriend: {
    backgroundColor: '#E24E06',
    color: '#fff',
    padding: '10px 25px',
    borderRadius: 8,
    fontWeight: 700,
    cursor: 'pointer'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #C2C2C2',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
  },
  formControl: {
    width: '80%'
  },
  containerButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 20,
    marginTop: 20
  }
}));
