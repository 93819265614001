import Footer from 'components/footerUser';
import Header from 'components/header';
import React from 'react';
import useStyles from './style';
// import Footer from '../../components/Footer';
// import Header from '../../components/Header';

const TermsService = () => {
  const styles = useStyles();
  
  return (
    <div className={styles.app}>
      <Header />
      <div className={styles.services}>
        <div className={styles.servicesContainer}>
          <h4>Terms of Service</h4>
          <p>Selamat datang di Persona Talenta!</p>
          <p>Hai Komunitas Persona! Selamat datang di Ketentuan Layanan kami. Ketentuan Layanan berikut ini berfungsi sebagai perjanjian tertulis atau persetujuan tertulis atau kontrak antara Anda dan PT Persona Talenta Digital, ini penting jadi harap baca dengan cermat (Anda akan terikat secara hukum saat menggunakan Layanan ini).</p>
          <p>PT Persona Talenta Digital (“Persona Talenta”, “kami”, “milik kami”) menyediakan layanan (dijelaskan di bawah) kepada Anda melalui situs web yang beralamat di personatalenta.id dan melalui aplikasi seluler Persona (“Aplikasi”) dan layanan terkait (secara bersama-sama, layanan tersebut, termasuk fitur dan aplikasi, dan Aplikasi “Layanan”), tunduk pada Persyaratan Layanan ini (sebagaimana diubah dari waktu ke waktu). Kami berhak, atas kebijakan kami sendiri, untuk mengubah atau memodifikasi bagian dari Ketentuan Layanan ini kapan saja. Jika kami melakukan ini, kami akan memposting perubahan pada halaman ini dan akan menunjukkan di bagian atas halaman ini tanggal terakhir kali ketentuan ini direvisi. Kami juga dapat memberi tahu Anda, baik melalui antarmuka pengguna Layanan, pemberitahuan dan/atau peringatan, dalam pemberitahuan email atau melalui cara lain yang wajar. Setiap perubahan tersebut akan berlaku tidak lebih awal dari empat belas (14) hari setelah diposting, kecuali bahwa perubahan yang menangani fungsi baru Layanan atau perubahan yang dibuat karena alasan hukum atau alasan tertentu akan segera berlaku. Jika Anda terus menggunakan Layanan setelah tanggal perubahan tersebut efektif, berarti Anda menerima ketentuan atau persyaratan layanan yang baru.</p>
          <p>Selain itu, saat menggunakan layanan, Anda akan tunduk pada persyaratan tambahan apa pun yang berlaku untuk layanan tersebut yang dapat diposting di Layanan dari waktu ke waktu, termasuk, tanpa batasan, Kebijakan Privasi yang terdapat di personatalenta.id dan Kebijakan lainnya yang terletak di personatalenta.id. Semua persyaratan tersebut dengan ini digabungkan dengan referensi ke dalam ketentuan atau persyaratan layanan ini.</p>
          <h5>Akses dan Penggunaan Layanan</h5>
          <p><strong>Deskripsi Layanan :</strong> Layanan Persona Talenta ini dirancang untuk beberapa aktivitas pengguna untuk dapat berinteraksi dalam bentuk layanan media sosial, berkirim pesan, membaca hasil analisa profil, melakukan pencocokan dalam pertnership, romantika dan persahabatan serta membaca profiling yang lebih lengkap dengan metode bazi atau analitik delapan karakter dan atau metoda lain dalam melakukan analisa analitisnya.</p>
          <p><strong>Penggunaan Anda :</strong> Layanan ini dimaksudkan untuk menyediakan materi informatif yang terkait dengan interaksi sosial, informasi profil dan profiling serta pencocokan adalah semata untuk hiburan Anda saja. Dengan pengertian bahwa Persona Talenta tidak memberikan layanan psikologis, kesehatan, atau jenis profesional pribadi lainnya sehubungan dengan Layanan ini. Jika Anda memerlukan bantuan psikologis, kesehatan, atau bantuan atau saran pribadi lainnya, silakan berkonsultasi dengan profesional yang kompeten terkait kebutuhan Anda.</p>
          <p>Persona Talenta secara khusus melepaskan semua tanggung jawab atas segala kewajiban, kerugian atau risiko yang timbul sebagai akibat, langsung atau tidak langsung, dari penggunaan layanan, termasuk tindakan apa pun yang Anda ambil atau hindari sebagai akibat dari informasi yang Anda terima melalui layanan ini dan tindakan pengguna mana pun yang terhubung dengan Anda melalui layanan ini.</p>
          <p>Persona Talenta menolak tanggung jawab hukum apa pun atas penggunaan fitur apa pun yang terkait dengan layanan ini. Dengan demikian maka Anda dengan ini setuju untuk bertanggung jawab penuh atas tindakan apa pun yang Anda ambil berdasarkan atau terkait dengan penggunaan layanan oleh Anda.</p>
          <p><strong>Kewajiban Registrasi Anda :</strong> Anda harus mendaftar ke Persona Talenta untuk mengakses dan menggunakan fitur-fitur tertentu dari Layanan. Jika Anda memilih untuk mendaftar ke Layanan, Anda setuju untuk memberikan dan memelihara informasi yang benar, akurat, terkini dan lengkap tentang diri Anda seperti yang tertera di kolom dan atau diminta oleh form pendaftaran Layanan. Data pendaftaran dan informasi tertentu lainnya tentang Anda diatur oleh Kebijakan Privasi kami. Jika Anda berusia di bawah 16 tahun, Anda dilarang menggunakan Layanan.</p>
          <p><strong>Akun Anggota, Kata Sandi dan Keamanan : </strong>Anda bertanggung jawab untuk menjaga kerahasiaan kata sandi dan akun Anda serta bertanggung jawab penuh atas setiap dan semua aktivitas yang terjadi di bawah kata sandi atau akun Anda. Anda setuju untuk (a) segera memberi tahu Persona Talenta tentang penggunaan kata sandi atau akun Anda yang tidak sah atau pelanggaran keamanan lainnya, dan (b) memastikan bahwa Anda keluar dari akun Anda pada akhir setiap sesi saat mengakses Layanan. Persona Talenta tidak akan bertanggung jawab atas kehilangan atau kerugian atau kerusakan yang timbul dari kegagalan Anda untuk mematuhi ini.</p>
          <p><strong>Modifikasi Layanan : </strong>Persona Talenta berhak untuk mengubah atau menghentikan, untuk sementara atau selamanya, Layanan (fitur atau bagiannya) dengan atau tanpa pemberitahuan. Anda setuju bahwa Persona Talenta tidak akan bertanggung jawab kepada Anda atau pihak ketiga mana pun atas modifikasi, penangguhan, atau penghentian Layanan.</p>
          <p><strong>Penggunaan dan Penyimpanan : </strong>Anda mengakui bahwa Persona Talenta dapat menetapkan praktik yang berlaku umum dan batasan terkait penggunaan Layanan, termasuk namun tidak terbatas pada jangka waktu maksimum data atau konten lain akan disimpan oleh Layanan dan ruang penyimpanan maksimum yang akan dialokasikan pada server Persona Talenta di nama Anda. Anda setuju bahwa Persona Talenta tidak bertanggung jawab atau berkewajiban atas penghapusan atau kegagalan untuk menyimpan data atau konten lain yang dikelola atau diunggah oleh Layanan. Anda mengakui bahwa Persona Talenta berhak untuk menghentikan akun yang tidak aktif untuk jangka waktu yang lama. Anda selanjutnya mengakui bahwa Persona Talenta berhak untuk mengubah praktik umum dan batasan ini kapan saja, atas kebijakannya sendiri, dengan atau tanpa pemberitahuan terlebih dahulu.</p>
          <p><strong>Layanan Seluler : </strong>Layanan mencakup layanan tertentu yang tersedia melalui perangkat seluler, termasuk (i) kemampuan untuk mengunggah konten ke Layanan melalui perangkat seluler, (ii) kemampuan untuk menelusuri Layanan dan Aplikasi dari perangkat seluler dan (iii) kemampuan untuk mengakses fitur-fitur tertentu melalui Aplikasi (secara bersama-sama disebut “Layanan Seluler”). Sejauh Anda mengakses Layanan melalui perangkat seluler, biaya standar operator layanan nirkabel Anda, tarif data, dan biaya lainnya mungkin berlaku untuk Anda. </p>
          <p>Selain itu, mengunduh, memasang, atau menggunakan Layanan Seluler tertentu mungkin dilarang atau dibatasi oleh operator Anda, dan tidak semua Layanan Seluler dapat bekerja dengan semua operator atau perangkat. Dengan menggunakan Layanan Seluler, Anda setuju bahwa kami dapat berkomunikasi dengan Anda mengenai Persona Talenta dan entitas lain melalui pesan teks atau sarana elektronik lainnya ke perangkat seluler Anda dan bahwa informasi tertentu tentang penggunaan Anda atas Layanan Seluler dapat dikomunikasikan kepada kami. Jika Anda mengubah atau menonaktifkan nomor telepon seluler Anda, Anda setuju untuk segera memperbarui informasi akun Layanan Anda untuk memastikan bahwa pesan Anda tidak dikirim ke orang yang memperoleh nomor Anda terdahulu.</p>
          <p><strong>Pembelian: </strong>Persona Talenta dapat menawarkan produk dan layanan untuk dibeli dengan imbalan Pembelian atau biaya lainnya (“Pembelian” purchase atau “pembelian dalam aplikasi” in-app purchase) melalui akun Google, Apple atau platform pembayaran pihak ketiga lainnya yang digunakan oleh Layanan ini. Jika Anda memilih untuk melakukan Pembelian atau pembelian dalam aplikasi, Anda akan diminta untuk mengonfirmasi pembelian Anda dengan penyedia pembayaran yang berlaku, dan metode pembayaran Anda (baik itu uang elektronik, kartu Anda atau akun pihak ketiga seperti Google atau Apple atau lainnya) ( “Metode Pembayaran”) Anda akan dikenakan biaya sesuai dengan harga yang ditampilkan kepada Anda untuk layanan yang Anda pilih serta pajak penjualan atau pajak serupa, biaya aplikasi dan biaya lainnya yang mungkin dikenakan pada pembayaran Anda, dan Anda mengizinkan Persona Talenta atau akun pihak ketiga, sebagaimana berlaku, untuk menagih Anda.</p>
          <p>Dengan melakukan Pembelian atau pembelian dalam aplikasi dari Persona Talenta, Anda mengakui dan menyetujui bahwa syarat dan ketentuan tambahan mungkin berlaku untuk penggunaan, akses, dan pembelian Anda atas Pembelian tersebut dan pembelian dalam aplikasi, dan syarat dan ketentuan tambahan tersebut digabungkan dengan referensi di sini. Persona Talenta tidak bertanggung jawab atas kesalahan pemrosesan pembayaran atas akun metoda pembayaran pihak ketiga yang mungkin terjadi saat melakukan Pembelian atau pembelian dalam aplikasi. Kami menyarankan Anda meninjau syarat dan ketentuan pihak ketiga mana pun sebelum melakukan Pembelian atau pembelian dalam aplikasi.</p>
          <p>Selain itu, untuk setiap Pembelian atau pembelian dalam aplikasi, Anda akan ditagih sesuai dengan layanan yang Anda pilih, dan Anda dapat membatalkan pembelian atau pembelian dalam aplikasi sesuai dengan syarat dan ketentuan yang berlaku. Harap diperhatikan bahwa fitur dan konten yang diberikan secara gratis dalam jumlah terbatas sehingga Anda dapat mengakses dan merasakan fitur layanan tersebut sebelum melakukan pembelian apa pun. Persona Talenta merekomendasikan untuk melakukan pembelian secara bertanggung jawab dan penuh pertimbangan. Harap diperhatikan bahwa Layanan dapat digunakan tanpa Pembelian, sehingga membatalkan, atau mengakhiri Pembelian Anda tidak menghapus akun atau profil Anda dari Persona Talenta. Anda harus menghapus atau mengakhiri akun Anda sesuai dengan prosedur yang ditetapkan dalam Aplikasi. Selain itu, Persona Talenta berhak untuk menangguhkan atau menghentikan akun apa pun tanpa memberikan pengembalian dana untuk pembelian atau pembelian dalam aplikasi apa pun karena melanggar Persyaratan Layanan ini.</p>
          <p><strong>Pembaruan Otomatis: </strong>Jika Anda membeli aplikasi dalam pembelian atau pembelian dalam aplikasi, akan diperpanjang secara otomatis ketika Anda mengakui dan menyetujui pemberitahuan pembelian sesuai dengan periode/persyaratan seperti semula dibeli. Untuk menghindari tagihan yang tidak diinginkan di masa mendatang, Anda harus membatalkan pembelian sebelum berakhirnya periode pembelian berakhir, atau menolak pemberitahuan pembaruan sesuai dengan syarat dan ketentuan Layanan ini dan platform pembayaran pihak ketiga. Harap diperhatikan bahwa menghapus akun dan/atau aplikasi seluler dari perangkat Anda tidak secara otomatis membatalkan pembelian.</p>
          <p><strong>Pengembalian dana: </strong>Tergantung pada jenis perangkat Anda, Anda akan melakukan pembelian dengan App Store (iOS) atau Play Store (Android). Persona Talenta tidak menangani pembayaran atau pengembalian uang ini secara langsung, Anda harus menghubungi pihak ketiga tempat Anda melakukan metoda pembayaran pembelian untuk meminta pengembalian uang atau mencari bantuan terkait pembayaran. pembelian Anda, dengan menunjukkan bahwa pembelian itu dibuat untuk aplikasi Persona Talenta pada laporan pembayaran Anda. Persona Talenta telah dengan cermat menyediakan fitur-fitur tertentu bagi pengguna untuk membantu keputusan pembelian yang memuaskan. Namun, jika Anda melakukan kesalahan pembelian, ikuti petunjuk di bawah ini untuk meminta pengembalian dana:</p>
          <p>Pengguna Play Store / perangkat Android: <a href="https://support.google.com/googleplay/">https://support.google.com/googleplay/</a></p>
          <p>Pengguna App Store / perangkat iOS: <a href="https://support.apple.com/">https://support.apple.com/</a> </p>
          <h5>Ketentuan Penggunaan</h5>
          <p><strong>Tanggung Jawab Pengguna: </strong>Anda bertanggung jawab penuh atas semua data pribadi, data, kode, video, gambar, informasi, teks, perangkat lunak, musik, suara, foto, grafik, pesan, atau materi lain (“konten”) yang Anda unggah, posting, publikasikan, bagikan, atau tampilkan ("unggah") atau email atau digunakan melalui Layanan ini.</p>
          <p>Berikut ini adalah contoh jenis konten dan/atau penggunaan yang ilegal atau dilarang oleh Persona Talenta. Persona Talenta berhak untuk menyelidiki dan mengambil tindakan apapun termasuk tindakan hukum yang sesuai terhadap siapa pun yang, atas kebijakan Persona Talenta, melanggar ketentuan ini atau ketentuan lain apa pun yang terkandung dalam Ketentuan Layanan ini atau Peraturan dan Regulasi terkait yang diterbitkan oleh Layanan ini, termasuk namun tidak terbatas pada: menghapus konten yang menyinggung Layanan; memblokir Alamat IP tertentu; menangguhkan atau menghentikan akun pelanggar tersebut; dan melaporkan pelanggar ke aparat penegak hukum yang berwenang.  Perlu diketahui, semua tindakan tersebut dapat dilakukan tanpa pemberitahuan sebelumnya kepada pihak/pengguna yang melanggar. </p>
          <p>Anda setuju dan bertanggung jawab untuk tidak menggunakan Layanan untuk mengirim konten atau meng-unggah konten apa pun yang:</p>
          <ul>
            <li>Melanggar kekayaan intelektual atau hak kepemilikan lainnya dari pihak mana pun;</li>
            <li>Anda tidak memiliki hak untuk mengunggah berdasarkan hukum apa pun atau berdasarkan hubungan kontrak atau fidusia;</li>
            <li>Berisi virus perangkat lunak atau kode, file, atau program komputer lainnya yang dirancang untuk mengganggu, menghancurkan, atau membatasi fungsi perangkat lunak atau perangkat keras komputer atau peralatan telekomunikasi apa pun;</li>
            <li>Menimbulkan atau menciptakan risiko privasi atau keamanan bagi siapa pun;</li>
            <li>Merupakan iklan yang tidak diminta atau tidak sah, materi promosi, kegiatan komersial dan/atau penjualan, “junk mail”, “spam”, “surat berantai”, “skema piramida”, “kontes”, “undian”, atau bentuk ajakan lainnya;</li>
            <li>Melanggar hukum, berbahaya, mengancam, kasar, melecehkan, menyiksa, kekerasan berlebihan, memfitnah, vulgar, cabul, pornografi, memfitnah, mengganggu privasi orang lain, kebencian rasial, etnis atau tidak menyenangkan;</li>
            <li>Dalam penilaian Persona Talenta, tidak dapat diterima atau yang membatasi atau menghalangi orang lain untuk menggunakan atau menikmati Layanan, atau yang dapat membuat Persona Talenta atau penggunanya mengalami kerugian atau kewajiban dalam bentuk apa pun;</li>
            <li>Mengganggu atau mengacaukan Layanan atau server atau jaringan yang terhubung ke Layanan, atau tidak mematuhi persyaratan, prosedur, kebijakan, atau peraturan apa pun dari jaringan yang terhubung ke Layanan;</li>
            <li>Melanggar hukum lokal, nasional atau internasional yang berlaku, atau peraturan apa pun yang memiliki kekuatan hukum;</li>
            <li>Menyamar sebagai orang atau entitas mana pun, atau secara salah menyatakan atau menggambarkan secara keliru afiliasi Anda dengan seseorang atau entitas;</li>
            <li>Mengumpulkan atau mengumpulkan alamat email atau informasi kontak lain dari pengguna lain dari Layanan dengan cara elektronik atau lainnya untuk tujuan pengiriman email yang tidak diminta atau komunikasi yang tidak diminta lainnya;</li>
            <li>Mengiklankan atau menawarkan untuk menjual atau membeli barang atau jasa apa pun untuk tujuan bisnis apa pun yang tidak diizinkan secara khusus;</li>
            <li>Memajukan atau mempromosikan kegiatan atau perusahaan kriminal atau memberikan informasi instruksional tentang kegiatan ilegal;</li>
            <li>Membuat akun baru setelah dihapus dari layanan tanpa izin tertulis dari Persona Talenta;</li>
            <li>Memperoleh atau mencoba mengakses atau memperoleh materi atau informasi apa pun melalui cara apa pun yang tidak sengaja disediakan atau disediakan melalui Layanan;</li>
            <li>Melanggar Pedoman Komunitas; atau</li>
            <li>Kasar, melecehkan, mengancam, atau tidak pantas saat berkomunikasi dengan tim dukungan pelanggan Persona Talenta.</li>
          </ul>
          <p><strong>Perilaku Pengguna : </strong>Persona Talenta meminta agar Pengguna memperlakukan semua orang dengan rasa hormat, kejujuran, dan kebaikan untuk mempromosikan ruang yang aman untuk berbagi cerita dan pengalaman pribadi. Untuk aturan dan peraturan tambahan yang berkaitan dengan Perilaku Pengguna, silakan kunjungi Pedoman Komunitas di situs web: personatalenta.id. Persona Talenta memiliki kebijakan tanpa toleransi terkait Perilaku Pengguna yang tidak pantas dan akan mengambil langkah-langkah yang diperlukan untuk menegakkan kebijakan tersebut secara agresif.</p>
          <p><strong>Tidak Ada Pemeriksaan Verifikasi Identitas: </strong>Persona Talenta tidak melakukan pemeriksaan latar belakang atau verifikasi identitas pada pengguna Layanan ini. Harap gunakan akal sehat dan penilaian yang baik saat berkomunikasi dengan orang lain di Layanan ini.</p>
          <p>Anda memahami bahwa persona talenta tidak melakukan pemeriksaan latar belakang atau verifikasi identitas pada pengguna layanan ini atau mencari latar belakang penggunanya. Persona talenta tidak membuat pernyataan atau jaminan atas perilaku, identitas, niat, legitimasi, atau kebenaran pengguna. Namun, Persona Talenta berhak untuk melakukan, dan Anda mengizinkan Persona Talenta untuk melakukan, pemeriksaan latar belakang atau lainnya yang dianggap diperlukan, dari setiap informasi yang Anda berikan untuk dapat digunakan untuk tertentu yang diperlukan.</p>
          <p>Anda sepenuhnya bertanggung jawab atas interaksi dan komunikasi Anda dengan pengguna lain di layanan ini. Pemeriksaan pendaftaran dan alat lainnya tidak menjamin keselamatan Anda pada layanan ini, dan bukan sebagai pengganti untuk mengikuti tindakan keselamatan menurut akal sehat dan tindakan keselamatan lainnya yang wajar. Anda harus selalu menggunakan penilaian terbaik dan melakukan tindakan keselamatan yang tepat saat berkomunikasi dengan atau bertemu orang baru secara online. Komunikasi yang diterima melalui layanan, termasuk pemberitahuan otomatis yang dikirim oleh pengguna Persona Talenta, dapat dilakukan dari pengguna yang terlibat dengan layanan untuk tujuan yang tidak benar, termasuk pelecehan, pencegahan, penipuan, penyalahgunaan, atau segala sesuatu yang tidak sesuai.</p>
          <p>Meskipun Persona Talenta berusaha untuk mendorong dan memberikan pengalaman pengguna yang terhormat, Persona Talenta tidak bertanggung jawab atau berkewajiban dengan cara apa pun atas perilaku pengguna mana pun di dalam atau di luar Layanan. Anda setuju untuk berhati-hati dalam semua interaksi dengan pengguna lain, terutama jika Anda memutuskan untuk berkomunikasi di luar Layanan atau bertemu langsung.</p>
          <p><strong>Penggunaan Software : </strong>Perangkat lunak yang terhubung dengan Layanan ini dan transmisi data yang berlaku, jika ada, tunduk pada peraturan dan perundangan yang berlaku di Indonesia. Mengunduh atau menggunakan Perangkat Lunak adalah risiko Anda sendiri. Menyadari sifat global Internet, Anda setuju untuk mematuhi semua peraturan dan undang-undang terkait penggunaan Layanan oleh Anda, termasuk yang berkaitan dengan perilaku online dan konten yang dapat diterima.</p>
          <p><strong>Penggunaan komersial : </strong>Kecuali atas izin tertulis oleh Persona Talenta atau dalam Layanan, Anda setuju untuk tidak menampilkan, mendistribusikan, melisensikan, melakukan, menerbitkan, mereproduksi, menggandakan, menyalin, membuat karya turunan dari, memodifikasi, menjual, menjual kembali, mengeksploitasi, mentransfer, atau mengunggah untuk tujuan komersial apa pun, setiap bagian dari Layanan, penggunaan Layanan, atau akses ke Layanan. Layanan ini hanya untuk penggunaan pribadi Anda.</p>
          <h5>Saluran Distribusi Pihak Ketiga</h5>
          <p>Persona Talenta menawarkan Aplikasi Layanan ini yang mungkin tersedia melalui Apple Store, Android Play Store, atau saluran distribusi lainnya (“Saluran Distribusi”). Jika Anda memperoleh Aplikasi tersebut melalui Saluran Distribusi, Anda mungkin tunduk pada persyaratan tambahan Saluran Distribusi. Ketentuan Layanan ini hanya antara Anda dan Persona Talenta, dan bukan dengan Saluran Distribusi. Sejauh Anda menggunakan produk atau layanan pihak ketiga lainnya sehubungan dengan penggunaan Layanan kami oleh Anda, Anda setuju untuk mematuhi semua persyaratan yang berlaku dari perjanjian apa pun untuk layanan pihak ketiga tersebut.</p>
          <p>Sehubungan dengan Aplikasi Layanan yang tersedia untuk Anda gunakan berhubungan dengan produk bermerek Apple, selain syarat dan ketentuan lain yang ditetapkan dalam syarat Layanan ini, syarat-syarat berikut dan ketentuan juga berlaku:</p>
          <ul>
            <li>Persona Talenta dan Anda mengakui bahwa Ketentuan Layanan ini dibuat antara Persona Talenta dan Anda saja, dan bukan dengan Apple, dan bahwa antara Persona Talenta dan Apple, Persona Talenta bertanggung jawab penuh untuk aplikasi Layanan ini yang diaktifkan Apple dan kontennya.</li>
            <li>Anda tidak boleh menggunakan aplikasi Layanan ini yang Diaktifkan Apple dengan cara apa pun yang melanggar atau tidak sesuai dengan Aturan Penggunaan yang ditetapkan untuk aplikasi Layanan ini yang Diaktifkan Apple dalam, atau bertentangan dengan, Persyaratan Layanan App Store.</li>
            <li>Lisensi Anda untuk menggunakan aplikasi Layanan ini yang Diaktifkan Apple terbatas pada lisensi yang tidak dapat dialihkan untuk menggunakan aplikasi Layanan ini yang Diaktifkan Apple pada Produk iOS yang Anda miliki atau kendalikan, sebagaimana diizinkan oleh Aturan Penggunaan yang ditetapkan dalam Persyaratan Layanan App Store.</li>
            <li>Apple tidak berkewajiban apa pun untuk menyediakan layanan pemeliharaan atau dukungan apa pun sehubungan dengan aplikasi Layanan ini yang Diaktifkan Apple.</li>
            <li>Apple tidak bertanggung jawab atas jaminan produk apa pun, baik tersurat maupun tersirat oleh hukum. Jika ada kegagalan aplikasi Layanan ini yang Diaktifkan Apple untuk mematuhi garansi yang berlaku, Anda dapat memberi tahu Apple, dan Apple akan mengembalikan harga pembelian aplikasi Layanan ini yang Diaktifkan Apple kepada Anda, jika ada; dan, sejauh diizinkan oleh undang-undang yang berlaku, Apple tidak akan memiliki kewajiban jaminan apa pun lainnya sehubungan dengan aplikasi Layanan ini yang Diaktifkan Apple, atau klaim, kerugian, kewajiban, kerusakan, biaya, atau pengeluaran apa pun yang disebabkan oleh kegagalan untuk mematuhi setiap garansi, yang akan menjadi tanggung jawab penuh Persona Talenta, sepanjang tidak dapat disangkal berdasarkan hukum yang berlaku.</li>
            <li>Persona Talenta dan Anda mengakui bahwa Persona Talenta, bukan Apple, bertanggung jawab untuk menangani klaim apa pun dari Anda atau pihak ketiga mana pun yang terkait dengan aplikasi Layanan ini yang Diaktifkan Apple atau kepemilikan dan/atau penggunaan aplikasi Layanan ini yang Diaktifkan Apple oleh Anda, termasuk, namun tidak terbatas kepada: (i) klaim kewajiban produk; (ii) setiap klaim bahwa aplikasi Layanan ini yang Diaktifkan Apple gagal memenuhi persyaratan hukum atau peraturan yang berlaku; dan (iii) klaim yang timbul di bawah perlindungan konsumen atau undang-undang serupa.</li>
            <li>Jika ada klaim pihak ketiga bahwa aplikasi Layanan ini yang Diaktifkan Apple atau kepemilikan dan penggunaan pengguna akhir atas aplikasi Layanan ini yang Diaktifkan Apple tersebut melanggar hak kekayaan intelektual pihak ketiga tersebut, seperti antara Persona Talenta dan Apple, Persona Talenta, bukan Apple, akan bertanggung jawab penuh atas penyelidikan, pembelaan, penyelesaian, dan pembebasan klaim pelanggaran kekayaan intelektual tersebut.</li>
            <li>Anda menyatakan dan menjamin bahwa (i) Anda tidak berada di negara yang terkena embargo Pemerintah AS, atau yang telah ditetapkan oleh Pemerintah AS sebagai negara “pendukung teroris”; dan (ii) Anda tidak terdaftar dalam daftar pihak yang dilarang atau dibatasi oleh Pemerintah AS.</li>
            <li>Jika Anda memiliki pertanyaan, keluhan, atau klaim apa pun sehubungan dengan aplikasi Layanan ini yang Diaktifkan Apple, pertanyaan tersebut harus ditujukan ke Persona Talenta sebagai berikut: contact@personatalenta.id</li>
            <li>Persona Talenta dan Anda mengakui dan menyetujui bahwa Apple, dan anak perusahaan Apple, adalah penerima pihak ketiga dari Ketentuan Layanan ini sehubungan dengan aplikasi Layanan ini yang Diaktifkan Apple, dan bahwa, setelah Anda menerima syarat dan ketentuan dari Ketentuan Layanan ini, Apple akan memiliki hak (dan akan dianggap telah menerima hak) untuk memberlakukan Ketentuan Layanan ini terhadap Anda sehubungan dengan aplikasi Layanan ini yang Diaktifkan Apple sebagai penerima manfaat pihak ketiga daripadanya.</li>
          </ul>
          <h5>Hak kekayaan intelektual</h5>
          <p><strong>Konten Layanan, Perangkat Lunak, dan Merek Dagang: </strong>Anda mengakui dan menyetujui bahwa Layanan dapat berisi konten atau fitur (“Konten Layanan”) yang dilindungi oleh hak cipta, paten, merek dagang, rahasia dagang, atau hak dan undang-undang kepemilikan lainnya. Kecuali jika diizinkan secara tertulis oleh Persona Talenta, Anda setuju untuk tidak mengubah, menyalin, membingkai, mengikis, menyewakan, meminjamkan, menjual, mendistribusikan, atau membuat karya turunan berdasarkan Layanan atau Konten Layanan, secara keseluruhan atau sebagian, kecuali bahwa hal di atas tidak berlaku untuk Konten Pengguna Anda sendiri yang Anda unggah secara sah ke Layanan. </p>
          <p>Sehubungan dengan penggunaan Layanan oleh Anda, Anda tidak diperbolehkan atau menggunakan penambangan data, robot, pengikisan atau metode pengumpulan atau ekstraksi data serupa. Jika Anda diblokir oleh Persona Talenta untuk mengakses Layanan (termasuk dengan memblokir alamat IP Anda), Anda setuju untuk tidak menerapkan tindakan apa pun untuk menghindari pemblokiran tersebut (misalnya, dengan menutupi alamat IP Anda atau menggunakan alamat IP proxy). Setiap penggunaan Layanan atau Konten Layanan selain yang diizinkan secara khusus di sini sangat dilarang.</p>
          <p>Teknologi dan perangkat lunak yang mendasari Layanan atau didistribusikan sehubungan dengannya adalah milik Persona Talenta, afiliasi kami, dan mitra kami. Anda setuju untuk tidak menyalin, memodifikasi, membuat karya turunan, merekayasa balik, merakit balik, atau mencoba menemukan kode sumber apa pun, menjual, menetapkan, mensublisensikan, atau mengalihkan hak apa pun dalam Perangkat Lunak. Setiap hak yang tidak diberikan secara tertulis di sini dilindungi oleh Persona Talenta.</p>
          <p>Nama dan logo Persona Talenta adalah merek dagang dan merek layanan Persona Talenta (secara bersama-sama disebut “Merek Dagang Persona Talenta”). Nama dan logo perusahaan, produk, dan layanan lain yang digunakan dan ditampilkan melalui Layanan mungkin merupakan merek dagang atau merek layanan dari pemiliknya masing-masing yang mungkin atau mungkin tidak mendukung atau berafiliasi dengan atau terhubung dengan Persona Talenta. Tidak ada dalam Ketentuan Layanan ini atau Layanan yang harus ditafsirkan sebagai pemberian, secara tersirat, estoppel, atau sebaliknya, lisensi atau hak untuk menggunakan Merek Dagang Persona Talenta yang ditampilkan pada Layanan, tanpa izin tertulis sebelumnya dari kami dalam setiap contoh. Semua niat baik yang dihasilkan dari penggunaan Merek Dagang Persona Talenta akan berlaku untuk keuntungan eksklusif kami.</p>
          <p><strong>Materi Pihak Ketiga: </strong>Dalam situasi apa pun, Persona Talenta tidak bertanggung jawab dengan cara apa pun atas konten atau materi apa pun dari pihak ketiga mana pun (termasuk pengguna), termasuk, namun tidak terbatas pada, atas kesalahan atau kelalaian apa pun dalam konten apa pun, atau atas kehilangan atau kerusakan dalam bentuk apa pun yang timbul sebagai akibat dari penggunaan konten tersebut. Anda mengakui bahwa Persona Talenta tidak menyaring konten terlebih dahulu, tetapi Persona Talenta dan pihak yang ditunjuk akan memiliki hak (tetapi bukan kewajiban) atas kebijakannya sendiri untuk menolak atau menghapus konten apa pun yang tersedia melalui Layanan. Tanpa membatasi hal-hal tersebut di atas, Persona Talenta dan pihak yang ditunjuknya berhak menghapus konten apa pun yang melanggar Persyaratan Layanan ini atau dianggap oleh Persona Talenta, atas kebijakannya sendiri, tidak dapat diterima. Anda setuju bahwa Anda harus mengevaluasi, dan menanggung semua risiko yang terkait dengan,</p>
          <p><strong>Konten Pengguna yang Ditransmisikan Melalui Layanan: </strong>Sehubungan dengan konten atau materi lain yang Anda unggah melalui Layanan atau bagikan dengan pengguna atau penerima lain ("Konten Pengguna"), Anda menyatakan dan menjamin bahwa Anda memiliki semua hak, kepemilikan, dan kepentingan dalam dan terhadap Konten Pengguna tersebut, termasuk, tanpa batasan, semua hak cipta dan hak publisitas yang terkandung di dalamnya. Dengan mengunggah Konten Pengguna apa pun, dengan ini Anda berikan dan akan memberi Persona Talenta dan perusahaan afiliasinya lisensi non-eksklusif, di seluruh dunia, bebas royalti, disetor penuh, dapat dialihkan, dapat disublisensikan, abadi, lisensi yang tidak dapat dibatalkan untuk menyalin, menampilkan, mengunggah, melakukan, mendistribusikan, menyimpan, memodifikasi dan menggunakan Konten Pengguna Anda sehubungan dengan pengoperasian Layanan atau promosi, iklan, atau pemasarannya, dalam bentuk, media, atau teknologi apa pun yang sekarang dikenal atau dikembangkan kemudian.</p>
          <p>Anda mengetahui dan menyetujui bahwa setiap pertanyaan, komentar, saran, ide, umpan balik, atau informasi lain tentang Layanan (“Pengajuan”), yang Anda berikan kepada Persona Talenta tidak bersifat rahasia dan Persona Talenta berhak atas penggunaan dan penyebaran yang tidak terbatas dari Kiriman ini untuk tujuan apa pun, komersial atau lainnya, tanpa pengakuan atau kompensasi kepada Anda.</p>
          <p>Anda mengakui dan setuju bahwa Persona Talenta dapat menyimpan konten dan juga dapat mengungkapkan konten jika diwajibkan oleh hukum atau dengan itikad baik bahwa penyimpanan atau pengungkapan tersebut secara wajar diperlukan untuk: (a) mematuhi proses hukum, hukum yang berlaku, atau permintaan pemerintah; (b) menegakkan Ketentuan Layanan ini; (c) menanggapi klaim bahwa konten apa pun melanggar hak pihak ketiga; atau (d) melindungi hak, properti, atau keselamatan pribadi Persona Talenta, penggunanya, dan publik. Anda memahami bahwa pemrosesan teknis dan transmisi Layanan, termasuk konten Anda, mungkin melibatkan (a) transmisi melalui berbagai jaringan; dan (b) perubahan untuk menyesuaikan dan menyesuaikan dengan persyaratan teknis jaringan atau perangkat penghubung.</p>
          <p>Anda mengakui dan setuju bahwa Anda memiliki semua hak atas Konten Pengguna apa pun yang dihasilkan di dalam Layanan yang dapat diposting atau dibagikan di luar Layanan melalui media sosial atau di situs web pihak ketiga.</p>
          <p>Anda mengakui dan menyetujui bahwa fitur tertentu di Persona Talenta adalah media bersosialisasi dan Konten Pengguna yang diunggah atau dibagikan di fitur tersebut akan langsung dibagikan dengan anggota Persona Talenta lainnya. Harap berhati-hati sebelum mengunggah atau membagikan Konten Pengguna apa pun.</p>
          <p><strong>Konten Anggota yang Ditransmisikan Melalui Layanan: </strong>Pengguna lain juga akan membagikan konten pada Layanan ini (secara bersama-sama disebut “Konten Anggota). Konten anggota yang dibagikan di Layanan ini adalah satu-satunya milik pengguna yang membagikan konten tersebut dengan Persona Talenta. Anda tidak memiliki hak atas konten anggota tersebut, tidak dapat menggunakan konten apa pun yang melanggar Ketentuan Layanan ini. Persona Talenta berhak untuk segera menghapus akun Anda atas penyalahgunaan konten anggota.</p>
          <p><strong>Keluhan Hak Cipta: </strong>Persona Talenta menghormati kekayaan intelektual orang lain, dan kami meminta pengguna kami untuk melakukan hal yang sama. Jika Anda yakin bahwa karya Anda telah disalin dengan cara yang merupakan pelanggaran hak cipta, atau bahwa hak kekayaan intelektual Anda telah dilanggar, Anda harus memberi tahu Persona Talenta tentang klaim pelanggaran Anda sesuai dengan prosedur yang ditetapkan di bawah ini:</p>
          <p>Persona Talenta akan memproses dan menyelidiki pemberitahuan dugaan pelanggaran dan akan mengambil tindakan yang sesuai berdasarkan Undang-undang Tentang Hak Cipta (“UUHC”) dan undang-undang kekayaan intelektual lainnya yang berlaku sehubungan dengan dugaan atau pelanggaran yang pada awalnya adalah informasi atau pelanggaran hak cipta dari Anda. Pemberitahuan pelanggaran hak cipta yang diklaim harus dikirim melalui email ke Persona Talenta di contact@personatalenta.id (Baris subjek: “Permintaan Penghapusan UUHC”).  Agar efektif, pemberitahuan tertulis tersebut berisi informasi sebagai berikut:</p>
          <ul>
            <li>Tanda tangan elektronik atau fisik dari orang yang berwenang untuk bertindak atas nama pemilik hak cipta atau kepentingan kekayaan intelektual lainnya;</li>
            <li>Deskripsi karya berhak cipta atau kekayaan intelektual lain yang Anda klaim telah dilanggar;</li>
            <li>Deskripsi di mana materi yang Anda klaim melanggar terletak di Layanan, dengan detail yang cukup sehingga kami dapat menemukannya di Layanan;</li>
            <li>Alamat, nomor telepon, dan alamat email Anda;</li>
            <li>Pernyataan oleh Anda bahwa Anda memiliki keyakinan dengan itikad baik bahwa penggunaan yang disengketakan tidak diizinkan oleh pemilik hak cipta atau kekayaan intelektual, agennya, atau hukum;</li>
            <li>Pernyataan oleh Anda, dibuat di bawah sumpah, bahwa informasi di atas dalam Pemberitahuan Anda adalah akurat dan bahwa Anda adalah pemilik hak cipta atau kekayaan intelektual atau berwenang untuk bertindak atas nama pemilik hak cipta atau kekayaan intelektual.</li>
          </ul>
          <p><strong>Kontra-Pemberitahuan: </strong>Jika Anda yakin bahwa Konten Pengguna Anda yang telah dihapus (atau aksesnya dinonaktifkan) tidak melanggar, atau bahwa Anda memiliki otorisasi dari pemilik hak cipta, agen pemilik hak cipta, atau sesuai dengan hukum, untuk mengunggah dan menggunakan konten dalam Konten Pengguna Anda, Anda dapat mengirimkan pemberitahuan tanggapan tertulis yang berisi informasi sebagai berikut:</p>
          <ul>
            <li>Tanda tangan fisik atau elektronik Anda;</li>
            <li>Identifikasi konten yang telah dihapus atau yang aksesnya telah dinonaktifkan dan lokasi di mana konten tersebut muncul sebelum dihapus atau dinonaktifkan;</li>
            <li>Pernyataan bahwa Anda dengan itikad baik meyakini bahwa konten tersebut telah dihapus atau dinonaktifkan sebagai akibat dari kesalahan atau kesalahan identifikasi konten; dan</li>
            <li>Nama, alamat, nomor telepon, dan alamat email Anda, pernyataan bahwa Anda menyetujui yurisdiksi pengadilan yang berlokasi di Kota Jakarta dan pernyataan bahwa Anda akan menerima layanan proses dari orang yang memberikan pemberitahuan tentang dugaan pelanggaran.</li>
          </ul>
          <p>Jika pemberitahuan tanggapan diterima oleh Persona Talenta, maka selanjutnya kami akan mengirimkan salinan pemberitahuan tanggapan tersebut kepada pihak pengadu yang memberi tahu bahwa ia dapat mengganti konten yang dihapus atau berhenti menonaktifkannya dalam 10 hari kerja. Kecuali jika pemilik hak cipta mengajukan tindakan mencari keadilan terhadap penyedia konten, anggota, atau pengguna, konten yang dihapus dapat diganti, atau aksesnya dipulihkan, dalam 10 hingga 14 hari kerja atau lebih setelah menerima pemberitahuan tanggapan, di kebijaksanaan tunggal kami.</p>
          <p><strong>Kebijakan Pelanggar Berulang: </strong>Sesuai dengan UUHC dan peraturan perundangan lain yang berlaku, Persona Talenta mengambil kebijakan untuk menghentikan, pengguna yang dianggap sebagai pelanggar berulang dalam keadaan yang sesuai dan atas kebijakan Persona Talenta sendiri,. Persona Talenta juga dapat, atas kebijakannya sendiri, membatasi akses ke Layanan dan/atau menghentikan keanggotaan pengguna yang melanggar hak kekayaan intelektual orang lain, terlepas dari apakah ada pelanggaran berulang atau tidak.</p>
          <h5>Situs Web Pihak Ketiga</h5>
          <p>Layanan dapat menyediakan, atau pihak ketiga dapat menyediakan, tautan atau akses lain ke situs dan sumber lain di Internet. Persona Talenta tidak memiliki kendali atas situs dan sumber tersebut dan Persona Talenta tidak bertanggung jawab atas dan tidak mendukung situs dan sumber daya tersebut. Anda selanjutnya mengetahui dan menyetujui bahwa Persona Talenta tidak akan bertanggung jawab atau berkewajiban, secara langsung atau tidak langsung, atas kerusakan atau kerugian yang disebabkan atau diduga disebabkan oleh atau sehubungan dengan penggunaan atau ketergantungan pada konten, event, barang, produk, atau layanan apa pun yang tersedia di atau melalui situs atau sumber daya tersebut. Setiap transaksi yang Anda lakukan dengan pihak ketiga yang ditemukan saat menggunakan Layanan adalah antara Anda dan pihak ketiga, dan Anda setuju bahwa Persona Talenta tidak bertanggung jawab atas kerugian atau klaim yang mungkin Anda alami/miliki terhadap pihak ketiga tersebut.</p>
          <h5>Layanan Jejaring Sosial</h5>
          <p>Anda dapat masuk ke Layanan melalui berbagai layanan online pihak ketiga, seperti media sosial dan layanan jejaring sosial lainnya (“Layanan Jejaring Sosial”). Untuk memanfaatkan fitur dan kemampuan ini, kami mungkin meminta Anda untuk mengautentikasi, mendaftar, atau masuk ke Layanan Jejaring Sosial di situs web penyedianya masing-masing. Sebagai bagian dari integrasi tersebut, Layanan Jejaring Sosial akan memberi kami akses ke informasi tertentu yang telah Anda berikan ke Layanan Jejaring Sosial tersebut, dan kami akan menggunakan, menyimpan, dan mengungkapkan informasi tersebut sesuai dengan Kebijakan Privasi kami. Untuk informasi lebih lanjut tentang implikasi pengaktifan Layanan Jejaring Sosial ini dan penggunaan Persona Talenta dapat dibaca disitus personatalenta.id. Namun, harap diingat bahwa cara Layanan Jaringan Sosial menggunakan, menyimpan, dan mengungkapkan informasi Anda diatur semata-mata oleh kebijakan pihak ketiga tersebut, dan Persona Talenta tidak memiliki kewajiban atau tanggung jawab atas praktik privasi atau tindakan lain dari pihak ketiga mana pun dari situs atau layanan pihak yang mungkin diaktifkan dalam Layanan.</p>
          <p>Selain itu, Persona Talenta tidak bertanggung jawab atas keakuratan, ketersediaan, atau keandalan informasi, konten, barang, data, pendapat, saran, atau pernyataan apa pun yang tersedia sehubungan dengan Layanan Jejaring Sosial. Dengan demikian, Persona Talenta tidak bertanggung jawab atas segala kerusakan atau kerugian yang disebabkan atau diduga disebabkan oleh atau sehubungan dengan penggunaan atau ketergantungan pada Layanan Jejaring Sosial tersebut. Persona Talenta mengaktifkan fitur ini hanya sebagai kemudahan dan integrasi atau penyertaan fitur tersebut tidak menyiratkan dukungan atau rekomendasi.</p>
          <h5>Ganti Rugi dan Pelepasan</h5>
          <p>Anda setuju untuk membebaskan mengganti kerugian, dan membebaskan Persona Talenta dan afiliasinya serta pejabat, karyawan, direktur, komisaris, pemegang saham, dan agennya dari setiap dan semua kerugian, kerusakan, pengeluaran, termasuk biaya, hak, klaim, dan lainnya, segala jenis dan cedera (termasuk kematian) yang timbul dari atau terkait dengan penggunaan Layanan oleh Anda, Konten Pengguna apa pun, koneksi Anda ke Layanan, pelanggaran Anda terhadap Ketentuan Layanan ini atau pelanggaran Anda terhadap hak orang lain.</p>
          <h5>Penafian Garansi</h5>
          <p>Penggunaan layanan adalah risiko Anda sendiri. Layanan disediakan dengan dasar “sebagaimana adanya” dan “sebagaimana tersedia”. Persona Talenta secara tegas menolak semua jaminan dalam bentuk apa pun, baik tersurat, tersirat maupun hukum, termasuk, namun tidak terbatas pada jaminan tersirat dapat diperdagangkan, kesesuaian untuk tujuan tertentu, judul dan nilai.</p>
          <p>Persona Talenta tidak menjamin bahwa (i) Layanan akan memenuhi kebutuhan Anda, (ii) Layanan tidak akan terganggu, tepat waktu, aman, atau bebas dari kesalahan, (iii) hasil yang dapat diperoleh dari penggunaan Layanan akan akurat atau dapat diandalkan, atau (iv) kualitas produk, layanan, informasi, atau materi lainnya yang dibeli atau diperoleh Anda melalui layanan akan memenuhi harapan Anda.</p>
          <h5>Batasan Tanggung Jawab</h5>
          <p>Anda secara tegas memahami dan menyetujui bahwa Persona Talenta tidak bertanggung jawab atas kerugian langsung, tidak langsung, insidental, khusus, konsekuensial, kasus kerugian, atau kerusakan atas kehilangan keuntungan termasuk namun tidak terbatas pada, kerusakan data atau kerusakan lainnya untuk yang (bahkan jika Persona Talenta telah diberitahukan tentang kemungkinan kerusakan tersebut), baik berdasarkan kontrak, kerugian, kelalaian, tanggung jawab ketat atau lainnya, akibat dari: (i) penggunaan atau ketidakmampuan menggunakan Layanan; (ii) biaya pengadaan barang dan Layanan pengganti akibat dari barang, data, informasi, atau Layanan yang dibeli atau diperoleh atau pesan yang diterima atau transaksi yang dilakukan melalui atau dari Layanan; (iii) akses tidak resmi ke atau perubahan transmisi atau data Anda; (iv) pernyataan atau perilaku pihak ketiga pada Layanan; atau (v) hal lain yang terkait dengan Layanan. Dalam keadaan apa pun kewajiban total Persona Talenta kepada Anda atas semua kerusakan, kerugian, atau penyebab tindakan tidak akan melebihi jumlah yang telah Anda bayarkan kepada Persona Talenta dalam tiga (3) bulan terakhir, atau, jika lebih besar, maksimal Seratus Ribu Rupiah (Rp100.000,-). Jika Anda tidak puas dengan bagian layanan atau dengan persyaratan layanan ini, satu-satunya dan perbaikan eksklusif Anda adalah untuk menghentikan penggunaan Layanan ini.</p>
          <p><strong>Penyelesaian Sengketa dengan Arbitrase Mengikat: </strong>Harap baca bagian ini dengan seksama karena itu mempengaruhi hak Anda.</p>
          <h5>Perjanjian untuk Arbitrase</h5>
          <p>Penyelesaian Sengketa dengan Bagian Arbitrase Mengikat ini disebut dalam Ketentuan Layanan ini sebagai “Perjanjian Arbitrase.” Anda setuju bahwa setiap dan semua perselisihan atau klaim yang telah atau mungkin timbul antara Anda dan Persona Talenta, baik yang timbul dari atau terkait dengan Ketentuan Layanan ini (termasuk dugaan pelanggarannya), Layanan, iklan apa pun, aspek apa pun dari hubungan atau transaksi antara kami, akan diselesaikan secara eksklusif melalui arbitrase final dan mengikat, bukan pengadilan, sesuai dengan ketentuan Perjanjian Arbitrase ini, kecuali bahwa Anda dapat mengajukan klaim individu di pengadilan, jika klaim Anda memenuhi syarat. Anda setuju bahwa, dengan menyepakati Ketentuan Layanan ini, Anda dan Persona Talenta masing-masing melepaskan hak untuk berpartisipasi dalam gugatan kelompok. Hak Anda akan ditentukan oleh arbiter netral, bukan hakim.</p>
          <h5>Larangan Tindakan Kelompok dan Perwakilan dan Bantuan Non-Individu</h5>
          <p>Anda dan Persona Talenta setuju bahwa klaim hanya bisa diterima berdasarkan klaim individu saja, dan bukan sebagai kelompok, dalam kelompok atau tindakan perwakilan siapa pun. Kecuali Anda dan Persona Talenta saling menyetujui, arbitrator tidak boleh mengkonsolidasi atau menggabungkan klaim lebih dari satu orang atau pihak, dan tidak boleh memimpin setiap bentuk proses konsolidasi, perwakilan, atau klasifikasi. Dan juga, arbitrator dapat memberikan pembebasan (termasuk pembantuan moneter, saksi, dan pernyataan) hanya untuk pihak individu yang mencari kebaikan dan hanya sejauh diperlukan untuk memberikan perbaikan yang diperlukan oleh pihak tersebut,</p>
          <h5>Penyelesaian Sengketa Pra-Arbitrase</h5>
          <p>Persona Talenta beritikad baik untuk menyelesaikan perselisihan secara damai dan efisien, dan sebagian besar masalah pelanggan dapat diselesaikan dengan cepat dan untuk kepuasan pelanggan dengan mengirim email ke dukungan pelanggan di contact@personatalenta.id. Jika upaya tersebut terbukti tidak berhasil, salah satu pihak yang bermaksud untuk mencari arbitrase harus terlebih dahulu mengirimkan kepada pihak lain, melalui surat resmi, Pemberitahuan Sengketa (“Pemberitahuan”).</p>
          <p>Pemberitahuan untuk Persona Talenta harus dikirim ke contact@personatalenta.id (“Alamat Pemberitahuan”). Pemberitahuan harus (i) menjelaskan sifat dan dasar klaim atau sengketa dan (ii) menetapkan bantuan khusus yang diminta. Jika Persona Talenta dan Anda tidak menyelesaikan klaim dalam waktu enam puluh (60) hari kalender setelah Pemberitahuan diterima, Anda atau Persona Talenta dapat memulai proses arbitrase. Selama arbitrase, jumlah setiap tawaran penyelesaian yang dibuat oleh Persona Talenta atau Anda tidak akan diungkapkan kepada arbiter sampai setelah arbiter menentukan jumlah, jika ada, yang menjadi hak Anda atau Persona Talenta.</p>
          <h5>Prosedur Arbitrase</h5>
          <p>Arbitrase akan dilakukan oleh arbiter netral sesuai dengan Badan Arbitrase Nasional Indonesia (“BANI”) aturan dan prosedur, termasuk Prosedur Tambahan BANI untuk Perselisihan Terkait Konsumen (secara bersama-sama disebut “Peraturan BANI”), sebagaimana diubah oleh Perjanjian Arbitrase ini. Untuk informasi tentang BANI, silakan kunjungi situs webnya, https://baniarbitration.org/. Informasi tentang Peraturan BANI dan biaya untuk sengketa konsumen dapat ditemukan di halaman arbitrase konsumen BANI. </p>
          <p>Jika ada ketidaksesuaian antara ketentuan apa pun dalam Peraturan BANI dan ketentuan apa pun dari Perjanjian Arbitrase ini, ketentuan yang berlaku dari Perjanjian Arbitrase ini akan berlaku kecuali jika arbiter menentukan bahwa penerapan ketentuan Perjanjian Arbitrase yang tidak konsisten tidak akan menghasilkan arbitrase yang adil secara fundamental. Arbiter juga harus mengikuti ketentuan dari Ketentuan Layanan ini. Semua masalah adalah untuk diputuskan oleh arbiter, termasuk, namun tidak terbatas pada, masalah yang berkaitan dengan ruang lingkup, keberlakuan, dan kemampuan arbitrasi dari Perjanjian Arbitrase ini. </p>
          <p>Meskipun proses arbitrase biasanya lebih sederhana dan lebih ramping daripada persidangan dan proses peradilan lainnya, arbiter dapat memberikan ganti rugi, dan ganti rugi yang sama secara individual yang dapat diberikan kepada individu berdasarkan Persyaratan Layanan dan hukum yang berlaku. Keputusan oleh arbiter dapat dilaksanakan dan dapat dibatalkan oleh pengadilan hanya untuk alasan yang sangat terbatas.</p>
          <p>Kecuali jika Persona Talenta dan Anda setuju sebaliknya, sidang arbitrase apa pun akan berlangsung di lokasi yang cukup nyaman bagi kedua belah pihak dengan mempertimbangkan kemampuan para pihak untuk bepergian dan keadaan terkait lainnya. Jika para pihak tidak dapat menyepakati suatu lokasi, penentuan dilakukan oleh BANI. Jika klaim Anda sebesar Rp 1.000.000,- (satu juta rupiah), atau kurang, Persona Talenta setuju bahwa Anda dapat memilih apakah arbitrase akan dilakukan semata-mata berdasarkan dokumen yang diserahkan kepada arbiter, melalui sidang online, atau melalui sidang tatap muka, sebagaimana ditetapkan oleh Peraturan BANI. Jika tuntutan Anda melebihi Rp 1.000.000,-  (satu juta rupiah), hak atas pemeriksaan akan ditentukan oleh Peraturan BANI.</p>
          <h5>Biaya Arbitrase</h5>
          <p>Pembayaran semua biaya pengajuan, administrasi, dan arbiter (secara bersama-sama disebut “Biaya Arbitrase”) akan diatur oleh Peraturan BANI, kecuali ditentukan lain dalam Perjanjian Arbitrase ini. Jika nilai keringanan yang dicari adalah Rp 1.000.000,- (satu juta rupiah), atau kurang, atas permintaan Anda, Persona Talenta akan membayar semua Biaya Arbitrase. Jika nilai ganti rugi yang diminta lebih dari Rp 1.000.000,- (satu juta rupiah), dan Anda dapat menunjukkan kepada arbiter bahwa Anda secara ekonomi tidak mampu membayar bagian Anda dari Biaya Arbitrase atau jika arbiter menentukan dengan alasan apa pun bahwa Anda tidak boleh diharuskan membayar bagian Anda dari Biaya Arbitrase, Persona Talenta akan membayar bagian Anda dari biaya tersebut. Selain itu, jika Anda menunjukkan kepada arbiter bahwa biaya arbitrase akan mahal dibandingkan dengan biaya litigasi, Persona Talenta akan membayar Biaya Arbitrase sebanyak yang dianggap perlu oleh arbiter untuk mencegah arbitrase menjadi mahal. Setiap pembayaran biaya pengacara akan diatur oleh Peraturan BANI.</p>
          <p>Ketentuan Layanan ini berisi perjanjian arbitrase, yang akan, dengan pengecualian terbatas, mengharuskan Anda untuk mengajukan klaim yang Anda miliki terhadap kami untuk mengikat dan mengakhiri arbitrase. Berdasarkan perjanjian arbitrase, (i) Anda hanya diizinkan untuk mengajukan klaim terhadap Persona Talenta berdasarkan individu, bukan sebagai kelompok atau anggota kelompok atau tindakan perwakilan atau proses, dan (ii) Anda hanya diizinkan untuk mencari pembebasan (termasuk moneter, hukum, dan pernyataan) secara individu.</p>
          <h5>Kerahasiaan</h5>
          <p>Semua aspek dari proses arbitrase, dan setiap keputusan, atau putusan oleh arbiter, akan sangat dirahasiakan untuk kepentingan semua pihak.</p>
          <h5>Keterpisahan</h5>
          <p>Jika pengadilan atau arbiter memutuskan bahwa syarat atau ketentuan apa pun dari Perjanjian Arbitrase ini (berjudul “Larangan Tindakan Kelompok dan Perwakilan dan Bantuan Non-Perorangan” di atas) tidak sah atau tidak dapat dilaksanakan, para pihak setuju untuk mengganti syarat atau ketentuan tersebut dengan syarat atau ketentuan yang sah dan dapat dilaksanakan dan yang paling mendekati untuk menyatakan maksud dari syarat atau ketentuan yang tidak sah atau tidak dapat dilaksanakan, dan Perjanjian Arbitrase ini akan berlaku sebagaimana diubah. Jika pengadilan atau arbiter memutuskan bahwa salah satu ketentuan dari sub-bagian di atas berjudul “Larangan Tindakan Kelompok dan Perwakilan dan Bantuan Non-Perorangan” tidak sah atau tidak dapat dilaksanakan, maka keseluruhan Perjanjian Arbitrase ini akan batal demi hukum.</p>
          <h5>Perubahan Masa Mendatang untuk Perjanjian Arbitrase</h5>
          <p>Tanpa mengabaikan ketentuan apa pun dalam Ketentuan Layanan ini yang bertentangan, Persona Talenta setuju bahwa jika hal itu membuat perubahan di masa mendatang pada Perjanjian Arbitrase ini (selain perubahan pada Alamat Pemberitahuan) saat Anda adalah pengguna Layanan, Anda dapat menolak setiap perubahan dengan mengirimkan pemberitahuan tertulis kepada Persona Talenta dalam waktu tiga puluh (30) hari kalender sejak perubahan ke Alamat Pemberitahuan yang diberikan di atas. Dengan menolak perubahan apa pun di masa mendatang, Anda setuju bahwa Anda akan menengahi setiap perselisihan di antara kami sesuai dengan bahasa Perjanjian Arbitrase ini sejak tanggal Anda pertama kali menerima Persyaratan Layanan ini (atau menerima setiap perubahan selanjutnya pada Persyaratan Layanan ini).</p>
          <h5>Penghentian</h5>
          <p>Anda setuju bahwa Persona Talenta, atas kebijakannya sendiri, dapat menangguhkan atau menghentikan akun Anda (atau bagian apa pun darinya) atau penggunaan Layanan dan menghapus dan membuang konten apa pun dalam Layanan, untuk alasan apa pun, termasuk, tanpa batasan, karena kurangnya gunakan atau jika Persona Talenta yakin bahwa Anda telah melanggar atau bertindak tidak konsisten dengan isi atau inti dari Ketentuan Layanan ini. Setiap aktivitas yang dicurigai curang, kasar, atau ilegal yang mungkin menjadi alasan penghentian penggunaan Layanan oleh Anda, dapat dirujuk ke otoritas penegak hukum yang sesuai. </p>
          <p>Persona Talenta juga dapat atas kebijakannya sendiri dan sewaktu-waktu menghentikan penyediaan Layanan, atau bagiannya, dengan atau tanpa pemberitahuan. Anda setuju bahwa penghentian akses Anda ke Layanan berdasarkan ketentuan apa pun dari Persyaratan Layanan ini dapat dilakukan tanpa pemberitahuan sebelumnya, dan mengakui dan menyetujui bahwa Persona Talenta dapat segera menonaktifkan atau menghapus akun Anda dan semua informasi dan file terkait di akun Anda dan/atau menghalangi akses lebih lanjut ke file atau Layanan tersebut. Selanjutnya, Anda setuju bahwa Persona Talenta tidak akan bertanggung jawab kepada Anda atau pihak ketiga mana pun atas penghentian akses Anda ke Layanan.</p>
          <h5>Sengketa Pengguna</h5>
          <p>Anda setuju bahwa Anda bertanggung jawab penuh atas interaksi Anda dengan pengguna lain sehubungan dengan Layanan dan Persona Talenta tidak akan memiliki kewajiban atau tanggung jawab sehubungan dengan hal tersebut. Persona Talenta berhak, tetapi tidak berkewajiban, untuk terlibat dengan cara apa pun dengan perselisihan antara Anda dan pengguna Layanan lainnya.</p>
          <h5>Umum</h5>
          <p>Ketentuan Layanan ini merupakan keseluruhan perjanjian antara Anda dan Persona Talenta dan mengatur penggunaan Layanan oleh Anda, sebagai perjanjian antara Anda dan Persona Talenta sehubungan dengan Layanan. Anda juga dapat tunduk pada syarat dan ketentuan tambahan yang mungkin berlaku saat Anda menggunakan layanan afiliasi atau pihak ketiga, konten pihak ketiga, atau perangkat lunak pihak ketiga.</p>
          <p>Ketentuan Layanan ini diatur oleh hukum Indonesia. Sehubungan dengan sengketa atau tuntutan yang tidak tunduk pada arbitrase, sebagaimana disebutkan di atas, Anda dan Persona Talenta setuju untuk tunduk pada yurisdiksi pribadi dan eksklusif pengadilan yang berlokasi di Kota Jakarta. Ketidakberhasilan Persona Talenta untuk melaksanakan atau menegakkan hak atau ketentuan apa pun dari Ketentuan Layanan ini tidak akan dianggap sebagai pengesampingan hak atau ketentuan tersebut. Jika ada ketentuan dari Ketentuan Layanan ini yang ditemukan oleh pengadilan Jakarta, yang kompeten tidak sah, para pihak tetap setuju bahwa pengadilan harus berusaha untuk memberlakukan niat para pihak sebagaimana tercermin dalam ketentuan, dan ketentuan lain dari Ketentuan Layanan ini. Layanan tetap dalam kekuatan dan efek penuh.</p>
          <p>Anda setuju bahwa setiap klaim atau penyebab tindakan yang timbul dari atau terkait dengan penggunaan Layanan atau Ketentuan Layanan ini harus diajukan dalam waktu satu (1) tahun setelah klaim atau penyebab tindakan tersebut muncul atau selamanya dilarang. Versi cetak dari perjanjian ini dan pemberitahuan apa pun yang diberikan dalam bentuk elektronik akan dapat diterima dalam proses peradilan atau administratif berdasarkan atau terkait dengan perjanjian ini pada tingkat yang sama dan tunduk pada kondisi yang sama seperti dokumen dan catatan bisnis lain yang awalnya dibuat dan dipelihara di formulir tercetak.</p>
          <p>Anda tidak boleh mengalihkan Persyaratan Layanan ini tanpa persetujuan tertulis sebelumnya dari Persona Talenta, tetapi Persona Talenta dapat mengalihkan Persyaratan Layanan ini, seluruhnya atau sebagian, tanpa batasan. Judul bagian dalam Persyaratan Layanan ini hanya untuk kenyamanan dan tidak memiliki efek hukum atau kontrak. Pemberitahuan kepada Anda dapat dilakukan melalui email atau surat biasa. Layanan juga dapat memberikan pemberitahuan kepada Anda tentang perubahan pada Ketentuan Layanan ini atau hal-hal lain dengan menampilkan pemberitahuan atau tautan ke pemberitahuan secara umum di Layanan.</p>
          <h5>Privasi Anda</h5>
          <p>Di Persona Talenta, kami menghormati privasi pengguna kami. Untuk detailnya, silakan lihat Kebijakan Privasi kami, tersedia di: contact@personatalenta.id. Dengan menggunakan Layanan, Anda menyetujui Ketentuan Layanan Persona Talenta, melakukan pengumpulan, pemrosesan dan penggunaan  data pribadi Anda sebagaimana diuraikan di dalamnya.</p>
          <p>Pertanyaan? Kekhawatiran? Saran?Silahkan hubungi kami di contact@personatalenta.id untuk melaporkan pelanggaran apa pun terhadap Ketentuan Layanan ini atau untuk mengajukan pertanyaan apa pun tentang Ketentuan Layanan atau Layanan ini. Sekarang, itu tidak terlalu buruk kan? Terima kasih telah membaca Persyaratan Layanan kami. Kami harap Anda menikmati Persona Talenta!</p>
        </div> 
      </div>
      <Footer />
    </div>
  );
}

export default TermsService;
