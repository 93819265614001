/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { Avatar, Box, Button, Flex, Grid, Input, Text } from '@chakra-ui/react';
import useStyles from './style';
import Banner from "views/admin/profile/components/Banner";

const AddFriend = (props) => {
  // const [addBy, setAddBy] = useState('username');
  const [user, setUser] = useState({});
  const [params, setParams] = useState('');
  const [searchData, setSearchData] = useState([]);
  const styles = useStyles();

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('https://api.personatalenta.id/user/current', {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode"}})
    .then(res => {
      setUser(res.data.user)
    }).catch(err => {
      if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
        // navigate('/');
      }
    })
  }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   axios.get(`https://api.personatalenta.id/user/search?keyword=`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode",  'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' }})
  //   .then(res => {
  //     setSearchData(res?.data?.users)
  //     // setCards(res?.data?.cards)
  //   }).catch(err => {
  //     if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
  //       // navigate('/');
  //     }
  //   })
  // }, []);



  const onChangeInput = e => {
    setParams(e.target.value);
    handleSubmit();
  };

  const handleSubmit = () => {
    const token = localStorage.getItem('token');
    axios.get(`https://api.personatalenta.id/user/friendship/find-friend?search=${params}`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode",  'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' }})
    .then(res => {
      setSearchData(res?.data?.users)
      // setCards(res?.data?.cards)
    }).catch(err => {
      if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
        // navigate('/');
      }
    })
  };

  const handleAddFriend = item => {
    const token = localStorage.getItem('token');
    axios.post('https://api.personatalenta.id/user/friendship/add-friend', qs.stringify({friend_id: item.id}), {headers: {"Content-Type": 'application/x-www-form-urlencoded', Authorization: `Bearer ${token}`}})
    .then(() => {
      window.location.assign('/dashboard/friend-list')
    }).catch(err => {
      console.log('err: ', err)
      // setError(err.response.data.message || err.response.data)
    })
  };
  
  return (
    <Box pt={{ sm: 10, base: "130px", md: "80px", xl: "80px" }} mt={10}>
      {/* Main Fields */}
      <Grid
        gap={{ base: "20px", xl: "20px" }}>
        {/* <Banner
          avatar={user?.photo}
          name={user?.name}
          job={user?.occupation}
          dob={user?.birth_date}
          edit={!props?.match?.params?.id}
        /> */}
        <Flex direction="row" gap={5} id="sticky" alignItems="center">
          <Flex gap={3} alignItems="center">
            {/* <Text>Search Username Persona</Text> */}
            <Input
              variant='search'
              fontSize='sm'
              bg={"white"}
              color={"black"}
              // w={10}
              fontWeight='500'
              px="10px"
              py="5px"
              borderRadius={5}
              // textAlign="center"
              placeholder="Search user on apps"
              id="search"
              value={params}
              onChange={onChangeInput}
            />
          </Flex>
          <Button
            borderWidth={1}
            // borderColor="#1877f2"
            bg={"white"}
            color='black'
            fontSize='sm'
            fontWeight='bold'
            borderRadius='5px'
            px='10px'
            py='5px'
            onClick={() => handleSubmit()}
            // disabled={pagination.page === pagination.max}
          >
            Search
          </Button>
        </Flex>
        <Flex flexDirection="row" flexWrap="wrap" gap={3} width={{sm: "100%", md: "75%"}} flex={3}>
          {searchData.map((item) => (
            <Flex flexDirection="column" gap={5} flex={1}>
              <Flex alignItems="center" gap={3} flexDirection="row">
                <Avatar
                  src={item.photo}
                  h='40px'
                  w='40px'
                  border='1px solid #059212'
                  // borderColor={borderColor}
                />
                <Flex flexDirection="column" flex={1} maxWidth={120}>
                  <Text whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" maxWidth={100}>{item.name}</Text>
                  {/* {item.privacy === 0 && ( */}
                    <Text fontSize="sm" whiteSpace="nowrap" mt={-2} textOverflow="ellipsis" overflow="hidden" maxWidth={100}>{item.gender == 0 ? "Male" : "Female"}</Text>
                  {/* )} */}
                </Flex>
                {item.status === 'NOT_FRIEND_PUBLIC' || item.status === 'NOT_FRIEND_PRIVATE' ? (
                  <Button
                    borderWidth={1}
                    borderColor="blue.300"
                    bg={"white"}
                    color='black'
                    fontSize='xs'
                    // fontWeight='bold'
                    borderRadius='5px'
                    px='10px'
                    py='5px'
                    onClick={() => handleAddFriend(item)}
                    // disabled={pagination.page === pagination.max}
                  >
                    Add Friend
                  </Button>
                ) : item.status === 'WAITING FOR RESPOND' ? (
                  <Button
                    borderWidth={1}
                    borderColor="blue.300"
                    bg={"white"}
                    color='black'
                    fontSize='xs'
                    // fontWeight='bold'
                    borderRadius='5px'
                    px='10px'
                    py='5px'
                    disabled
                    // onClick={() => handleAddFriend(item)}
                    // disabled={pagination.page === pagination.max}
                  >
                    Requested
                  </Button>
                ) : (
                  <Flex width="80px" />
                )}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Grid>
    </Box>
  );
}

export default AddFriend;
