/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';
import qs from 'qs';

import Select from 'assets/svg/select';
import Selected from 'assets/svg/selected';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import PlaceholderPhoto from 'assets/img/placeholderPhoto.png';

import useStyles from './style';
import Button from 'components/button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Flex, Text } from '@chakra-ui/react';

const Input = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const EditProfile = () => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const [avatars, setAvatars] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState();
  const [file, setFile] = useState()
  const [error, setError] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const tmpToken = localStorage.getItem('token');
    setToken(tmpToken)
    axios.get('https://api.personatalenta.id/user/current', {headers: {Authorization: `Bearer ${tmpToken}`}})
    .then(res => {
      setUser(res.data.user)
    }).catch(err => {
      if (err.response.data.message === "Unauthorized") {
        // navigate('/');
      }
    })
  }, []);

  useEffect(() => {
    axios.get('https://api.personatalenta.id/user/registration_data', {headers: {Authorization: `Bearer ${token}`}})
    .then(res => {
      setAvatars(res.data.data.avatars)
    }).catch(err => {
      if (err.response.data.message === "Unauthorized") {
        // navigate('/');
      }
    })
  }, [token]);

  const handleSubmit = () => {
    axios.post('https://api.personatalenta.id/user/edit', qs.stringify({...user}), {headers: {"Content-Type": 'application/x-www-form-urlencoded', Authorization: `Bearer ${token}`}})
    .then(() => {
      window.location.assign('/dashboard/profile');
    }).catch(err => {
      console.log('err: ', err)
      setError(err.response.data.message || err.response.data)
    })
  };

  const onChangeInput = e => {
    const tmpValue = {};
    if (e.target.id === 'password') {
      tmpValue.password1 = e.target.value;
      tmpValue.password2 = e.target.value;
      setUser({...user, ...tmpValue});
    } else {
      tmpValue[e.target.id] = e.target.value;
      setUser({...user, ...tmpValue});
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectAvatar = avatar => {
    setUser({...user, avatar: avatar.name})
    setSelectedAvatar(avatar)
  }

  const handleSaveAvatar = () => {
    axios.post('https://api.personatalenta.id/user/photo', qs.stringify({avatar: selectedAvatar.name}), {headers: {"Content-Type": 'application/x-www-form-urlencoded', Authorization: `Bearer ${token}`}})
    .then(() => {
      window.location.reload();
    }).catch(err => {
      console.log('err: ', err)
      setError(err.response.data.message || err.response.data)
    })

  }

  const handleChangePhoto = (event) => {
    setFile(event.target.files[0]);
      event.preventDefault()
      const url = 'https://api.personatalenta.id/user/photo';
      const formData = new FormData();
      formData.append('photo', event.target.files[0]);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        },
      };
      axios.post(url, formData, config).then((response) => {
        window.location.reload()
      }).catch(err => {
        console.log('err: ', err)
        setError(err.response?.data?.message || err.response?.data)
      }); 
  
  }


  return (
    <div className={styles.app}>
      <div className={styles.services}>
        <div className={styles.servicesContainer}>
          <div className={styles.containerContent}>
            <div className={styles.rightContainer}>
              <div className={styles.containerSectionServices}>
                {error && (
                  <span className={styles.error}>{error}</span>
                )}
                <div className={styles.containerForm}>
                  <FormControl variant="standard" className={styles.formControlPhoto}>
                    <div className={styles.containerQuestion}>
                      <div>
                        {user.photo ? (
                          <img src={selectedAvatar ? selectedAvatar.url : user.photo} alt="Profile" width={157} className={styles.placeholderPhoto} />
                        ) : (
                          <img src={PlaceholderPhoto} alt="placeholder" width={157} className={styles.placeholderPhoto} />
                        )}
                        <p className={styles.infoFile}>Maximum file size of 1 MB</p>
                      </div>
                      <div>
                        <Input fullWidth id="file" style={{display: 'none'}} className={styles.inputFile} type="file" onChange={handleChangePhoto} />
                        <label shrink htmlFor="file" className={styles.labelBrowse}>
                          Browse Gallery
                        </label>
                        <div className={styles.buttonUpload} onClick={handleClickOpen}>
                          <span>Browse Avatar</span>
                        </div>
                      </div>
                    </div>
                  </FormControl>
                  <div className={styles.containerEditProfile}>
                    <div className="row add-friend">
                      <h6 className="label">Display Name</h6>
                      <FormControl variant="standard" className={styles.formControl}>
                        <Input fullWidth id="name" value={user.name} className={styles.input} onChange={onChangeInput} type="text" placeholder="Display Name" />
                      </FormControl>
                    </div>
                    <div className="row add-friend">
                      <h6 className="label">Occupation</h6>
                      <FormControl variant="standard" className={styles.formControl}>
                        <Input fullWidth id="occupation" onChange={onChangeInput} value={user.occupation} className={styles.input} type="text" placeholder="Occupation" />
                      </FormControl>
                    </div>
                    <div className="row add-friend">
                      <h6 className="label">Tanggal Lahir</h6>
                      <FormControl variant="standard" className={styles.formControl}>
                        <Input fullWidth id="birth_date" onChange={onChangeInput} className={styles.input} value={user.birth_date} type="date" placeholder="Tanggal Lahir" />
                        <p className={styles.infoRequired}>Hanya bisa diubah sekali</p>
                      </FormControl>
                    </div>
                    <div className="row add-friend">
                      <h6 className="label">Jam Lahir</h6>
                      <FormControl variant="standard" className={styles.formControl}>
                        <Input fullWidth id="birth_time" onChange={onChangeInput} className={styles.input} value={user.birth_time} type="time" placeholder="Jam Lahir" />
                        <p className={styles.infoRequired}>Hanya bisa diubah sekali</p>
                      </FormControl>
                    </div>
                    <div className="row add-friend">
                      <h6 className="label">Tempat Lahir</h6>
                      <FormControl variant="standard" className={styles.formControl}>
                        <Input fullWidth id="birth_location" onChange={onChangeInput} className={styles.input} value={user.birth_location} type="text" placeholder="Tempat Lahir" />
                      </FormControl>
                    </div>
                    <div className="row add-friend">
                      <h6 className="label">Gender</h6>
                      <FormControl variant="gender" className={styles.formControlSmall}>
                        <RadioGroup
                          row
                          id="gender"
                          value={Number(user.gender)}
                          defaultValue={Number(user.gender)}
                          onChange={onChangeInput}
                        >
                          <FormControlLabel value={0} control={<Radio id="gender" icon={<Select />} checkedIcon={<Selected />} />} label="Pria" />
                          <FormControlLabel value={1} control={<Radio id="gender" icon={<Select />} checkedIcon={<Selected />} />} label="Wanita" />
                        </RadioGroup>
                        <p className={styles.infoRequired}>Hanya bisa diubah sekali</p>
                      </FormControl>
                    </div>
                    <div className="row add-friend">
                      <h6 className="label">Privacy</h6>
                      <FormControl variant="privacy" className={styles.formControlSmall}>
                        <RadioGroup
                          row
                          id="privacy"
                          value={Number(user.privacy)}
                          defaultValue={Number(user.privacy)}
                          onChange={onChangeInput}
                        >
                          <FormControlLabel value={0} control={<Radio id="privacy" icon={<Select />} checkedIcon={<Selected />} />} label="Public" />
                          <FormControlLabel value={1} control={<Radio id="privacy" icon={<Select />} checkedIcon={<Selected />} />} label="Private" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className={styles.containerButton}>
                      <Button variant="alternate" label="Cancel" onClick={() => window.location.assign('/dashboard/profile')} />
                      <Button variant="primary" label="Save" onClick={() => handleSubmit()} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Select Avatar
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent dividers>
                <Flex flexDirection="row" wrap="wrap" maxWidth="100%" gap={8} alignItems="center" justifyContent="center">
                  {avatars.length > 0 && (
                    <>
                      {avatars.map((avatar, index) => (
                        <Flex borderWidth={user.avatar === avatar.name ? 5 : 0} borderRadius={500} borderColor="#E24E06" cursor="pointer" onClick={() => handleSelectAvatar(avatar)}>
                          <Avatar
                            mx='auto'
                            src={avatar.url}
                            h='130px'
                            w='130px'
                            borderWidth={user.avatar === avatar.name ? '4px solid' : 0}
                            borderColor={"#E24E06"}
                          />
                        </Flex>
                      ))}
                    </>
                  )}
                </Flex>
              </DialogContent>
              <DialogActions>
                <Button variant="primary" label="Select" onClick={handleSaveAvatar} />
              </DialogActions>
            </BootstrapDialog>
          </div>
        </div> 
      </div>
    </div>
  );
}

export default EditProfile;
